import {useQuery} from "react-query";
import { MasterService } from "src/app/shared/domains/master/master.service";

export function usePaymentTerms(compCode, systemAlias){
return useQuery("fetchPaymentTerms" + compCode + systemAlias, ()=> MasterService.fetchPaymentTermsSet(compCode, systemAlias), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
})
}

export function useFetchCurrencySetFromSAP(systemAlias){
    return useQuery("fetchCurrencySetFromSAP" + systemAlias, () => MasterService.fetchCurrencies('', systemAlias), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity
    })
}
import { Button, Tooltip } from "@material-ui/core";
import React, { memo } from "react";
import { NewItemDetailsTable } from "./newItemDetailsTable";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { AccountDetailsTable } from "./accountDetailsTable";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { newEditRequestPageStyles } from "./newEditRequestPageStyles";
import { EditRequestDeletePopUp } from "./editRequestDeletePopUp";
import { getCondtions } from "../../helper";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { Formatter } from "src/app/shared/components/amount-formatter";
import { AddMaterialFromPsl } from "./addMaterialFromPsl";
import { useFetchCurrencyDecimals } from "src/app/shared/domains/master/master.hooks";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import NewAccountDetailsComponent from "./NewAccountDetails.component";
import { AccTabItem } from "src/app/docFlows/components/dataTable/ItemDetails/accTabItems";
import { InfoHoverIcon } from "../../components/InfoHoverIcon";
import { TaxCalculationsPopup } from "../../components/TaxCalculationsPopup";
import { AddPricingCondtions } from "./addPricingCondtions";
import { PricingConditions } from "src/app/docFlows/domains/documentFlow/pricingConditions";
import { LoadingComponent } from "src/app/shared/components/loading";
import _ from "lodash";

type editItemDetailsprops = {
    documentFlow: DocumentFlow;
    itemLines: DocFlowItem[];
    totalValDynamic: any;
    dispatchMethod: any;
    showCBbasedOnSupplierStatus: any;
    accountDetailsData: Record<string, AccTabItem[]> | null
    accountDetailsLineNumbers: string[];
    accDetailsEditedLines: string[];
    accDetailsLoading: boolean;
    setFinalResendPoData: Function;
    finalResendPodata: any;
    disableDetails?: boolean;
    pricingCondionsData: PricingConditions[];
    vertexData?: any
};

const ItemDetails = (props: editItemDetailsprops) => {
    const { documentFlow, itemLines, totalValDynamic, dispatchMethod, showCBbasedOnSupplierStatus, accountDetailsData, accountDetailsLineNumbers, accDetailsEditedLines, accDetailsLoading, pricingCondionsData, disableDetails = false, vertexData = {loading:false,data:{}} } = props;
    const firstLineItem = itemLines[0];
    const [selectedData, setSelectedData] = React.useState<DocFlowItem[]>([]);
    const styles = newEditRequestPageStyles({});
    const [open, setOpen] = React.useState(false);
    const [openMaterialPopUp, setOpenMaterialPopUp] = React.useState(false);
    const [openPricingConditionsPopUp, setOpenPricingConditionsPopUp] = React.useState(false);
    const { data: currencyDecimals } = useFetchCurrencyDecimals(props.documentFlow.SystemAlias);

    const selectedPricingConditonsData = pricingCondionsData?.filter(item => item?.itemNumber === selectedData[0]?.PrItem);

    const totalvalue = <Formatter amount={totalValDynamic} currency={firstLineItem?.PrCurrency ? firstLineItem?.PrCurrency : ""} />;
    const classes = useStylesForToolTip({});

    const handleMassDelete = () => {
        setOpen(true);
    };

    const isDeletionAllowed: boolean = selectedData.some((item => {
        const fieldLevelConditions = getCondtions(item);
        return (fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR)
    }));

    const { hasFeature } = React.useContext(FeaturesContext);

    const showTotalOrTotalWithTax = () => {
        const data = vertexData.data.data;
        if(_.isEmpty(data))
            return <div className={styles.totalHeading}>
                Total Requested Amount:
                <span className={styles.totalValue}>{totalvalue}</span>
            </div>
        else
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={styles.totalHeading}>
                    Total Requested Amount:
                    <span className={styles.totalValue}>{totalvalue}</span>
                </div>
                <div className={styles.totalHeading}>
                    Total Amount With Conditions:
                    <span className={styles.totalValue}><Formatter amount={data?.total?.toString()} currency={firstLineItem?.PrCurrency ? firstLineItem?.PrCurrency : ""} /></span>
                </div>
            </div>
    }

    return <div style={{ width: "100%", height: "100%" }}>
        {
            
            props.itemLines.length > 0 ? 
            <>
                <div className={styles.itemHeader} style={{ marginBottom: !!selectedData.length ? 10 : 15 }}>
                    {/* <div className={`${styles.header} ${styles.boldFont}`} style={{ margin: 0 }}>ITEM DETAILS {hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) && documentFlow.POCompanyCode.substring(0, 2).toLocaleUpperCase() === 'Z2' && <InfoHoverIcon popUpComponent={<TaxCalculationsPopup taxAmount={"100"} />} />}</div> */}
                    <div className={`${styles.header} ${styles.boldFont}`} style={{ margin: 0 }}>ITEM DETAILS</div>
                    {
                        hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) ?
                        (
                            !vertexData.loading && 
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                            {
                                !disableDetails &&
                                <>
                                    {
                                        hasFeature(Features.RC_168614_PRICING_CONDITIONS) && getCondtions(firstLineItem)?.isItEasyRequestPR && selectedData.length === 1 &&
                                        <Button
                                            className={styles.deleteButton}
                                            onClick={() => setOpenPricingConditionsPopUp(true)}
                                            style={{
                                                paddingLeft: 14, paddingRight: 14,
                                                color: "#F19001",
                                                cursor: "pointer"
                                            }}>
                                            <AddCircleOutlineIcon />
                                            <div style={{ marginLeft: "5px" }} >{selectedPricingConditonsData?.length > 0 ? "Add / Update Condition Type" : `Add Condition Type`}</div>
                                        </Button>
                                    }
                                    {
                                        // firstLineItem?.Psl_ID !== "" && (firstLineItem?.Psl_ID?.startsWith('C') || firstLineItem?.Psl_ID?.startsWith('L')) && hasFeature(Features.RC_163946_ADD_NEW_ITEM_BUTTON)  &&
                                        <Button
                                            className={styles.deleteButton}
                                            onClick={() => setOpenMaterialPopUp(true)}
                                            style={{
                                                paddingLeft: 14, paddingRight: 14,
                                                color: "#F19001",
                                                cursor: "pointer"
                                            }}>
                                            <AddCircleOutlineIcon />
                                            <div style={{ marginLeft: "5px" }} >Add New Item From PSL</div>
                                        </Button>
                                    }
                                    {
                                        selectedData.length != 0 &&
                                        <Tooltip
                                            interactive
                                            classes={{ tooltip: classes.customTooltip }}
                                            title={isDeletionAllowed ? "Document with history (GR or IR) cannot be deleted" : ""}
                                            placement="bottom-start">
                                            <Button
                                                className={styles.deleteButton}
                                                onClick={!isDeletionAllowed && handleMassDelete}
                                                style={{
                                                    paddingLeft: 14, paddingRight: 14,
                                                    color: !isDeletionAllowed ? "#F19001" : "lightgrey",
                                                    cursor: !isDeletionAllowed ? "pointer" : "not-allowed",
                                                }}>

                                                <DeleteForeverIcon />
                                                <div style={{ marginLeft: "5px" }} >Mass Delete</div>
                                            </Button>
                                        </Tooltip>
                                    }
                                </>
                            }

                            {
                                hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) ?
                                showTotalOrTotalWithTax()
                                :
                                <div className={styles.totalHeading}>
                                    Total Requested Amount:
                                    <span className={styles.totalValue}>{totalvalue}</span>
                                </div>
                            }

                            </div>
                        )
                        :
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                        {
                            !disableDetails &&
                            <>
                                {
                                    hasFeature(Features.RC_168614_PRICING_CONDITIONS) && getCondtions(firstLineItem)?.isItEasyRequestPR && selectedData.length === 1 &&
                                    <Button
                                        className={styles.deleteButton}
                                        onClick={() => setOpenPricingConditionsPopUp(true)}
                                        style={{
                                            paddingLeft: 14, paddingRight: 14,
                                            color: "#F19001",
                                            cursor: "pointer"
                                        }}>
                                        <AddCircleOutlineIcon />
                                        <div style={{ marginLeft: "5px" }} >{selectedPricingConditonsData?.length > 0 ? "Add / Update Condition Type" : `Add Condition Type`}</div>
                                    </Button>
                                }
                                {
                                    // firstLineItem?.Psl_ID !== "" && (firstLineItem?.Psl_ID?.startsWith('C') || firstLineItem?.Psl_ID?.startsWith('L')) && hasFeature(Features.RC_163946_ADD_NEW_ITEM_BUTTON)  &&
                                    <Button
                                        className={styles.deleteButton}
                                        onClick={() => setOpenMaterialPopUp(true)}
                                        style={{
                                            paddingLeft: 14, paddingRight: 14,
                                            color: "#F19001",
                                            cursor: "pointer"
                                        }}>
                                        <AddCircleOutlineIcon />
                                        <div style={{ marginLeft: "5px" }} >Add New Item From PSL</div>
                                    </Button>
                                }
                                {
                                    selectedData.length != 0 &&
                                    <Tooltip
                                        interactive
                                        classes={{ tooltip: classes.customTooltip }}
                                        title={isDeletionAllowed ? "Document with history (GR or IR) cannot be deleted" : ""}
                                        placement="bottom-start">
                                        <Button
                                            className={styles.deleteButton}
                                            onClick={!isDeletionAllowed && handleMassDelete}
                                            style={{
                                                paddingLeft: 14, paddingRight: 14,
                                                color: !isDeletionAllowed ? "#F19001" : "lightgrey",
                                                cursor: !isDeletionAllowed ? "pointer" : "not-allowed",
                                            }}>

                                            <DeleteForeverIcon />
                                            <div style={{ marginLeft: "5px" }} >Mass Delete</div>
                                        </Button>
                                    </Tooltip>
                                }
                            </>
                        }

                        {
                            hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) ?
                            showTotalOrTotalWithTax()
                            :
                            <div className={styles.totalHeading}>
                                Total Requested Amount:
                                <span className={styles.totalValue}>{totalvalue}</span>
                            </div>
                        }

                        </div>
                    }
                </div>
                {
                    hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) ?
                    (
                        vertexData.loading ? <LoadingComponent /> :
                        <NewItemDetailsTable
                            disbaleItemDetails={disableDetails}
                            currencyDecimals={currencyDecimals}
                            lineItems={itemLines}
                            documentFlow={documentFlow}
                            setSelectedData={setSelectedData}
                            dispatchMethod={dispatchMethod}
                            showCBbasedOnSupplierStatus={showCBbasedOnSupplierStatus}
                            setFinalResendPoData={props.setFinalResendPoData}
                            finalResendPodata={props.finalResendPodata}
                            vertexData={vertexData}
                        />
                    )
                    :
                    <NewItemDetailsTable
                        disbaleItemDetails={disableDetails}
                        currencyDecimals={currencyDecimals}
                        lineItems={itemLines}
                        documentFlow={documentFlow}
                        setSelectedData={setSelectedData}
                        dispatchMethod={dispatchMethod}
                        showCBbasedOnSupplierStatus={showCBbasedOnSupplierStatus}
                        setFinalResendPoData={props.setFinalResendPoData}
                        finalResendPodata={props.finalResendPodata}
                    />
                }
            </> 
            :
            <div>Line items have been deleted</div>
            
        }
            <div className={`${styles.header} ${styles.boldFont}`}>ACCOUNT DETAILS</div>
        {
            props.itemLines.length > 0 ? hasFeature(Features.RC_163868_NEW_ACCOUNT_TAB) ?
            <NewAccountDetailsComponent
                disableAccountDetails={disableDetails}
                documentFlow={documentFlow}
                lineItems={itemLines}
                dispatchMethod={dispatchMethod}
                accountDetailsData={accountDetailsData}
                accountDetailsLineNumbers={accountDetailsLineNumbers}
                accDetailsEditedLines={accDetailsEditedLines}
                accDetailsLoading={accDetailsLoading}
            /> 
            :
            <AccountDetailsTable lineItems={itemLines} documentFlow={documentFlow} dispatchMethod={dispatchMethod}></AccountDetailsTable> : <div>Line items have been deleted</div>
        }
        {
            open && <EditRequestDeletePopUp open={open} setOpen={setOpen} toBeDeleteditems={selectedData} dispatchMethod={dispatchMethod}></EditRequestDeletePopUp>
        }
        {
            openMaterialPopUp &&
            <AddMaterialFromPsl
                currencyDecimals={currencyDecimals}
                lineItems={itemLines}
                documentFlow={documentFlow}
                setOpenChatSearch={setOpenMaterialPopUp}
                openChatSearch={openMaterialPopUp}
                dispatchMethod={dispatchMethod}

            />
        }
        {
            openPricingConditionsPopUp &&
            <AddPricingCondtions
            selectedPricingConditonsData={selectedPricingConditonsData || []}
                selectedItem={selectedData[0]}
                documentFlow={documentFlow}
                setOpenPricingConditionsPopUp={setOpenPricingConditionsPopUp}
                dispatchMethod={dispatchMethod}
            />
        }
    </div>
};

export default memo(ItemDetails);
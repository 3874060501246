import React from "react";
import { useReducer, useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { withContext } from "../../../shared/services/context.service";
import {
  CustomFastDate,
  CustomFastInput,
  CustomFastAsyncSelect
} from "../../../shared/components/form";
import {
  FeedbackDetails,
  FeedbackDialogContent,
  StyledFeedbackForm,
  StyledUserFeedbackDialog
} from "../../../shared/containers/dashboard/components/feedback/feedback.styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { RootStateStore } from "../../../application.reducers";
import {
  StyledDialogActions,
  StyledDialogTitle
} from "../../../shared/components/layout/styles";
import { PopupLoading } from "../../../shared/components/loading";
import { DocFlowItem } from "../../domains/prpo/poItem";
import { DateTime } from "luxon";
import { MasterService } from "../../../shared/domains/master/master.service";
import { isEmpty } from "ramda";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
      fontStyle: "italic",
      marginBottom: 5
    },
    select: {
      marginBottom: "10px!important"
    }
  })
);

const EditItemContent = ({ onClose, onSubmit, dfItem }) => {
  const { label, select } = useStyles({});
  const [enableSave, setEnableSave] = React.useState(false);
  const [values, setValues]: [any, any] = useReducer(R.mergeDeepRight, dfItem);
  const sending: boolean = useSelector(
    (state: RootStateStore) => state.docFlows.documentFlows.data.onUpdateLoading
  );
  const applyChange = (vals: { [key: string]: any }) => {
    const newItem = {
      ...values,
      ...vals
    };
    setValues(newItem);
    setEnableSave(true);
  };

  const handleSubmit = () => {
    onSubmit(values);
  };

  return (
    <>
      {sending ? (
        <PopupLoading text={"Updating..."} />
      ) : (
        <FeedbackDialogContent>
          <FeedbackDetails>
            <Grid container>
              <Grid container spacing={2} wrap={"nowrap"}>
                <Grid item sm={4}>
                  <Grid className={label}>Quantity</Grid>
                  <Grid className={select}>
                    <CustomFastInput
                      type={"number"}
                      // initialValue={values.PoQuantityRaw}
                      initialValue={parseInt(values.PoQuantityRaw)}
                      onChange={value => applyChange({ PoQuantityRaw: value })}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={8}>
                  <Grid className={label}>UoM</Grid>
                  <Grid className={select}>
                    <CustomFastAsyncSelect
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                      }}
                      defaultOptions={true}
                      isMulti={false}
                      onLoadLimit={0}
                      loadOnOpen={true}
                      onLoad={x =>
                        MasterService.fetchUoms(x, values.PoMaterial)
                      }
                      customDisplay={option =>
                        option.value
                          ? `${option.label} - ${option.value}`
                          : option.label
                      }
                      initialValue={
                        values.PoQuantityUom
                          ? {
                            label: values.PoQuantityUom
                          }
                          : null
                      }
                      onChange={value =>
                        value
                          ? applyChange({
                            PoQuantityUom: value.label
                          })
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} wrap={"nowrap"}>
                <Grid item sm={4}>
                  <Grid className={label}>Unit Price</Grid>
                  <Grid className={select}>
                    <CustomFastInput
                      type={"number"}
                      // initialValue={values.PoNetPriceRaw}
                      initialValue = {typeof(values.PoNetPrice) === 'string'? 
                        Number(values.PoNetPrice.replace(/[\s]/g, '').replace(',', '.')):
                        values.PoNetPrice
                      }                  
                      onChange={value => applyChange({ PoNetPrice: value })}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={8}>
                  <Grid className={label}>Currency</Grid>
                  <Grid className={select}>
                    <CustomFastAsyncSelect
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                      }}
                      defaultOptions={true}
                      isMulti={false}
                      onLoadLimit={0}
                      loadOnOpen={true}
                      onLoad={x => MasterService.fetchCurrencies(x)}
                      customDisplay={option =>
                        option.label
                          ? `${option.value} - ${option.label}`
                          : option.value
                      }
                      initialValue={
                        values.PoQuantityUom
                          ? {
                            value: values.PoCurrency
                          }
                          : null
                      }
                      onChange={value =>
                        value
                          ? applyChange({
                            PoCurrency: value.value
                          })
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} wrap={"nowrap"}>
                <Grid item sm={4}>
                  <Grid className={label}>Per Unit</Grid>
                  <Grid className={select}>
                    <CustomFastInput
                      type={"text"}
                      initialValue={values.PoNetPricePerUnit}
                      onChange={value =>
                        applyChange({ PoNetPricePerUnit: value })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item sm={8}>
                  <Grid className={label}>UoM</Grid>
                  <Grid className={select}>
                    <CustomFastAsyncSelect
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                      }}
                      defaultOptions={true}
                      isMulti={false}
                      onLoadLimit={0}
                      loadOnOpen={true}
                      onLoad={x =>
                        MasterService.fetchUoms(x, values.PoMaterial)
                      }
                      customDisplay={option =>
                        option.value
                          ? `${option.label} - ${option.value}`
                          : option.label
                      }
                      initialValue={
                        values.PoNetPriceUnit
                          ? {
                            label: values.PoNetPriceUnit
                          }
                          : null
                      }
                      onChange={value =>
                        value
                          ? applyChange({
                            PoNetPriceUnit: value.label
                          })
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={label}>
                Delivery Date
              </Grid>
              <Grid item sm={12} className={select}>
                <CustomFastDate
                  initialValue={DateTime.fromFormat(
                    values.PoDeliveryDate,
                    "yyyyMMdd"
                  ).toISO()}
                  onChange={value =>
                    applyChange({
                      PoDeliveryDate: DateTime.fromISO(value).toFormat(
                        "yyyyMMdd"
                      )
                    })
                  }
                />
              </Grid>
              <Grid item sm={12} className={label}>
                Spender
              </Grid>
              <Grid item sm={12} className={select}>
                <CustomFastAsyncSelect
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isMulti={false}
                  onLoadLimit={3}
                  loadOnOpen={true}
                  onLoad={x => MasterService.fetchRequestor(x, null, null)}
                  customDisplay={option =>
                    option.label && option.value
                      ? `${option.label} - ${option.value}`
                      : option.label
                        ? option.label
                        : option.value
                  }
                  initialValue={
                    values.PoRequisitioner
                      ? {
                        value: values.PoRequisitioner
                      }
                      : values.PoReqFname && values.PoReqLname
                        ? {
                          label: values.PoReqFname + " " + values.PoReqLname
                        }
                        : values.PoRequisitioner &&
                          values.PoReqFname &&
                          values.PoReqLname
                          ? {
                            value: values.PoRequisitioner,
                            label: values.PoReqFname + " " + values.PoReqLname
                          }
                          : null
                  }
                  onChange={value =>
                    value
                      ? applyChange({
                        PoRequisitioner: value.value,
                        PoReqFname: value.label.substr(
                          0,
                          value.label.indexOf(" ")
                        ),
                        PoReqLname: value.label.substr(
                          value.label.indexOf(" ") + 1
                        )
                      })
                      : applyChange({
                        PoRequisitioner: "",
                        PoReqFname: "",
                        PoReqLname: ""
                      })
                  }
                  customTooltipMessage="Type the UserID to start searching (3 chars min.)"
                />
              </Grid>
              <Grid item sm={12} className={label}>
                Material
              </Grid>
              <Grid item sm={12} className={select}>
                <CustomFastAsyncSelect
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isMulti={false}
                  onLoadLimit={2}
                  onLoad={x =>
                    MasterService.fetchMaterial(
                      x,
                      [values.Plant],
                      values.SystemAlias
                    )
                  }
                  customDisplay={option =>
                    option.label
                      ? `${option.value} - ${option.label}`
                      : option.value
                  }
                  disabled={
                    !isEmpty(values.PrNo) || !isEmpty(values.ContractNo)
                  }
                  initialValue={
                    values.PoMaterial
                      ? {
                        value: values.PoMaterial.replace(/^0+/, "")
                      }
                      : {}
                  }
                  onChange={value =>
                    value
                      ? applyChange({
                        PoMaterial: value.value
                      })
                      : null
                  }
                  customTooltipMessage="Type to find the materials"
                />
              </Grid>
              <Grid item sm={12} className={label}>
                Material Description
              </Grid>
              <Grid item sm={12} className={select}>
                <CustomFastInput
                  disabled={
                    values.MaterialType !== "ZSIM" &&
                    values.MaterialType !== "NLAG"
                  }
                  type={"text"}
                  initialValue={values.PoItemTxt}
                  onChange={value => applyChange({ PoItemTxt: value })}
                />
              </Grid>
            </Grid>
          </FeedbackDetails>
        </FeedbackDialogContent>
      )}
      {!sending && (
        <StyledDialogActions>
          <Button color="primary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={!enableSave}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </StyledDialogActions>
      )}
    </>
  );
};

export const EditPoPopup = ({ onClose, dfItem, saveDocFlowItem, onDone }) => {
  const docFlowItem: DocFlowItem = {
    ...dfItem
  };

  const diff = function(obj1, obj2) {
    if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
      return obj1;
    }

    var diffs = {};
    var key;

    const arraysMatch = function(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }

      return true;
    };

    const compare = function(item1, item2, key) {
      var type1 = Object.prototype.toString.call(item1);
      var type2 = Object.prototype.toString.call(item2);

      if (type2 === "[object Undefined]") {
        diffs[key] = null;
        return;
      }

      if (type1 !== type2) {
        diffs[key] = item2;
        return;
      }

      if (type1 === "[object Object]") {
        var objDiff = diff(item1, item2);
        if (Object.keys(objDiff).length > 0) {
          diffs[key] = objDiff;
        }
        return;
      }

      if (type1 === "[object Array]") {
        if (!arraysMatch(item1, item2)) {
          diffs[key] = item2;
        }
        return;
      }

      if (type1 === "[object Function]") {
        if (item1.toString() !== item2.toString()) {
          diffs[key] = item2;
        }
      } else {
        if (item1 !== item2) {
          diffs[key] = item2;
        }
      }
    };

    for (key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        compare(obj1[key], obj2[key], key);
      }
    }

    for (key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (!obj1[key] && obj1[key] !== obj2[key]) {
          diffs[key] = obj2[key];
        }
      }
    }

    return diffs;
  };

  const handleSubmit = (docflowItem: DocFlowItem) =>
    new Promise((resolve, reject) =>
      saveDocFlowItem(
        {
          resolve,
          reject
        },
        {
          ...diff(dfItem, docflowItem),
          PoNo: dfItem.PoNo,
          PoItem: dfItem.PoItem,
          SystemAlias: dfItem.SystemAlias,
          CompCode: dfItem.CompCode,
          CompCodeTxt: dfItem.CompCodeTxt,
          PoCreationDate: dfItem.PoCreationDate
        },
        onDone
      )
    );

  const handleClose = () => {
    onClose();
  };

  const EditItemForm = withContext(EditItemContent);

  return (
    <StyledUserFeedbackDialog
      open={true}
      maxWidth={"xs"}
      scroll={"paper"}
      fullWidth={true}
    >
      <StyledFeedbackForm>
        <StyledDialogTitle>
          Edit PO #{dfItem.PoNo} - Line: {dfItem.PoItem.replace(/^0+/, "")}
        </StyledDialogTitle>
        <EditItemForm
          onClose={handleClose}
          onSubmit={handleSubmit}
          dfItem={docFlowItem}
        />
      </StyledFeedbackForm>
    </StyledUserFeedbackDialog>
  );
};

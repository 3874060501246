import * as React from "react";
import { Features } from "src/app/shared/domains/core/pataflag";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Button, Slider, Tooltip, withStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CustomFastAsyncSelect, CustomFastInput, CustomFastSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { AmountIcon, DollarIcon, PercentageIcon } from "src/app/landing/containers/icons";
import { useState } from "react";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { ItemTableCellComponent } from "../../../itemTableCell.component";
import { AccTabItem } from "src/app/docFlows/components/dataTable/ItemDetails/accTabItems";
import { getCondtions } from "../../helper";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";

interface AccDrilldownTableProps {
    documentFlow: DocumentFlow;
    lineItems: any[];
    firstLineItem: DocFlowItem;
    lineNumber: string;
    accItems: AccTabItem[];
    updateItems: Function;
    disableAccountDetails?: boolean;
}

const AllocationSlider = withStyles({
    root: {
        color: '#656f71',
        height: 8,
        '& .MuiSlider-thumb.Mui-disabled': {
            height: 24,
            width: 24,
            marginTop: -8,
            marginLeft: -12,
        },
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    disabled: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);


export default function NewAccountItemComponent(props: AccDrilldownTableProps) {

    const { documentFlow, lineItems, firstLineItem, lineNumber, accItems, updateItems, disableAccountDetails=false } = props;
    const [expand, setExpand] = useState(true);
    const fieldLevelConditions = getCondtions(lineItems?.filter(item => item.PrItem === lineNumber)[0]);
    const classes = useStylesForToolTip({});

    const firstAccItem = accItems.length > 0 ? accItems[0] : null;
    const itemsLength = accItems.filter(item => item["Del_indicator"] !== "X").length;

    const isThereGRIR = fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR;
    const isItOnlyOneItem = accItems.length === 1 || (itemsLength === 1);

    const handleAllocationChange = (event: any, newValue: number | number[]) => {
        console.log(event)
    };

    const accountTypes = [
        { value: "K", label: "Cost Center" },
        { value: "P", label: "WBS / Project" },
        { value: "S", label: "Statistical WBS" },
        { value: "2", label: "Profit Center" },
    ];

    const getAllocationValue = (accItem: AccTabItem) => {
        switch (accItem.DistributionInd) {
            case "2":
                return accItem.DistributionPercent;
            case "3":
                return accItem.NetOrderValue;
            case "1":
            default:
                return accItem.Quantity;
        }
    };

    const fetchAccType = (val: string) => {
        const result = accountTypes.filter(item => item.value === val);
        return result[0];
    };

    const getAccountType = () => {
        if (!firstAccItem) return null;

        switch (firstAccItem.AccCategory) {
            case "P":
                return firstAccItem.CostCenter ? fetchAccType("S") : fetchAccType("P");
            case "2":
                return fetchAccType("2");
            case "K":
                return fetchAccType("K");
            default:
                return null;
        }
    };
    const [accountType, setAccountType] = useState(() => getAccountType());

    const updateSourceItems = (seqNumber: any, tempValues: any) => {
        let tempItems = accItems.map(item => {
            return item.SequenceNo === seqNumber ? { ...item, ...tempValues } : item
        });

        if (itemsLength === 2 && tempValues?.Del_indicator === "X") {  // this is to ensure that after deletion , if there is only 1 line then dis ind = ""
            tempItems = tempItems.map(item => ({ ...item, DistributionInd: "" }));
        };

        tempItems = tempItems.filter(item => !(item?.Del_indicator === "X" && item?.isItSplitItem));

        updateItems(lineNumber, tempItems);
    };

    const accountChangeFunc = (value: any) => {
        setAccountType(value);

        let tempValues: any = { AccCategory: value?.value === "S" ? "P" : value?.value };
        switch (value?.value) {
            case "K":
                tempValues = { ...tempValues, Pr_WBSelement: "" };
                break;
            case "P":
                tempValues = { ...tempValues, CostCenter: "" };
                break;
            case "2":
                tempValues = { ...tempValues, CostCenter: "" };
                break;
            default:
                tempValues = { ...tempValues, CostCenter: "" };
                break;
        }
        const tempItems = accItems.length > 0 ? accItems.map(item => {
            return { ...item, ...tempValues }
        }) : [
            {
                PRNo: firstLineItem.PrNo,
                PONo: firstLineItem.PoNo,
                PRNo_Item: lineNumber,
                PONo_Item: lineNumber,
                SequenceNo: "01",
                DistributionInd: "",
                Quantity: "",
                DistributionPercent: "",
                NetOrderValue: "",
                GLAccount: "",
                CostCenter: "",
                ProfitCenter: "",
                Pr_GlAccountTxt: "",
                Pr_CostCenterTxt: "",
                PR_ProfitcenterTxt: "",
                Pr_WBSelement: "",
                Pr_WbsElementTxt: "",
                PrNetPriceUnit: "",
                PrCurrency: lineItems.filter(item => item.PrItem === lineNumber)[0]?.PrCurrency,
                PrMaterial: "",
                PrMaterialTxt: "",
                Tot_Quantity: "",
                Tot_value: "",
                Units: lineItems.filter(item => item.PrItem === lineNumber)[0]?.PrQuantityUom,
                ...tempValues
            }
        ];
        updateItems(lineNumber, tempItems);
    }

    const costCenterFunc = (seqNumber: any, value: any) => {
        const tempValues = { CostCenter: value?.value, Pr_CostCenterTxt: value?.label };
        updateSourceItems(seqNumber, tempValues);
    }
    const profitCenterFunc = (seqNumber: any, value: any) => {
        const tempValues = { ProfitCenter: value?.value, PR_ProfitcenterTxt: value?.label };
        updateSourceItems(seqNumber, tempValues);
    }
    const wbsChangeFunc = (seqNumber: any, value: any) => {
        const tempValues = { Pr_WBSelement: value?.WBSElement, Pr_WbsElementTxt: value?.label };
        updateSourceItems(seqNumber, tempValues);
    }
    const glAccountChangeFunc = (seqNumber: any, value: any) => {
        const tempValues = { GLAccount: value?.value, Pr_GlAccountTxt: value?.label };
        updateSourceItems(seqNumber, tempValues);
    }

    const disIndChangeFunc = (value: any) => {
        let tempValues;

        switch (value) {
            case "2":
                tempValues = { DistributionInd: value, NetOrderValue: "", Quantity: "" };
                break;
            case "3":
                tempValues = { DistributionInd: value, DistributionPercent: "", Quantity: "" };
                break;
            case "1":
            default:
                tempValues = { DistributionInd: value, DistributionPercent: "", NetOrderValue: "" };
                break;
        };

        const tempItems = accItems.map(item => {
            return { ...item, ...tempValues }
        });
        updateItems(lineNumber, tempItems);

    };

    const allocValueChangeFunc = (seqNumber: any, DistributionInd: string, value: any) => {
        let tempValues;

        switch (DistributionInd) {
            case "2":
                tempValues = { DistributionPercent: value.toFixed(1), NetOrderValue: "", Quantity: "" };
                break;
            case "3":
                tempValues = { NetOrderValue: value.toFixed(2), DistributionPercent: "", Quantity: "" };
                break;
            case "1":
            default:
                tempValues = { Quantity: value.toFixed(3), DistributionPercent: "", NetOrderValue: "" };
                break;
        };

        updateSourceItems(seqNumber, tempValues);


    };

    const deleteItems = (seqNumber: any) => {
        const tempValues = { Del_indicator: "X" };

        updateSourceItems(seqNumber, tempValues);
    };

    const splitFunc = (accItem: AccTabItem) => {
        const newItem = {
            ...accItem,
            SequenceNo: (+accItem.SequenceNo + 1).toString().padStart(2, '0'),
            Quantity: "",
            DistributionPercent: "",
            NetOrderValue: "",
            GLAccount: "",
            CostCenter: "",
            ProfitCenter: "",
            Pr_GlAccountTxt: "",
            Pr_CostCenterTxt: "",
            PR_ProfitcenterTxt: "",
            Pr_WBSelement: "",
            Pr_WbsElementTxt: "",
            isItSplitItem: true
        };
        const finalAccItems = [...accItems, newItem];
        updateItems(lineNumber, finalAccItems);
    }

    return (
        <>
            <div style={{ display: "column", width: "100%", marginBottom: "20px" }}>
                <div style={{ display: "flex", width: "100%", backgroundColor: "#F9F9F9", height: "82px", alignItems: "center" }}>
                    <div style={{ marginLeft: "30px", cursor: "pointer" }} onClick={() => setExpand(prevState => !prevState)}>
                        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                    <div style={{ display: "flex", gap: "10px", marginLeft: "50px", alignItems: "center", width: "20%" }}>
                        <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>Item Number:</div>
                        <div>
                            <ItemTableCellComponent
                                key={lineNumber}
                                values={[lineNumber]}
                                hideLeadingZeros={true}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "50px", width: "80%" }}>
                        <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>Account Type:</div>
                        <div style={{ width: "20%" }}>
                            <CustomFastSelect
                                clearable={false}
                                disabled={isThereGRIR || disableAccountDetails}
                                styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                                    indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                                    control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                                }}
                                initialValue={accountType}
                                options={accountTypes}
                                onChange={accountChangeFunc}
                            />
                        </div>
                    </div>
                </div>

                {expand && <>
                    {accItems?.filter((item: any) => item?.Del_indicator !== "X").map((accItem: any, i) =>
                        <Tooltip
                            interactive
                            classes={{ tooltip: classes.customTooltip }}
                            title={isThereGRIR ? "Account details cannot be changed when Good Receipts or Invoice Receipts are already posted. If needed, these postings must be reversed first and posted again once changes are made" : ""}
                            placement="top" >
                            <div style={{ display: "flex", gap: "100px", height: "100px", alignItems: "center", border: "1px solid #D9D9D9", padding: "0 100px 0 100px" }}>
                                {(accountType?.value === "K" || (accountType?.value === "S")) && <div style={{ width: "100%" }}>
                                    <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>
                                        Cost Center
                                    </div>
                                    <CustomFastAsyncSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                                        }}
                                        disabled={isThereGRIR || disableAccountDetails}
                                        clearable={false}
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        onLoadLimit={3}
                                        onLoad={(x) => MasterService.fetchCostCenter(x?.trim(), [{ id: firstLineItem?.CompCode || documentFlow.POCompanyCode }], firstLineItem?.SystemAlias)}
                                        customDisplay={option => `${option.value} - ${option.label}`}
                                        initialValue={accItem?.CostCenter ? { value: accItem?.CostCenter, label: accItem?.Pr_CostCenterTxt } : null}
                                        onChange={(v) => costCenterFunc(accItem.SequenceNo, v)} />
                                </div>
                                }
                                {accountType?.value === "2" && <div style={{ width: "100%" }}>
                                    <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>
                                        Profit Center
                                    </div>
                                    <CustomFastAsyncSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                                        }}
                                        disabled={isThereGRIR || disableAccountDetails}
                                        clearable={false}
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        onLoadLimit={3}
                                        onLoad={(x) => MasterService.fetchProfitCenter(x?.trim(), firstLineItem?.SystemAlias)}
                                        customDisplay={option => `${option.value} - ${option.label}`}
                                        initialValue={accItem?.ProfitCenter ? { value: accItem?.ProfitCenter, label: accItem?.PR_ProfitcenterTxt } : null}
                                        onChange={(v) => profitCenterFunc(accItem.SequenceNo, v)} />
                                </div>
                                }
                                {(accountType?.value === "P" || accountType?.value === "S") && <div style={{ width: "100%" }}>
                                    <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>
                                        WBS / Project
                                    </div>
                                    <CustomFastAsyncSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                                        }}
                                        disabled={isThereGRIR || disableAccountDetails}
                                        clearable={false}
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        onLoadLimit={3}
                                        onLoad={(x) => MasterService.fetchProjectId(x?.trim(), [{ id: firstLineItem?.CompCode || documentFlow.POCompanyCode }], firstLineItem?.SystemAlias)}

                                        customDisplay={option =>
                                            option.label ? `${option.value} - ${option.label}` : option.value
                                        }
                                        initialValue={accItem?.Pr_WBSelement ? { value: accItem?.Pr_WBSelement, label: accItem?.Pr_WbsElementTxt } : null}
                                        onChange={(v) => wbsChangeFunc(accItem.SequenceNo, v)} />
                                </div>
                                }
                                <div style={{ width: "100%" }}>
                                    <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>
                                        G/L Number
                                    </div>
                                    <CustomFastAsyncSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                                        }}
                                        disabled={isThereGRIR || disableAccountDetails}
                                        clearable={false}
                                        isMulti={false}
                                        menuPortalTarget={document.body}
                                        onLoadLimit={3}
                                        onLoad={(x) => MasterService.fetchGlAccount(x?.trim(), firstLineItem?.SystemAlias)}
                                        customDisplay={option => `${option.value} - ${option.label}`}
                                        initialValue={accItem?.GLAccount ? { value: accItem?.GLAccount, label: accItem?.Pr_GlAccountTxt } : null}
                                        onChange={(v) => glAccountChangeFunc(accItem.SequenceNo, v)} />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div style={{ fontSize: ".8rem", fontWeight: "bold", color: "#5D5858", }}>
                                            Allocate For
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <div onClick={(!isItOnlyOneItem) && (() => disIndChangeFunc("2"))} style={{ cursor: (!isItOnlyOneItem) ? "pointer" : "not-allowed" }}>
                                                <PercentageIcon fill={accItem?.DistributionInd === "2" ? "#ff7f00" : "#656f71"} />
                                            </div>
                                            <div onClick={(!isItOnlyOneItem) && (() => disIndChangeFunc("3"))} style={{ cursor: (!isItOnlyOneItem) ? "pointer" : "not-allowed" }}>
                                                <DollarIcon fill={accItem?.DistributionInd === "3" ? "#ff7f00" : "#656f71"} />
                                            </div>
                                            <div onClick={(!isItOnlyOneItem) && (() => disIndChangeFunc(accItems.length > 1 ? "1" : ""))} style={{ cursor: (!isItOnlyOneItem) ? "pointer" : "not-allowed" }}>
                                                <AmountIcon fill={(!accItem?.DistributionInd || accItem?.DistributionInd === "1") ? "#ff7f00" : "#656f71"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                                        <div style={{ width: "50%", display: "flex", alignItems: "center", gap: "10px" }}>
                                            <CustomFastInput
                                                disabled={disableAccountDetails}
                                                type={"number"}
                                                initialValue={getAllocationValue(accItem)}
                                                onChange={value => allocValueChangeFunc(accItem.SequenceNo, accItem.DistributionInd, +value)}
                                            />
                                            <div>
                                                {accItem?.DistributionInd === "2" && <span>%</span>}
                                                {accItem?.DistributionInd === "3" && <span>{accItem?.PrCurrency}</span>}
                                                {(!accItem?.DistributionInd || accItem?.DistributionInd === "1") && <span>{accItem?.Units}</span>}
                                            </div>
                                        </div>
                                        {
                                            <div style={{ width: "50%" }}>
                                                <AllocationSlider disabled={disableAccountDetails} valueLabelDisplay="auto" value={accItems.length === 1 ? 100 : getAllocationValue(accItem)} onChange={handleAllocationChange} aria-labelledby="continuous-slider" style={{ color: "#656f71" }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                
                                {
                                    !disableAccountDetails && 
                                    <div style={{ color: "#F19001", display: "flex", alignItems: "center", gap: "30px", cursor: "pointer", justifyContent: "flex-end" }}>
                                        {(!isItOnlyOneItem && !isThereGRIR) && <div onClick={() => deleteItems(accItem.SequenceNo)}>
                                            <DeleteForeverIcon />
                                        </div>}
                                        {((i === (itemsLength - 1)) && !isThereGRIR) && !fieldLevelConditions.isItFO &&  <div>
                                            <Button
                                                style={{
                                                    background: "#FFFFFF 0% 0 % no - repeat padding- box",
                                                    border: "1px solid #F19001",
                                                    borderRadius: "50px",
                                                    color: "#F19001",
                                                    paddingLeft: 14,
                                                    paddingRight: 14,
                                                    cursor: "pointer",
                                                    marginRight: "10px"
                                                }}
                                                onClick={() => splitFunc(accItem)}
                                            >
                                                <AddCircleOutlineIcon />
                                                <div style={{ marginLeft: "5px" }} >Split</div>
                                            </Button>
                                        </div>
                                        }
                                    </div>
                                }
                                
                            </div>
                        </Tooltip>
                    )
                    }
                </>

                }
            </div >
        </>
    );
}


import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useResendPoStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledDiv: {
      opacity: '0.5',
      pointerEvents: 'none'
    },
    disableDiv: {
      opacity: '0.8',
      pointerEvents: 'none'
    },
    enableDiv:{
      opacity: '1',
      pointerEvents: 'auto'
    },
    grayColorBackgroud: {
      backgroundColor: '#eef0f1'
    },
    whiteColorBackgroud: {
      backgroundColor: 'white'
    }
  })
);
import React, { useEffect, useState } from 'react';
import { Button,Grid, MuiThemeProvider } from '@material-ui/core';
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'src/app/shared/components/layout/styles';
import { SettingsTheme } from 'src/app/shared/containers/dashboard/components/addCompanyCode/addCompanyCode.styles';

export const EditRequestBackButton = ({onSaveClick, shouldOpen, setShowBackButtonPopUp}:{onSaveClick:Function, shouldOpen: boolean, setShowBackButtonPopUp: Function}) => {
    const [open, setOpen] = useState(shouldOpen);

    const handleOnClick = (e: any) => {
        e.preventDefault();
        setOpen(false);
        setShowBackButtonPopUp(false);
    }

    return (
        <StyledDialog open={open} maxWidth={"xs"} scroll={'paper'} fullWidth={true}>
            <MuiThemeProvider theme={SettingsTheme}>
                <StyledDialogTitle>Exit Document</StyledDialogTitle>
                <StyledDialogContent dividers={true}>
                    <Grid container>
                        <span style={{ fontSize: '1rem' }}>Do you want to save the document first?</span>
                    </Grid>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button color="primary" onClick={(e) => onSaveClick(true)}> Yes</Button>
                    <Button color="primary" onClick={(e) => onSaveClick(false)}> No</Button>
                    <Button color="primary" onClick={handleOnClick}> Cancel</Button>
                </StyledDialogActions>
            </MuiThemeProvider>

        </StyledDialog>
    );
};

import * as React from "react";
import {isNil} from "ramda";

interface Props {
  values: string[],
  fallbackValues?: string[]
  joinCharacter?: string
  hideLeadingZeros?: boolean
  showVertical?: boolean
  color?:boolean
}

export function ItemTableCellComponent(props: Props) {
  const {values, fallbackValues, joinCharacter, hideLeadingZeros, showVertical} = props

  let texts = values.filter(x => !isNil(x) && x.length > 0)
    .map(x => hideLeadingZeros ? x.replace(/^0+/, '') : x)
  if (texts.length === 0 && (fallbackValues || []).length !== 0) {
    texts = fallbackValues.filter(x => !isNil(x) && x.length > 0)
  }

  if (showVertical) {
    return <>
      {texts.map(text => <div style={{color: props.color ? "red" : undefined }}>{text}</div>)}
    </>
  }

  return <span style={{color: props.color ? "red" : undefined }}>{texts.join(joinCharacter || " ")}</span>;
}

import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { newEditRequestPageStyles } from './newEditRequestPageStyles';
import { EditRequestDeletePopUp } from './editRequestDeletePopUp';
import { DISPATCH_TYPES } from '../../EditRequestAllLinesWrapper';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { Features } from 'src/app/shared/domains/core/pataflag';
import { getCondtions } from '../../helper';
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';

export default function ItemDetailsMoreButton({ lineItem, dispatchMethod }: { lineItem: DocFlowItem, dispatchMethod: any }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const styles = newEditRequestPageStyles({});
    const [open, setOpen] = React.useState(false);
    const { hasFeature } = React.useContext(FeaturesContext);
    const fieldLevelConditions = getCondtions(lineItem);
    const isThereGRIR = fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR;
    const IsItEasyRequestPr = fieldLevelConditions.isItEasyRequestPR;
    const classes = useStylesForToolTip({});

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log(lineItem);
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setOpen(prevState => !prevState);
    };

    const handleCopy = () => {
        const payload: any = {
            ...lineItem,
            CopiedItemNo: lineItem.PrItem,
            ExtReqNo: lineItem.ExtReqNo,
            PR_PaymentTerms: lineItem.PR_PaymentTerms,
            Taxcode: lineItem.Taxcode,
            PrRequisitioner: lineItem.PrRequisitioner,
            Supplier: lineItem.Supplier,
            PR_ItemCategory: lineItem.PR_ItemCategory,
            PR_AccCategory: lineItem.PR_AccCategory,
            PuchOrg: lineItem.PuchOrg,
            GrQty: "0.000",
            IrAmount: "0.000",
            isItCopiedLineItem: true
        };
        dispatchMethod({
            type: DISPATCH_TYPES.ADD_NEW_ITEM_LINE,
            payload: payload
        })
    };

    const newLineItem: any = { ...lineItem }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Tooltip
                    interactive
                    classes={{ tooltip: classes.customTooltip }}
                    title={isThereGRIR ? "Document with history (GR or IR) cannot be deleted" : ""}
                    placement="top" >
                    <MenuItem
                        className={styles.button}
                        onClick={!isThereGRIR && handleDelete}
                        style={{
                            color: (!isThereGRIR ? "#F19001" : "lightgrey"),
                            cursor: (!isThereGRIR ? "pointer" : "not-allowed"),
                        }}>
                        <DeleteForeverIcon />
                        <div style={{ marginLeft: "5px" }}>Delete Line</div>
                    </MenuItem>
                </Tooltip>
                <Tooltip
                    interactive
                    classes={{ tooltip: classes.customTooltip }}
                    title={(fieldLevelConditions.isItMulti) ?
                        "Document with Account Category as Multi cannot be copied" : ""}
                    placement="top" >
                    <MenuItem
                        className={styles.button}
                        onClick={(IsItEasyRequestPr || !fieldLevelConditions.isItMulti) && handleCopy}
                        style={{
                            color: (IsItEasyRequestPr || !fieldLevelConditions.isItMulti) ? "#F19001" : "lightgrey",
                            cursor: (IsItEasyRequestPr || !fieldLevelConditions.isItMulti) ? "pointer" : "not-allowed",
                        }}>
                        <DeleteForeverIcon />
                        <div style={{ marginLeft: "5px" }}>Copy & Create New Line</div>
                    </MenuItem>
                </Tooltip>
            </Menu>
            {open && <EditRequestDeletePopUp open={open} setOpen={setOpen} toBeDeleteditems={[lineItem]} dispatchMethod={dispatchMethod}></EditRequestDeletePopUp>}
        </div>
    );
}

import * as React from "react";
import { Button, Checkbox, createStyles, Grid, IconButton, InputAdornment, Link, makeStyles, MuiThemeProvider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootStateStore } from "src/app/application.reducers";

import { SettingsTheme } from "src/app/shared/containers/dashboard/components/userSettings/userSettings.styles";
import { CustomFastInput, CustomFastSelect } from "src/app/shared/components/form";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from "src/app/shared/components/layout/styles";
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { DropzoneDialog } from "material-ui-dropzone";
import { ExceptionEnum, initialTopicValues } from "./EditPrPopup";
import { DocFlowItem } from "../../domains/prpo/poItem";
import { DateTime } from "luxon";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { DownloadDocument } from "../../actions/documentFlow.actions";
import { useDispatch } from "react-redux";
import { ArrowUpward } from "@material-ui/icons";


const exceptionValues = [
    { value: "Contract in place", label: "Contract in place" },
    { value: "Single Source", label: "Single Source" },
    { value: "Emergency case", label: "Emergency case" },
]

interface AttachmentSectionProps {
    values: DocFlowItem;
    sectionHeading: string;
    isItCompetitiveBidding: boolean;   
    files: any[],
    setFiles: Function,
    exceptionSelected?: boolean,
    setExceptionSelected?: Function,
    topicValues?: any[],
    setTopicValues?: Function,    
    filesWithTopics? : any
    setFilesWithTopics? : Function,
    exceptionalType?: any,
    setExceptionalType?: Function,    
    totalValDynamic?: Number,
    setValues?: Function,
    setEnableSave?: Function
    invoices?: any;
    setErrorMsg: Function;
    currencyConversionValue?: Number;
    currencyWarning?: string

};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
      fontStyle: "italic",
      marginBottom: 5,     
    },
    fileName: {
      fontSize: 11,
    },
    subHeading: {
        fontWeight: 700,
        fontSize: 16
    }
  })
);

export const AttachmentSection = (props: AttachmentSectionProps) => {

    const {
    values,
    sectionHeading,
    isItCompetitiveBidding, 
    files,
    setFiles,
    exceptionSelected,
    setExceptionSelected,
    topicValues,
    setTopicValues,  
    filesWithTopics,
    setFilesWithTopics,
    exceptionalType, 
    setExceptionalType,  
    totalValDynamic,
    setValues,
    setEnableSave,
    invoices,
    setErrorMsg,
    currencyConversionValue,
    currencyWarning
    } = props

    const dispatch = useDispatch();
    const { label, fileName, subHeading } = useStyles({});
    const userName = useSelector((state: RootStateStore) => state.shared.user.currentUser.name);    
    const [open, setOpen] = React.useState(false);    
    const handleClose = () => {
        setOpen(false);
      };
    
      const handleOpen = () => {
        setOpen(true);
      };


      function setExceptionAsTopicToFiles() {
        setFilesWithTopics(prevState => {
            let newState = prevState.map(el => {
                el.topic = {value: ExceptionEnum.EXCEPTION, label: ExceptionEnum.EXCEPTION};
                return el;
            })

            return newState;
        })
      }

      function removeExceptionAsTopicToFiles(){
        setFilesWithTopics(prevState => {
            let newState = prevState.map(el => {
                el.topic = null;
                return el;
            })

            return newState;
        })
      }


    const checkBoxMethod = (e) => {
        setExceptionSelected(prevState => !prevState);   
        setErrorMsg('')     
        if (e.target.checked) {        
            setExceptionAsTopicToFiles()            
            setTopicValues([{value: ExceptionEnum.EXCEPTION, label: ExceptionEnum.EXCEPTION}])

        } else {
            setExceptionalType(null);           
            removeExceptionAsTopicToFiles()
            setTopicValues(initialTopicValues);
        }
    };

    function assignTopicToFiles(value, file){

        let newFilesState = filesWithTopics.map((el, i2) => {
            if(file.uniqId === el.uniqId){                    
                el.topic = value;                 
            }
            return el;
        })

        setFilesWithTopics(newFilesState)  
        setErrorMsg('')
    }

    const attachmentfileChange = (fs: File[]) => { // it is File Array,         
        setErrorMsg('')
        let newFiles = Array.from(fs).map(el => {

            let isCB = isItCompetitiveBidding? true: false;           
            let uniqId = Date.now().toString() + el.name;
            let fileName = el.name;
            let exceptionType = exceptionalType? exceptionalType: null;
            let topic = exceptionSelected? 
            {value: ExceptionEnum.EXCEPTION, label: ExceptionEnum.EXCEPTION}: null;            

            return {
                fileName: fileName,
                isCB: isCB,
                // exceptionType: exceptionType,
                uniqId: uniqId,
                file: el,
                topic: topic
            }
        })
 
        setFilesWithTopics(prevState => {
            let newState = [...prevState, ...newFiles];
            return newState;
        })

        setOpen(false);
     
    };

    const deleteCBFile = (uniqId: string) => {        
        setFilesWithTopics(prevState => prevState.filter(file => file.uniqId !== uniqId));
    }


    function deleteOptionalFiles(file){
        const filesAfterDeleting = files.filter(x => x !== file);
        setFiles(filesAfterDeleting);
    }

function attachmentfileChangeForOptionalDocs(fs) {
    setFiles(files.concat(fs));
    setOpen(false);
}

function infoTab(){
    return <div style={{display: 'flex', alignItems: 'center', 
    backgroundColor: 'rgb(214, 212, 212)',    
    marginTop: -10,
    marginBottom: 40,
    padding: '10px 0px 10px 10px',
    gap: 50,
    fontSize: 14
    }}>
        <p style={{margin: 0}}>Threshold: {Number(values.Threshold_value.trim())} {values.Threshold_currency}</p>
        <p style={{margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4}}>Total Amount: {Number(totalValDynamic.toFixed(2))} {values.PrCurrency} {(values.Threshold_currency === values.PrCurrency || !!currencyWarning)? 
        '': 
        `(${(Number(totalValDynamic) * Number(currencyConversionValue)).toFixed(2)} ${values.Threshold_currency})`}
        <span style={{color: 'red', fontSize: 14}}>        
        <Tooltip title={'Above Threshold'}
            placement = 'top'
            >
            <ArrowUpward />
            </Tooltip>
            </span></p>
        <p style={{margin: 0}}>Supplier Status: {values.Supplier_Status}</p>
    </div>
}


function eachRow(){
    let arr = isItCompetitiveBidding? filesWithTopics: files;

    return arr.map((file, i) => <tr key={isItCompetitiveBidding? file.uniqId: file.name}>
        {isItCompetitiveBidding ?
            <td key={file.name} style={{
            padding: "10px",
            backgroundColor: "rgb(250 247 247)",
            borderBottom: "2px solid #d6d4d4",
            width: "280px"
        }}>
            <CustomFastSelect
                options={topicValues}
                placeholder={"Please choose the Topic"}
                initialValue={file.topic}
                onChange={(value) => {
                    if(exceptionSelected && value === null) {
                        setExceptionAsTopicToFiles();
                        return;
                    }
                    assignTopicToFiles(value, file)
                    console.log(value)
                }} />                    
            
            </td> : null
        }
        
        <td style={{
            padding: "10px",
            backgroundColor: "rgb(250 247 247)",
            borderBottom: "2px solid #d6d4d4"
        }}>{userName}</td>
        <td style={{
            padding: "10px",
            backgroundColor: "rgb(250 247 247)",
            borderBottom: "2px solid #d6d4d4"
        }}>{moment().format('MMMM Do, YYYY')}</td>
        <td style={{
            padding: "10px",
            backgroundColor: "rgb(250 247 247)",
            borderBottom: "2px solid #d6d4d4",
            textDecoration: "underline"
        }}>{isItCompetitiveBidding? file.fileName: file.name}</td>
    </tr>)
}

const applyChange = (vals: { [key: string]: any }) => {
    const newItem = {
      ...values,
      ...vals
    };
    setValues(newItem);
    setEnableSave(true);
    setErrorMsg('')
  };


  const handleOnClick  =  async invoice => {
    const fileName = (invoice.Descr.substr(0, invoice.Descr.lastIndexOf(".")) || invoice.Descr) + "." + invoice.TechType;
    if(invoice.TechType === "URL"){
     const response = await MasterService.fetchInvoiceDataInNewTab(values.PrNo, 'BUS2105', invoice.AttaId, values.SystemAlias)
     const openURL = response.includes("http") ? response : "https://" + response;
     window.open(openURL, "_blank")
     return;
    }
    dispatch(new DownloadDocument(values.PrNo, 'BUS2105', invoice.AttaId, fileName, values.SystemAlias));
  }

    return (
        <div key={sectionHeading}>  
               {isItCompetitiveBidding && infoTab()}
            <div><p 
            className={subHeading}
            style={{padding: '0 0 15px 0px'}}
            >{sectionHeading}</p></div>
            <div style={{ display: "flex", alignItems: "center",
            fontSize: 14,
            justifyContent: 'space-between' }}>
                {isItCompetitiveBidding && <div>Offers/Summary</div>}
                <div style={{ display: "flex", alignItems: "center", padding: "10px 40px", border: "2px dashed darkgrey", marginLeft: "30px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      
                            <Button color="primary" component="span"
                            onClick={handleOpen}
                            style={{ cursor: "pointer", fontSize: ".8rem", padding: 0, }}>
                                <span style={{ fontSize: "1rem", paddingRight: "5px" }}>+</span> Choose File Or Drag & Drop</Button>
                
                <DropzoneDialog
                open={open}
                onSave={(fs) => {
                    isItCompetitiveBidding? attachmentfileChange(fs)
                : attachmentfileChangeForOptionalDocs(fs)
                }}
                showPreviews={true}
                maxFileSize={10000000}
                onClose={handleClose}
                // filesLimit={5}
                showAlerts={false}
                />

                    </div>                  
                </div>
                {isItCompetitiveBidding && <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        style={{ color: "Orange" }}
                        checked={exceptionSelected}
                        onChange={(e) => checkBoxMethod(e)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <div style={{ marginRight: "10px" }}>Exception</div>
                    <div style={{ width: "270px" }}>
                        <CustomFastSelect
                            disabled={!exceptionSelected}
                            options={exceptionValues}
                            placeholder={"Please choose the Exception"}
                            initialValue={exceptionalType}
                            onChange={(value) => {setExceptionalType(value)
                                                setErrorMsg('')
                            }} />
                    </div>
                </div>}
            </div>

            {((isItCompetitiveBidding && !!filesWithTopics.length) || 
               (!isItCompetitiveBidding && !!files.length)) &&

            <div style={{ margin: '20px 0px', display: "flex", fontSize: 14 }} key={sectionHeading}>                          

                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            {isItCompetitiveBidding? 
                            <th style={{
                                padding: "15px 10px",
                                backgroundColor: "#d6d4d4",
                                borderBottom: "2px solid #d6d4d4"
                            }}>Topic</th>: null
                            }
                            <th style={{
                                padding: "15px 10px",
                                backgroundColor: "#d6d4d4",
                                borderBottom: "2px solid #d6d4d4"
                            }}>User</th>
                            <th style={{
                                padding: "15px 10px",
                                backgroundColor: "#d6d4d4",
                                borderBottom: "2px solid #d6d4d4"
                            }}>Date</th>
                            <th style={{
                                padding: "15px 10px",
                                backgroundColor: "#d6d4d4",
                                borderBottom: "2px solid #d6d4d4"
                            }}>Name</th>
                        </tr>
                        
                            {eachRow()}

                    </tbody>
                </table>
               
                <table>
                    <tbody>
                        <tr>
                            <th style={{
                                padding: " 20px 10px",
                            }}></th>
                        </tr>
                        {!isItCompetitiveBidding? files.map((file, i) => <tr>
                            <td style={{ cursor: "pointer" }}>{<DeleteOutlinedIcon 
                                onClick={() => deleteOptionalFiles(file)} />}</td>
                        </tr>):                        
                        filesWithTopics.map(el => {
                           return <tr key = {el.uniqId}>
                                <td style={{cursor: 'pointer'}}>
                                {<DeleteOutlinedIcon 
                                onClick={() => deleteCBFile(el.uniqId)} />}
                                </td>
                            </tr>
                        })
                        
                        }
                    </tbody>
                </table>
            </div>
            }

            {!isItCompetitiveBidding && <div 
            style={{marginTop: 30}}
            >
    <p className={subHeading}>Uploaded Files</p>
    <Grid item sm={12}>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice) => (
                      <TableRow key={invoice.AttaId}>
                        <TableCell component="th" scope="row">
                          {invoice.CrName}
                        </TableCell>
                        <TableCell align="right">{DateTime.fromMillis(parseInt(invoice.CrDate.replace(/\D/g,''))).toFormat("dd/MM/yyyy")}</TableCell>
                        <TableCell align="right"><Link underline="always" onClick={() => handleOnClick(invoice)}>{(invoice.Descr.substr(0, invoice.Descr.lastIndexOf(".")) || invoice.Descr) + "." + invoice.TechType}</Link></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            </div>}


            {!isItCompetitiveBidding &&
             <div><Grid item sm={12} className={label} style ={{marginBottom: 5, marginTop:30}}>Comments (Internal only)</Grid>
            <Grid item sm={12}>
              <CustomFastInput
                type={"text"}
                initialValue={values.PoComments}
                onChange={value => applyChange({ PoComments: value })} 
              />
            </Grid>
            </div>
            }

            {isItCompetitiveBidding &&  <div style={{
                borderBottom: "1px dotted gray",
                paddingTop: "15px",
                margin: "30px 0px 30px 0px"
            }}></div> }
               
        </div>
    );
}
import React from 'react'
import { DocFlowItem } from '../../domains/prpo/poItem';
import { AttachmentSection } from './AttachmentSection';

interface AttachmentsAndCommentsSectionProps {
    values : DocFlowItem;
    setValues : Function;
    setEnableSave: Function;
    files: File[] 
    setFiles: Function;
    showCompetitiveBidding: boolean;
    exceptionSelected: boolean;
    setExceptionSelected: Function;
    topicValues: any[];
    setTopicValues: Function;
    filesWithTopics: any[];
    setFilesWithTopics: Function;
    exceptionalType: any;
    setExceptionalType: Function;
    totalValDynamic: Number;
    invoices: any    
    setErrorMsg: Function;
    currencyConversionValue: Number;
    currencyWarning: string
}

export default function AttachmentsAndCommentsSection ({values, 
    setValues, 
    setEnableSave, 
    files, 
    setFiles, 
    showCompetitiveBidding,
    exceptionSelected,
    setExceptionSelected,
    topicValues,
    setTopicValues,
    filesWithTopics,
    setFilesWithTopics,
    exceptionalType,
    setExceptionalType,
    totalValDynamic,
    invoices,
    setErrorMsg,
    currencyConversionValue,
    currencyWarning
}: AttachmentsAndCommentsSectionProps) {        

    return (
        <div>    
            {showCompetitiveBidding &&             
            <AttachmentSection key={"Competitive"}     
            values = {values}           
            files = {files}            
            setFiles = {setFiles}         
            sectionHeading={"Competitive Bidding justification"}
            isItCompetitiveBidding={true}            
            exceptionSelected = {exceptionSelected}
            setExceptionSelected = {setExceptionSelected}
            topicValues = {topicValues}
            setTopicValues = {setTopicValues}            
            filesWithTopics = {filesWithTopics}
            setFilesWithTopics = {setFilesWithTopics}
            exceptionalType = {exceptionalType}
            setExceptionalType = {setExceptionalType}      
            totalValDynamic = {totalValDynamic}   
            setErrorMsg = {setErrorMsg}  
            currencyConversionValue = {currencyConversionValue}
            currencyWarning = {currencyWarning}
            />

            }     
            
            <AttachmentSection key={"Optional Documents"} 
                values= {values}
                files = {files}                
                setFiles = {setFiles}            
                sectionHeading={"Optional Documents (e.g. Project briefs, Technical Drawings,etc.)"}
                isItCompetitiveBidding={false} 
                setValues = {setValues}
                setEnableSave = {setEnableSave}   
                invoices={invoices}       
                setErrorMsg = {setErrorMsg}
/>
</div >
    );
}
import * as React from "react";
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { DocFlowItem } from "../../domains/prpo/poItem";
import { GRItem } from '../../domains/prpo/gr.item'

import Avatar from "@material-ui/core/Avatar";
import DocumentFlow from "../../domains/documentFlow/documentFlow";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import { ContactCardComponent } from "src/app/shared/components/contact-card/contactCard.component";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";



export function extractInitials(firstName: string, lastName: string, code: string): string {
    if (firstName?.length === 0 && lastName?.length === 0) {
        return code.length ? code?.charAt(0) : ""
    }

    const firstNameInitial = firstName?.length ? firstName?.charAt(0) : ""
    const lastNameInitial = lastName?.length ? lastName?.charAt(0) : firstName?.charAt(1)

    return `${firstNameInitial}${lastNameInitial}`.toUpperCase()
}

export const AvatarWithPopover = ({ item, firstName, lastName, userId, email, color, customStyles = {} }: { item?: any, firstName: string, lastName: string, userId: string, email?: string, color?: boolean, customStyles?: any }) => {

    const tempColor = color ? "red" : undefined;
    const toolTipclass = useStylesForToolTip({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { hasFeature } = React.useContext(FeaturesContext);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!firstName && !lastName && !userId) {
        return null;
    }

    return (
        <div>
            <Tooltip
                interactive
                classes={{ tooltip: (hasFeature(Features.RC_163396_Hover_Contact_Card) && email) ? toolTipclass.contactCardToolTip : toolTipclass.customTooltip }}
                title={(hasFeature(Features.RC_163396_Hover_Contact_Card) && email) ?
                    <ContactCardComponent email={email} /> : <div
                        style={{
                            fontSize: 14,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: 5,
                            }}
                        >
                            {`${firstName} ${lastName}`}
                        </div>
                        <div
                            style={{
                                fontSize: 14
                            }}
                        >
                            {email ? `${email}` : `UserID: ${userId}`}
                        </div>
                    </div>
                }
                placement="bottom-start"
            >
                <Avatar style={{ fontSize: "1.1rem", width: "36px", height: "36px", color: tempColor, border: "2px solid #F2F4F8", ...customStyles }}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    {extractInitials(firstName, lastName, userId)}
                </Avatar>
            </Tooltip>
        </div>
    );

}

export const AvatarPopoverWithDelete = ({ item, firstName, lastName, userId, email, color, customStyles = {}, deleteUser }: { item?: any, firstName: string, lastName: string, userId: string, email?: string, color?: boolean, customStyles?: any, deleteUser?: any }) => {

    const useStyles = makeStyles((theme) => ({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        customTooltip: {
            backgroundColor: "#fff",
            color: '#777777',
            boxShadow: "0 0 2px 2px rgba(224, 224, 224, 1)", borderRadius: "5px",
            marginTop: 0,
        },

    }));

    const tempColor = color ? "red" : undefined;
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!firstName && !lastName && !userId) {
        return null;
    }

    return (
        <div>
            <Tooltip
                interactive
                classes={{ tooltip: classes.customTooltip }}
                title={
                    <div
                        style={{
                            fontSize: 14,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <div style={{ flex: '0 0 80%' }}>
                                <div
                                    style={{
                                        paddingBottom: 5,
                                    }}
                                >
                                    <span>{`${firstName} ${lastName}`}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                </div>
                                <div
                                    style={{
                                        fontSize: 14
                                    }}
                                >
                                    <span>{email ? `${email}` : `UserID: ${userId}`}</span>
                                </div>
                            </div>
                            <div style={{ flex: '0 0 2%', borderLeft: '1px solid gray', height: '30px' }}></div>
                            <div style={{ flex: '0 0 5%', paddingTop: '5px', paddingRight: '4px' }}>
                                <span><DeleteIcon style={{ fontSize: '22px', cursor: 'pointer' }} onClick={() => deleteUser(email)}>Delete</DeleteIcon></span>
                            </div>
                        </div>
                    </div>
                }
                placement="bottom-start"
            >
                <Avatar style={{ fontSize: "1.1rem", width: "36px", height: "36px", color: tempColor, border: "2px solid #F2F4F8", ...customStyles }}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    {extractInitials(firstName, lastName, userId)}
                </Avatar>
            </Tooltip>
        </div>
    );

}


import { useMutation, useQuery } from "react-query";
import { PSLService } from "src/app/preferredSuppliers/domains/psl.service";
import { UserInteractionType } from "src/app/shared/domains/user/userInteractionType";
import { DocumentFlowService } from "../domains/documentFlow/documentFlow.service";

export const useResubmitPrHook = (onError: Function) => {
    return useMutation((doFlowItems: any[]) => DocumentFlowService.newSaveDocFlow(UserInteractionType.EditedRequestCockpit, doFlowItems),
        {
            onError: (error, variables, context) => {
                onError() // this is to stop loading when sap call fails
            }
        })
}

export const useCreateFairmrktRfq = () => {
    return useMutation(({ prNo, itemNo }: { prNo: string, itemNo?: string }) => DocumentFlowService.initiateFairmrktRfq(prNo, itemNo)
    )
}


export const useFetchAccTabData = (prNo: string, poNo: string, systemAlias: string) => {
    return useQuery("accountsTab" + prNo + poNo + systemAlias, () => DocumentFlowService.getAccountsTabData(prNo, poNo, systemAlias), { staleTime: Infinity })
}

export const useFetchPricingCondData = (systemAlias: string, aribaPR: string, prNo: string) => {
    return useQuery("pricingConditions" + prNo + aribaPR + systemAlias, () => DocumentFlowService.fetchPRPricing(systemAlias, aribaPR, prNo), { staleTime: Infinity })
}

export const useFetchPslMaterial = (query: any) => {
    return useQuery("nbsPslMaterialData" + JSON.stringify(query),
        () => PSLService.fetchMaterialsForRc(query), { staleTime: Infinity, retry: 2 });
};


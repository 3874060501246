import * as React from "react";
import { Features } from "src/app/shared/domains/core/pataflag";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { LoadingComponent } from "src/app/shared/components/loading";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import NewAccountItemComponent from "./NewAccountItem.component";
import { DISPATCH_TYPES } from "../../EditRequestAllLinesWrapper";
import _ from "lodash";
import { AccTabItem } from "src/app/docFlows/components/dataTable/ItemDetails/accTabItems";

interface AccDrilldownTableProps {
    documentFlow: DocumentFlow;
    lineItems: DocFlowItem[];
    dispatchMethod: any;
    accountDetailsData: Record<string, AccTabItem[]> | null;
    accountDetailsLineNumbers:string[];
    accDetailsEditedLines:string[];
    accDetailsLoading: boolean;
    disableAccountDetails?: boolean;
}

export default function NewAccountDetailsComponent(props: AccDrilldownTableProps) {

    const { documentFlow, lineItems, dispatchMethod, accountDetailsData, accountDetailsLineNumbers,accDetailsEditedLines,accDetailsLoading, disableAccountDetails=false } = props;
    const firstLineItem = lineItems[0];

    const updateItems = (lineNumber: any, accItems: any) => {
        const tempData = { ...accountDetailsData };
        accountDetailsLineNumbers.forEach((item, i) => {
            if (item === lineNumber) {
                tempData[item] = accItems
            };
        });
        const tempAccLinesChanged = _.uniq([...accDetailsEditedLines,lineNumber]);
        dispatchMethod({
            type: DISPATCH_TYPES.UPDATE_ACCOUNT_DETAILS_TO_SEND,
            payload: {
                accDetails:tempData,
                accDataToSend:[...tempAccLinesChanged]
            }
        });
    };

    return (
        accDetailsLoading ? <LoadingComponent /> :
            <>
                {
                    accountDetailsLineNumbers.map(lineItem =>
                        <NewAccountItemComponent
                            key={lineItem}
                            documentFlow={documentFlow}
                            lineItems={lineItems}
                            firstLineItem={firstLineItem}
                            lineNumber={lineItem}
                            accItems={accountDetailsData?.[lineItem] || []}
                            updateItems={updateItems}
                            disableAccountDetails={disableAccountDetails}
                        />
                    )
                }
            </>
    );
}


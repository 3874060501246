import * as React from "react";
import { Button, Checkbox, createStyles, Grid, IconButton, InputAdornment, Link, makeStyles, MuiThemeProvider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { DropzoneDialog } from "material-ui-dropzone";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { ArrowUpward } from "@material-ui/icons";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { RootStateStore } from "src/app/application.reducers";
import { CustomFastInput, CustomFastSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { DownloadDocument } from "src/app/docFlows/actions/documentFlow.actions";
import { DISPATCH_TYPES } from "../../EditRequestAllLinesWrapper";
import { getCondtions, isItFoFun, supplierComments } from "../../helper";
import { logUserIntraction } from "src/app/nbsCockpit/helpers/functions";
import { UserInteractionType } from "src/app/shared/domains/user/userInteractionType";
import { Formatter } from "src/app/shared/components/amount-formatter";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import { useResendPoStyles } from "../../resendingPoStyles";
// import { DISPATCH_TYPES, ExceptionEnum } from "./AllLinesEditPr";


const exceptionValues = [
    { value: "Contract in place", label: "Contract in place" },
    { value: "Single Source", label: "Single Source" },
    { value: "Emergency case", label: "Emergency case" },
]

interface AttachmentSectionProps {
    // values: DocFlowItem;
    itemLines: DocFlowItem[],
    sectionHeading: string;
    isItCompetitiveBidding: boolean;
    attachmentAndCommentsState: any;
    invoices?: any;
    dispatchMethod: Function;
    totalValDynamicInThresholdCurr?: number;
    totalValDynamic: number;
    disableDetails?: boolean
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileName: {
            fontSize: 11,
        },
        subHeading: {
            fontSize: 14,
            color: '#5d5858',
            fontWeight: 500,
            marginBottom: 15
        },
        tableHeader: {
            padding: "15px 10px",
            // borderBottom: "2px solid #d6d4d4",
            fontSize: 14
        },
        tdStyle: {
            padding: "13px 10px",
            // backgroundColor: "rgb(250 247 247)",
            // borderBottom: "2px solid #d6d4d4"
        },
        tableCellStyleUploadedFiles: {
            border: 'none'
        }
    })
);

function SupplierCommentsComponent({ itemLines, dispatchMethod, subHeading, disableDetails = false }: { itemLines: DocFlowItem[], dispatchMethod: Function, subHeading: any, disableDetails?: boolean }) {

    const [showMoreClicked, setShowMoreClicked] = React.useState(false)
    const applyChanges = (vals: any, itemNo: string) => {
        dispatchMethod({
            type: DISPATCH_TYPES.EDIT_LINE_ITEMS,
            payload: {
                lineNo: itemNo,
                editedValues: vals
            }
        })
    };

    const resendPoClasses = useResendPoStyles({});
    const { hasFeature } = React.useContext(FeaturesContext);

    function showLists() {
        return <div
            className={disableDetails ? resendPoClasses.disableDiv : resendPoClasses.enableDiv}
            style={{ maxHeight: 220, overflow: showMoreClicked ? 'auto' : 'hidden' }}
        >{itemLines.map(el => (
            <div style={{
                display: 'flex', alignItems: 'center', height: 54,
                border: '1px solid #D2D2D2', borderRadius: 5, marginBottom: 20,
                color: '#7E7E7E', fontSize: 14
            }}
                key={el.PrItem}
            >
                <div style={{ width: 70, marginLeft: 10, color: 'black' }}>{`Line ${parseInt(el.PrItem)}:`}</div>

                <CustomFastInput
                    style={{
                        width: '100%',
                        outline: 'none',
                        border: 'none',
                    }}
                    type={"text"}
                    normalInput={true}
                    placeholder={!!supplierComments(el.Textsupplier) ? '' : 'Enter Comments'}
                    initialValue={supplierComments(el.Textsupplier)}
                    onChange={value =>
                        applyChanges({
                            Textsupplier: value
                        }, el.PrItem)}
                />
            </div>
        ))}
        </div>
    }

    return <>
        <div
            className={subHeading}
            style={{ margin: "50px 0 15px 0" }}>
            {hasFeature(Features.RC_166206_INCOTERMS) ? "Comments for Supplier - Item" : "Comments for Supplier"}
        </div>
        {showLists()}
        {(itemLines.length > 3 && !showMoreClicked) ?
            <p onClick={() => setShowMoreClicked(true)} style={{ color: '#F19001' }}>Show More ...</p> : null}
    </>
}


export const AttachmentSection = (props: AttachmentSectionProps) => {
    const {
        // values,
        itemLines,
        sectionHeading,
        isItCompetitiveBidding,
        attachmentAndCommentsState,
        invoices,
        dispatchMethod,
        totalValDynamicInThresholdCurr,
        totalValDynamic,
        disableDetails = false
    } = props

    const { files } = attachmentAndCommentsState;
    const { exceptionSelected,
        topicValues,
        filesWithTopics,
        exceptionalType,
        currencyWarning } = attachmentAndCommentsState.CBState

    const values = itemLines[0]
    const dispatch = useDispatch();
    const { fileName, subHeading, tableHeader, tdStyle, tableCellStyleUploadedFiles } = useStyles({});
    const userName = useSelector((state: RootStateStore) => state.shared.user.currentUser.name);
    const fieldLevelConditions = getCondtions(values);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const { hasFeature } = React.useContext(FeaturesContext);

    function setExceptionAsTopicToFiles() {
        dispatchMethod({ type: DISPATCH_TYPES.SELECT_EXCEPTION, payload: { checked: true } })
    }


    const checkBoxMethod = (e) => {
        dispatchMethod({ type: DISPATCH_TYPES.SELECT_EXCEPTION, payload: { checked: e.target.checked } })
    };

    function applyForAllLines(value: any) {
        dispatchMethod({ type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS, payload: value })
    }

    function assignTopicToFiles(value, file) {
        dispatchMethod({ type: DISPATCH_TYPES.ASSIGN_TOPICS_TO_FILES, payload: { value: value, file: file } })
    }

    const attachmentfileChange = (fs: File[]) => { // it is File Array,         
        let newFiles = Array.from(fs).map(el => {

            let isCB = isItCompetitiveBidding ? true : false;
            let uniqId = Date.now().toString() + el.name;
            let fileName = el.name;
            let exceptionType = exceptionalType ? exceptionalType : null;
            let topic = exceptionSelected ?
                { value: 'Exception', label: 'Exception' } : null;

            return {
                fileName: fileName,
                isCB: isCB,
                uniqId: uniqId,
                file: el,
                topic: topic
            }
        })

        dispatchMethod({ type: DISPATCH_TYPES.ADD_CB_FILES, payload: { newFiles: newFiles } })
        setOpen(false);

    };

    const deleteCBFile = (uniqId: string) => {
        dispatchMethod({ type: DISPATCH_TYPES.REMOVE_CB_FILES, payload: { uniqId: uniqId } })
    }


    function deleteOptionalFiles(file) {
        dispatchMethod({ type: DISPATCH_TYPES.UPDATE_OPTIONAL_FILES, payload: { file: file, updateType: 'delete' } })
    }

    function attachmentfileChangeForOptionalDocs(fs) {
        dispatchMethod({ type: DISPATCH_TYPES.UPDATE_OPTIONAL_FILES, payload: { file: fs, updateType: 'add' } })
        setOpen(false);
    }

    function infoTab() {
        return <div style={{
            display: 'flex', alignItems: 'center',
            gap: 50,
            fontSize: 14
        }}>
            <div style={{ display: "flex", gap: "5px" }}>
                <>{`Threshold:`}</>
                <Formatter amount={values.Threshold_value.trim()} currency={values.Threshold_currency} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "5px" }}>
                <div>{`Total Amount:`}</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Formatter amount={totalValDynamic} currency={values.PrCurrency} />
                    {
                        (values.Threshold_currency === values.PrCurrency || !!currencyWarning) ?
                            '' :
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ paddingLeft: "5px" }} >{`(`}</div>
                                <Formatter amount={totalValDynamicInThresholdCurr} currency={values.Threshold_currency} />
                                <>{")"}</>
                            </div>
                    }
                    <span style={{ color: 'red', fontSize: 14 }}>
                        <Tooltip title={'Above Threshold'}
                            placement='top'
                        >
                            <ArrowUpward />
                        </Tooltip>
                    </span>
                </div>
            </div>
            <div>Supplier Status: {values.Supplier_Status}</div>
        </div>
    }

    function eachRow() {
        let arr = isItCompetitiveBidding ? filesWithTopics : files;

        return arr.map((file, i) => <tr key={isItCompetitiveBidding ? file.uniqId : file.name}>
            {isItCompetitiveBidding ?
                <td key={file.name}
                    className={tdStyle}
                    style={{ width: "280px" }}>
                    <CustomFastSelect
                        options={topicValues}
                        placeholder={"Please choose the Topic"}
                        initialValue={file.topic}
                        onChange={(value) => {
                            if (exceptionSelected && value === null) {
                                setExceptionAsTopicToFiles();
                                return;
                            }
                            assignTopicToFiles(value, file)
                            console.log(value)
                        }} />

                </td> : null
            }

            <td className={tdStyle}>{userName}</td>
            <td style={{
                padding: "13px 10px",
            }}>{moment().format('MMMM Do, YYYY')}</td>
            <td
                className={tdStyle}
                style={{ textDecoration: "underline" }}>{isItCompetitiveBidding ? file.fileName : file.name}</td>
        </tr>)
    }

    function dragAndDropEle(isItCompetitiveBidding) {
        return <div style={{ display: "flex", borderRadius: 5, alignItems: "center", border: "2px dashed darkgrey", height: 50, width: isItCompetitiveBidding ? 300 : '35%', justifyContent: 'center' }}>
            <div style={{ display: "flex", alignItems: "center" }}>

                <Button disabled={disableDetails} color="primary" component="span"
                    onClick={handleOpen}
                    style={{ cursor: "pointer", fontSize: ".8rem", padding: 0, }}>
                    <span style={{ fontSize: "1rem", paddingRight: "5px" }}>+</span> Choose File Or Drag & Drop</Button>


                <DropzoneDialog
                    open={open}
                    onSave={(fs) => {
                        isItCompetitiveBidding ? attachmentfileChange(fs)
                            : attachmentfileChangeForOptionalDocs(fs)
                    }}
                    showPreviews={true}
                    maxFileSize={10000000}
                    onClose={handleClose}
                    // filesLimit={5}
                    showAlerts={false}
                />

            </div>
        </div>
    }


    function cbFilesElement(isItCompetitiveBidding) {
        return <div style={{ display: 'flex', gap: 40, marginTop: 30 }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <div>Offers/Summary</div>
                {dragAndDropEle(isItCompetitiveBidding)}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <Checkbox
                        style={{ color: "Orange", padding: 0 }}
                        checked={exceptionSelected}
                        onChange={(e) => checkBoxMethod(e)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <div style={{ marginRight: "10px" }}>Exception</div>
                </div>
                <div style={{ width: "300px" }}>
                    <CustomFastSelect
                        styles={{
                            control: (base, state) => ({ ...base, height: 50 })
                        }}
                        disabled={!exceptionSelected}
                        options={exceptionValues}
                        placeholder={"Please choose the Exception"}
                        initialValue={exceptionalType}
                        onChange={(value) => {
                            dispatchMethod({ type: DISPATCH_TYPES.SET_EXCEPTIONAL_TYPE, payload: value })
                        }} />
                </div>
            </div>

        </div>
    }




    function applyChange(obj) {
        if (hasFeature(Features.RC_164177_SHOW_RESEND_PO_PDF_BUTTON_ON_CONDITIONS) && obj?.PoComments || hasFeature(Features.RC_164177_SHOW_RESEND_PO_PDF_BUTTON_ON_CONDITIONS) && obj?.PoComments === '') {
            obj.commentsChangeIgnore = true;
            dispatchMethod({ type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS, payload: obj })
        } else {
            dispatchMethod({ type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS, payload: obj })
        }
    }


    const handleOnClick = async invoice => {
        const fileName = (invoice.Descr.substr(0, invoice.Descr.lastIndexOf(".")) || invoice.Descr) + "." + invoice.TechType;
        if (invoice.TechType === "URL") {
            const response = await MasterService.fetchInvoiceDataInNewTab(values.PrNo, 'BUS2105', invoice.AttaId, values.SystemAlias)
            const openURL = response.includes("http") ? response : "https://" + response;
            window.open(openURL, "_blank")
            return;
        }
        dispatch(new DownloadDocument(values.PrNo, 'BUS2105', invoice.AttaId, fileName, values.SystemAlias));
        logUserIntraction(UserInteractionType.DownloadInvoiceAttachmentRequestCockpit)
    }

    const resendPoClasses = useResendPoStyles({});

    return (
        <div key={sectionHeading}>
            <div><p className={subHeading} style={{
                marginBottom: isItCompetitiveBidding ? 3 : 15
            }}>{sectionHeading}</p></div>
            {isItCompetitiveBidding && infoTab()}
            {isItCompetitiveBidding && cbFilesElement(cbFilesElement)}
            {!isItCompetitiveBidding && dragAndDropEle(isItCompetitiveBidding)}

            {((isItCompetitiveBidding && !!filesWithTopics.length) ||
                (!isItCompetitiveBidding && !!files.length)) &&

                <div style={{ margin: '20px 0px', display: "flex", fontSize: 14 }} key={sectionHeading}>

                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr style={{ height: 60, backgroundColor: 'rgb(245, 245, 245)' }}>
                                {isItCompetitiveBidding ?
                                    <th className={tableHeader}>Topic</th> : null
                                }
                                <th className={tableHeader}>User</th>
                                <th className={tableHeader}>Date</th>
                                <th className={tableHeader}>Name</th>
                            </tr>

                            {eachRow()}

                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr>
                                <th style={{
                                    padding: " 20px 10px",
                                }}></th>
                            </tr>
                            {!isItCompetitiveBidding ? files.map((file, i) => <tr key={Date.now().toString() + file.name}>
                                <td style={{ cursor: "pointer" }}>{<DeleteOutlinedIcon
                                    onClick={() => deleteOptionalFiles(file)} />}</td>
                            </tr>) :
                                filesWithTopics.map(el => {
                                    return <tr key={el.uniqId}>
                                        <td style={{ cursor: 'pointer' }}>
                                            {<DeleteOutlinedIcon
                                                onClick={() => deleteCBFile(el.uniqId)} />}
                                        </td>
                                    </tr>
                                })

                            }
                        </tbody>
                    </table>
                </div>
            }

            {!isItCompetitiveBidding && <div
                style={{ marginTop: 50 }}
            >
                <p className={subHeading}>Uploaded Files</p>
                <Grid item sm={12}>
                    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                    <TableCell className={tableCellStyleUploadedFiles}>User</TableCell>
                                    <TableCell className={tableCellStyleUploadedFiles} align="right">Date</TableCell>
                                    <TableCell className={tableCellStyleUploadedFiles} align="right">Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((invoice) => (
                                    <TableRow key={invoice.AttaId}>
                                        <TableCell component="th" scope="row">
                                            {invoice.CrName}
                                        </TableCell>
                                        <TableCell align="right">{DateTime.fromMillis(parseInt(invoice.CrDate.replace(/\D/g, ''))).toFormat("dd/MM/yyyy")}</TableCell>
                                        <TableCell align="right"><Link underline="always" onClick={() => handleOnClick(invoice)}>{invoice?.Filename}</Link></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>}

            {hasFeature(Features.RC_166206_INCOTERMS) && <div style={{ margin: "20px 0" }}>
                <div
                    className={subHeading}
                    style={{ margin: "50px 0 15px 0" }}>
                    Comments for Supplier - Header
                </div>
                    <CustomFastInput
                        style={{
                            width: '100%',
                            outline: 'none',
                            height: 54,
                            border: '1px solid #D2D2D2', borderRadius: 5, marginBottom: 20,
                            color: '#7E7E7E', fontSize: 14,
                        }}
                        type={"text"}
                        normalInput={true}
                        placeholder={!!supplierComments(itemLines[0]?.HdrSupplierComm) ? '' : 'Enter Comments'}
                        initialValue={supplierComments(itemLines[0]?.HdrSupplierComm)}
                        onChange={value =>
                            applyForAllLines({ HdrSupplierComm: value })}
                    />
            </div>
            }


            {/* Comments for Suppliers component */}
            {
                hasFeature(Features.RC_164613_EDIT_SUPPLIER_COMMENTS_DOCTYPE_FO) ?
                    !isItCompetitiveBidding && (fieldLevelConditions.isItEasyRequestPR) && <SupplierCommentsComponent itemLines={itemLines} dispatchMethod={dispatchMethod} subHeading={subHeading} disableDetails={disableDetails} />
                    :
                    !isItCompetitiveBidding && (fieldLevelConditions.isItEasyRequestPR && !fieldLevelConditions.isItFO) && <SupplierCommentsComponent itemLines={itemLines} dispatchMethod={dispatchMethod} subHeading={subHeading} disableDetails={disableDetails} />
            }

            {
                !isItCompetitiveBidding &&
                <div className={disableDetails ? resendPoClasses.disableDiv : resendPoClasses.enableDiv} ><Grid item sm={12}
                    className={subHeading}
                    style={{ marginBottom: 15, marginTop: 40 }}>Comments (Internal only)</Grid>
                    <Grid item sm={12}>
                        <div style={{
                            height: 54,
                            border: '1px solid #D2D2D2',
                            borderRadius: 5,
                            marginBottom: 20,
                            paddingLeft: 10
                        }}>
                            <CustomFastInput
                                style={{
                                    width: '100%',
                                    outline: 'none',
                                    border: 'none',
                                    paddingTop: 10
                                }}
                                type={"text"}
                                normalInput={true}
                                initialValue={values.PoComments}
                                onChange={value => applyChange({ PoComments: value })}
                                placeholder="Enter Comments"
                            />
                        </div>
                    </Grid>
                </div>
            }

            {
                isItCompetitiveBidding && <div style={{
                    borderBottom: "1px dotted gray",
                    paddingTop: "15px",
                    margin: "30px 0px 30px 0px"
                }}></div>
            }

        </div >
    );
}
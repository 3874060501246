import React, { useEffect, useRef, useReducer, SetStateAction, useState } from 'react';
import DocumentFlow from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { NewBannerWrapperForMultiEdit } from 'src/app/shared/components/banner';
import { VerticalView } from 'src/app/shared/components/layout/styles';
import { TabSelector } from './TabSelector';
import { ResetButton as CloseBtn } from 'src/app/shared/components/filters/filters.styles';
import GeneralDetails from './AllSections/GeneralDetails';
import { buildItemsToSend, buildPricingCondsToSend, CBBasedOnSupplierStatusFun, check_CB_For_Rejection, check_CB_on_ValueChangeAndCurrencyChange, compareObj, diff, ExceptionEnum, fetchCurrencyConversion, formatValue, getCondtions, getDeletedItems, getFinalLineItems, getResendPoPdfNotificationMessage, getTotalValState, getUnDeletedItems, initialTopicValues, prepareItemsAndAccountsChangesData, removeNullValues, sortItem } from './helper';
import { getLabel } from '../../complianceIndicator';
import { getType } from '../../dataTable/docFlow/docFlowTableRelated/CbLink';
import ItemDetails from './AllSections/newEditRequest/itemDetails';
import AttachmentsAndComments from './AllSections/AttachmentsAndComments/AttachmentsAndComments';
import { newEditRequestPageStyles } from './AllSections/newEditRequest/newEditRequestPageStyles';
import { extractInitials } from '../AvatarWithPopover';
import { Avatar, Tooltip } from '@material-ui/core';
import _, { groupBy } from 'lodash';
import { MasterService } from 'src/app/shared/domains/master/master.service';
import { Invoice } from 'src/app/shared/domains/master/invoice';
import { PriceService2 } from 'src/app/shared/services/price.service';
import { useDispatch, useSelector } from 'react-redux';
import { NewRequestChangeAllDocFlowItems, NewRequestChangeAllDocFlowItemsWithAccountDetails, RequestChangeAllDocFlowItems } from 'src/app/docFlows/actions/documentFlow.actions';
import { Features, RemoteConfigurableValues } from 'src/app/shared/domains/core/pataflag';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { RootStateStore } from 'src/app/application.reducers';
import { PopupLoading } from 'src/app/shared/components/loading';
import { PataflagService } from 'src/app/shared/services/pataflag.service';
import { EditRequestBackButton } from '../EditRequestBackButton';
import { ContactCardComponent } from 'src/app/shared/components/contact-card/contactCard.component';
import ResendPoPdfReceipnts from './ResendPoPdfReciepents';
import { useQueryClient } from 'react-query';
import { useFetchAccTabData, useFetchPricingCondData } from 'src/app/docFlows/hooks/docflow.hooks';
import { useResendPoStyles } from './resendingPoStyles';
import { useFetchConditionTypes } from 'src/app/shared/domains/master/master.hooks';

interface AllLinesPropsType {
    allLines: DocFlowItem[];
    df: DocumentFlow;
    onDone: Function;
    onClose: Function;
    disableEditPr: boolean;
    zone: string;
    vertexData?: any;
}

export enum DISPATCH_TYPES {
    GET_INVOICES = "GET_INVOICES",
    COMMON_IN_ALL_LINE_ITEMS = 'COMMON_IN_ALL_LINE_ITEMS',
    MASS_DELETE_ITEMS = "MASS_DELETE_ITEMS",
    EDIT_LINE_ITEMS = "EDIT_LINE_ITEMS",
    SELECT_EXCEPTION = 'SELECT_EXCEPTION',
    ASSIGN_TOPICS_TO_FILES = 'ASSIGN_TOPICS_TO_FILES',
    ADD_CB_FILES = 'ADD_CB_FILES',
    REMOVE_CB_FILES = 'REMOVE_CB_FILES',
    UPDATE_OPTIONAL_FILES = 'UPDATE_OPTIONAL_FILES',
    CURRENCY_CONVERSION_CHANGE = 'CURRENCY_CONVERSION_CHANGE',
    SET_EXCEPTIONAL_TYPE = "SET_EXCEPTIONAL_TYPE",
    HANDLE_CURRENCY_CHANGE = "HANDLE_CURRENCY_CHANGE",
    ADD_NEW_ITEM_LINE = "ADD_NEW_ITEM_LINE",
    UPDATE_PRICE_FIELDS = "UPDATE_PRICE_FIELDS",
    CB_ERROR = "CB_ERROR",
    LOAD_ACCOUNT_DETAILS_INITIAL_DATA = "LOAD_ACCOUNT_DETAILS_INITIAL_DATA",
    LOAD_PRICING_CONDITIONS_INITIAL_DATA = "LOAD_PRICING_CONDITIONS_INITIAL_DATA",
    UPDATE_PRICING_CONDITIONS_DATA = "UPDATE_PRICING_CONDITIONS_DATA",
    UPDATE_ACCOUNT_DETAILS_TO_SEND = "UPDATE_ACCOUNT_DETAILS_TO_SEND",
}

const EditAvatarContainer = ({ el, df, values, label1, email }: { el?: string, df?: any, values?: any, label1: string, email?: string }) => {
    const showContactCard = PataflagService.getInstance().flag(Features.RC_163396_Hover_Contact_Card);
    return (
        <div style={{ display: "flex", margin: "5px 20px 20px 0" }}>
            <Tooltip
                interactive
                classes={{ tooltip: (showContactCard && email) ? "contactCardToolTip" : "customTooltip" }}
                title={(showContactCard && email) ?
                    <ContactCardComponent email={email} /> : ""
                }
                placement="bottom-start"
            >
                <Avatar style={{ fontSize: "1.1rem", width: "36px", height: "36px", border: "2px solid #F2F4F8" }}
                >
                    {el ? el.substring(0, 2) : extractInitials(df?.Creatorfnam, df?.Creatorlnam, "")}
                </Avatar></Tooltip>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                <span style={{ fontSize: "14px", fontWeight: "bold", color: "#F19001" }}>{label1}</span>
                {label1 !== "Generic Emails" && <span style={{ fontSize: "12px", fontWeight: 500, color: "#7E7E7E", lineHeight: "1" }}>{`${df.Creatorfnam} ${df.Creatorlnam}`}</span>}
                {<span style={{ fontSize: "12px" }}>{email.toLocaleLowerCase()}</span>}
            </div>
        </div >)
}

function defineInitialState(allLines: DocFlowItem[], df: DocumentFlow) {
    const formattedAllLines = allLines.map(item => {
        delete item["tableData"];
        const PrNetPriceUnit = formatValue(item.PrNetPriceUnit);
        const PrQuantityRaw = formatValue(item.PrQuantityRaw);
        const PrPriceRaw = formatValue(item.PrPriceRaw);
        const PrNetPriceRaw = formatValue(item.PrNetPriceRaw);
        const PrPrice = formatValue(item.PrPrice);
        return {
            ...item,
            PrNetPriceUnit,
            PrQuantityRaw,
            PrPriceRaw,
            PrNetPriceRaw,
            PrPrice
        };
    })
    let firstLineItem = formattedAllLines[0];
    let totalValue = getTotalValState(formattedAllLines);
    let CBStatusBasedOnSupplierStatus = CBBasedOnSupplierStatusFun(firstLineItem, df);
    let totalValDynamicInThresholdCurr = totalValue * 1;
    let threshold_value = Number(formattedAllLines[0].Threshold_value?.trim());
    let complianceIndicator = getLabel(
        getType(
            df.CollectiveNumber,
            df.CompetitiveBiddingIdInAzure,
            df.IsCbActive
        )
    )

    return {
        enableSave: false,
        errorMsg: "",
        invoices: [],
        docFlowHeader: df,
        initialItemLines: formattedAllLines,
        itemLines: _.cloneDeep(formattedAllLines),
        accDetailsEditedLines: [],
        accountDetailsLineNumbers: [],
        accountDetailsData: null,
        pricingConditionData: [],
        initialAccountDetailsData: null,
        initialTotalVal: totalValue,
        totalValDynamic: totalValue,
        totalValDynamicInThresholdCurr: totalValue,
        attachmentAndCommentsState: {
            files: [],
            CBState: {
                exceptionSelected: false,
                topicValues: initialTopicValues,
                exceptionalType: null,
                filesWithTopics: [],
                totalVal: totalValue,
                showCompetitiveBidding: check_CB_For_Rejection(df, formattedAllLines[0], threshold_value, totalValDynamicInThresholdCurr),
                showCBbasedOnSupplierStatus: CBStatusBasedOnSupplierStatus,
                complianceIndicatorState: complianceIndicator,
                currencyConversionValue: 1,
                currencyWarning: ""
            }
        },
        hideResendPoButton: "",
        prNewItemAdded: false,
        prFieldsModified: false
    }
}

function reducerMethod(state, action) {
    switch (action.type) {
        case DISPATCH_TYPES.EDIT_LINE_ITEMS: {
            let editedLineItemNo = action.payload.lineNo;
            let spendorChanged = action.payload?.spendorChanged;
            const newItemLines = getFinalLineItems(state.itemLines, editedLineItemNo, action.payload.editedValues);
            if (spendorChanged) {
                let newState = {
                    ...state,
                    itemLines: newItemLines
                };
                return newState;
            } else {
                let newState = {
                    ...state,
                    itemLines: newItemLines,
                    hideResendPoButton: action.payload,
                    prFieldsModified: true
                };
                return newState;
            }
        }
        case DISPATCH_TYPES.LOAD_ACCOUNT_DETAILS_INITIAL_DATA: {

            const finalResult = groupBy(action.payload, ({ PRNo_Item }) => PRNo_Item);

            const tempLineNumbers = Object.keys(finalResult);

            let newState = {
                ...state,
                accountDetailsLineNumbers: tempLineNumbers,
                accountDetailsData: finalResult,
                initialAccountDetailsData: finalResult
            };
            return newState;
        }
        case DISPATCH_TYPES.LOAD_PRICING_CONDITIONS_INITIAL_DATA: {
            const tempData = action.payload?.filter(item => item.chngeType === "CREATE")?.map(item => {
                return {
                    ...item,
                    isItInitialItem: true,
                    Cond_flag: "X"
                }
            });

            let newState = {
                ...state,
                pricingConditionData: tempData,
            };
            return newState;
        }
        case DISPATCH_TYPES.UPDATE_PRICING_CONDITIONS_DATA: {

            const nonEditedLines = state?.pricingConditionData?.filter(item => item?.itemNumber !== action.payload.itemNumber);

            let newState = {
                ...state,
                pricingConditionData: [...action.payload.editedLines, ...nonEditedLines]
            };
            return newState;
        }
        case DISPATCH_TYPES.UPDATE_ACCOUNT_DETAILS_TO_SEND: {
            let newState = {
                ...state,
                accountDetailsData: action.payload.accDetails,
                accDetailsEditedLines: action.payload.accDataToSend
            };
            return newState;
        }
        case DISPATCH_TYPES.UPDATE_PRICE_FIELDS: {
            let editedLineItemNo = action.payload.lineNo;
            const newItemLines = getFinalLineItems(state.itemLines, editedLineItemNo, action.payload.editedValues);
            let newTotalValue = getTotalValState(newItemLines);
            let currencyConversionValue = state.attachmentAndCommentsState.CBState.currencyConversionValue;
            let totalValDynamicInThresholdCurr = newTotalValue * currencyConversionValue;
            let threshold_value = Number(state.initialItemLines[0].Threshold_value?.trim())
            let initialCurrency = state.initialItemLines[0].PrCurrency;
            let changedCurrency = state.initialItemLines[0].PrCurrency;
            let initialTotalVal = state.initialTotalVal;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };

            let showCompetitiveBidding = state.attachmentAndCommentsState.CBState.showCompetitiveBidding
            if (state.attachmentAndCommentsState.CBState.showCBbasedOnSupplierStatus) {
                let initialTotavlValInThresholdCurr = initialTotalVal * currencyConversionValue
                showCompetitiveBidding = check_CB_on_ValueChangeAndCurrencyChange(state.docFlowHeader, state.itemLines[0], threshold_value, totalValDynamicInThresholdCurr, initialTotalVal, newTotalValue, initialTotavlValInThresholdCurr, initialCurrency, changedCurrency);
            }

            let CBState = {
                ...state.attachmentAndCommentsState.CBState,
                showCompetitiveBidding: showCompetitiveBidding
            }

            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState, errorMsg: '', }


            return {
                ...state,
                itemLines: newItemLines,
                totalValDynamic: newTotalValue,
                attachmentAndCommentsState: attachmentAndCommentsStateCopy,
                totalValDynamicInThresholdCurr: totalValDynamicInThresholdCurr,
                hideResendPoButton: action.payload,
                prFieldsModified: true
            };
        }
        case DISPATCH_TYPES.ADD_NEW_ITEM_LINE: {
            let tempPrNumber = Number(state.itemLines[state.itemLines.length - 1].PrItem);
            if (tempPrNumber < Number(state.itemLines[state.itemLines.length - 1].PR_Itemcount)) {
                tempPrNumber = Number(state.itemLines[state.itemLines.length - 1].PR_Itemcount);
            };
            let tempPoNumber = Number(state.itemLines[state.itemLines.length - 1].PoItem);
            if (tempPoNumber < Number(state.itemLines[state.itemLines.length - 1].PO_Itemcount)) {
                tempPoNumber = Number(state.itemLines[state.itemLines.length - 1].PO_Itemcount);
            };

            const newPrItemNo = (tempPrNumber + 10).toString().padStart(5, "0");
            const newPoItemNo = (tempPoNumber + 10).toString().padStart(5, "0");
            const newItemLines = [...state.itemLines, { ...action.payload, PrItem: newPrItemNo, PoItem: newPoItemNo }];
            let newTotalValue = getTotalValState(newItemLines);

            const addedItem = { CopiedItemNo: action.payload.CopiedItemNo, newPrItemNo: newPrItemNo, isItCopiedLineItem: action.payload.isItCopiedLineItem, isItAddedFromPsl: action.payload.isItAddedFromPsl ? true : false, isSupplierStatusMandatoryOrSoleSource: action.payload.isSupplierStatusMandatoryOrSoleSource };

            const tempAccData = { ...state.accountDetailsData };
            let newAccItems = [];

            if (!addedItem.isItAddedFromPsl) {
                newAccItems = tempAccData[addedItem.CopiedItemNo]?.map(newAccItem => ({ ...newAccItem, PRNo_Item: addedItem.newPrItemNo, PONo_Item: addedItem.newPrItemNo }))
                tempAccData[addedItem.newPrItemNo] = newAccItems;
            }
            const newAccLineNumbers = [...state.accountDetailsLineNumbers, addedItem.newPrItemNo];
            const tempAccItemLines = [...state.accDetailsEditedLines, addedItem.newPrItemNo];

            let currencyConversionValue = state.attachmentAndCommentsState.CBState.currencyConversionValue;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let totalValDynamicInThresholdCurr = newTotalValue * currencyConversionValue;
            let threshold_value = Number(newItemLines[0].Threshold_value?.trim())
            let initialCurrency = state.initialItemLines[0].prCurrency;
            let changedCurrency = newItemLines[0].prCurrency;
            let initialTotalVal = state.initialTotalVal;

            let showCompetitiveBidding = state.attachmentAndCommentsState.CBState.showCompetitiveBidding
            if (state.attachmentAndCommentsState.CBState.showCBbasedOnSupplierStatus) {
                let initialTotavlValInThresholdCurr = initialTotalVal * currencyConversionValue
                showCompetitiveBidding = check_CB_on_ValueChangeAndCurrencyChange(state.docFlowHeader, state.itemLines[0], threshold_value, totalValDynamicInThresholdCurr, initialTotalVal, newTotalValue, initialTotavlValInThresholdCurr, initialCurrency, changedCurrency);
            }

            let CBState = {
                ...state.attachmentAndCommentsState.CBState,
                showCompetitiveBidding: showCompetitiveBidding
            }

            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState, errorMsg: '' }

            return {
                ...state,
                itemLines: newItemLines,
                addedItem: addedItem,
                accountDetailsData: tempAccData,
                accDetailsEditedLines: tempAccItemLines,
                accountDetailsLineNumbers: newAccLineNumbers,
                totalValDynamic: newTotalValue,
                attachmentAndCommentsState: attachmentAndCommentsStateCopy,
                totalValDynamicInThresholdCurr: totalValDynamicInThresholdCurr,
                hideResendPoButton: action.payload,
                prNewItemAdded: true
            };


            // let newState = {
            //     ...state,
            //     itemLines: newItemLines,
            //     totalValDynamic: newTotalValue,
            // };
            // return newState;
        }
        case DISPATCH_TYPES.MASS_DELETE_ITEMS: {
            let newItemLines = state.itemLines;
            action.payload.deletedItems.forEach(item => {
                newItemLines = getFinalLineItems(newItemLines, item.PrItem, { isToBeDeleted: "X" });
            })
            let newTotalValue = getUnDeletedItems(newItemLines).length > 0 ? getTotalValState(newItemLines) : 0;
            let tempDeletedLineItems = getDeletedItems(newItemLines).map(item => item.PrItem);

            let activeLineNumbers = Object.keys(state.accountDetailsData);
            activeLineNumbers = activeLineNumbers.filter(lineNumber => !(tempDeletedLineItems.includes(lineNumber)));

            let currencyConversionValue = state.attachmentAndCommentsState.CBState.currencyConversionValue;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let totalValDynamicInThresholdCurr = newTotalValue * currencyConversionValue;
            let threshold_value = Number(newItemLines[0].Threshold_value?.trim())
            let initialCurrency = state.initialItemLines[0].prCurrency;
            let changedCurrency = newItemLines[0].prCurrency;
            let initialTotalVal = state.initialTotalVal;

            let showCompetitiveBidding = state.attachmentAndCommentsState.CBState.showCompetitiveBidding
            if (state.attachmentAndCommentsState.CBState.showCBbasedOnSupplierStatus) {
                let initialTotavlValInThresholdCurr = initialTotalVal * currencyConversionValue
                showCompetitiveBidding = check_CB_on_ValueChangeAndCurrencyChange(state.docFlowHeader, state.itemLines[0], threshold_value, totalValDynamicInThresholdCurr, initialTotalVal, newTotalValue, initialTotavlValInThresholdCurr, initialCurrency, changedCurrency);
            }

            let CBState = {
                ...state.attachmentAndCommentsState.CBState,
                showCompetitiveBidding: showCompetitiveBidding
            }

            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState, errorMsg: '' }

            return {
                ...state,
                itemLines: newItemLines,
                accountDetailsLineNumbers: activeLineNumbers,
                totalValDynamic: newTotalValue,
                attachmentAndCommentsState: attachmentAndCommentsStateCopy,
                totalValDynamicInThresholdCurr: totalValDynamicInThresholdCurr,
                hideResendPoButton: action.payload,
                prFieldsModified: true
            };

            // let newState = {
            //     ...state,
            //     itemLines: newItemLines,
            //     totalValDynamic: newTotalValue,
            // };
            // return newState;
        }
        case DISPATCH_TYPES.HANDLE_CURRENCY_CHANGE: {
            const { newAllItems, currencyConversionValue, currencyWarning } = action.payload;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let newTotalValue = getTotalValState(newAllItems);
            let totalValDynamicInThresholdCurr = newTotalValue * currencyConversionValue;
            let threshold_value = Number(newAllItems[0].Threshold_value?.trim())
            let initialCurrency = state.initialItemLines[0].prCurrency;
            let changedCurrency = newAllItems[0].prCurrency;
            let initialTotalVal = state.initialTotalVal;

            // check for CB condition after changing the currency
            // 1. showCBbasedOnSupplierStatus
            // 2. if above is true then check for total price condition (totalDynamicInThresholdCurr > thresholdVal)
            // 3. if currency is not changed and totalDynamic Val is less than or equal to totalVal then no CB

            let showCompetitiveBidding = state.attachmentAndCommentsState.CBState.showCompetitiveBidding
            if (state.attachmentAndCommentsState.CBState.showCBbasedOnSupplierStatus) {
                let initialTotavlValInThresholdCurr = initialTotalVal * currencyConversionValue
                showCompetitiveBidding = check_CB_on_ValueChangeAndCurrencyChange(state.docFlowHeader, state.itemLines[0], threshold_value, totalValDynamicInThresholdCurr, initialTotalVal, newTotalValue, initialTotavlValInThresholdCurr, initialCurrency, changedCurrency);
            }


            let CBState = {
                ...state.attachmentAndCommentsState.CBState, currencyConversionValue: currencyConversionValue,
                currencyWarning: currencyWarning,
                showCompetitiveBidding: showCompetitiveBidding,
                filesWithTopics: showCompetitiveBidding ? state.attachmentAndCommentsState.CBState.filesWithTopics : []
            }
            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return {
                ...state,
                errorMsg: '',
                itemLines: newAllItems,
                attachmentAndCommentsState: attachmentAndCommentsStateCopy,
                totalValDynamicInThresholdCurr: totalValDynamicInThresholdCurr,
                totalValDynamic: newTotalValue,
                hideResendPoButton: action.payload,
                prFieldsModified: true
            };

        }

        case DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS: {
            // below logic is written considering multiple key values in payload
            // ex: payload: {key1: val1, key2: val2, key3: val3}
            const payload = action.payload
            const keys = Object.keys(action.payload);
            const spendorChanged = action.payload?.spendorChanged;
            const commentsChangeIgnore = payload?.commentsChangeIgnore;
            let itemLinesCopy = state.itemLines.map(el => {
                keys.forEach(key => {
                    el[key] = payload[key]
                })
                return el;
            })
            if (commentsChangeIgnore || spendorChanged) {
                return { ...state, errorMsg: '', itemLines: itemLinesCopy }
            } else {
                return { ...state, errorMsg: '', itemLines: itemLinesCopy, hideResendPoButton: payload, prFieldsModified: true }
            }
        }

        case DISPATCH_TYPES.GET_INVOICES:
            return { ...state, invoices: action.payload };

        case DISPATCH_TYPES.UPDATE_OPTIONAL_FILES: { // ADDING OR REMOVING
            let updateType = action.payload.updateType;
            let file = action.payload.file;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState }
            let files = [...attachmentAndCommentsStateCopy.files]

            if (updateType === 'delete') {
                files = files.filter(x => x !== file);
                attachmentAndCommentsStateCopy.files = files
                return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy }
            }

            files = files.concat(file);
            attachmentAndCommentsStateCopy.files = files
            return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy }
        }

        case DISPATCH_TYPES.CURRENCY_CONVERSION_CHANGE: {
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let totalValDynamic = state.totalValDynamic
            let totalValDynamicInThresholdCurr;
            let threshold_value = Number(state.itemLines[0].Threshold_value?.trim());
            let { currencyConversionValue, currencyWarning } = action.payload

            totalValDynamicInThresholdCurr = totalValDynamic * currencyConversionValue;

            let CBState = {
                ...attachmentAndCommentsStateCopy.CBState,
                currencyConversionValue: currencyConversionValue,
                currencyWarning: currencyWarning,
                // We have added the below line based on 166925 User Story
                showCompetitiveBidding: check_CB_For_Rejection(state.docFlowHeader, state.itemLines[0], threshold_value, totalValDynamicInThresholdCurr)
            }

            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }

            return { ...state, errorMsg: '', attachmentAndCommentsState: attachmentAndCommentsStateCopy, totalValDynamicInThresholdCurr: totalValDynamicInThresholdCurr };
        }

        case DISPATCH_TYPES.ADD_CB_FILES: {
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let { newFiles } = action.payload;
            let filesWithTopics = [...attachmentAndCommentsStateCopy.CBState.filesWithTopics]
            let CBState = {
                ...attachmentAndCommentsStateCopy.CBState,
                filesWithTopics: [...filesWithTopics, ...newFiles]
            }
            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy };
        }

        case DISPATCH_TYPES.REMOVE_CB_FILES: {
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let { uniqId } = action.payload;
            let filesWithTopics = attachmentAndCommentsStateCopy.CBState.filesWithTopics.filter(file => file.uniqId !== uniqId);
            let CBState = { ...attachmentAndCommentsStateCopy.CBState, filesWithTopics: filesWithTopics }
            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy };
        }

        case DISPATCH_TYPES.SET_EXCEPTIONAL_TYPE: {
            let exceptionType = action.payload
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let CBState = { ...attachmentAndCommentsStateCopy.CBState, exceptionalType: exceptionType }
            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy, errorMsg: '' };
        }


        case DISPATCH_TYPES.SELECT_EXCEPTION: {
            let { checked } = action.payload;
            let exceptionFromState = state.attachmentAndCommentsState.CBState.exceptionSelected
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let CBState = {
                ...attachmentAndCommentsStateCopy.CBState,
                exceptionSelected: !exceptionFromState
            }

            if (checked) {
                CBState.filesWithTopics = CBState.filesWithTopics.map(el => {
                    el.topic = { value: ExceptionEnum.EXCEPTION, label: ExceptionEnum.EXCEPTION };
                    return el;
                })
                CBState.topicValues = [{ value: ExceptionEnum.EXCEPTION, label: ExceptionEnum.EXCEPTION }]
            }

            if (!checked) {
                CBState.exceptionType = null;
                CBState.filesWithTopics = CBState.filesWithTopics.map(el => {
                    el.topic = null;
                    return el;
                })
                CBState.topicValues = initialTopicValues
            }

            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return { ...state, errorMsg: '', attachmentAndCommentsState: attachmentAndCommentsStateCopy };

        }

        case DISPATCH_TYPES.ASSIGN_TOPICS_TO_FILES: {
            let { file, value } = action.payload;
            let attachmentAndCommentsStateCopy = { ...state.attachmentAndCommentsState };
            let filesWithTopics = attachmentAndCommentsStateCopy.CBState.filesWithTopics.map((el, i2) => {
                if (file.uniqId === el.uniqId) {
                    el.topic = value;
                }
                return el;
            })

            let CBState = { ...attachmentAndCommentsStateCopy.CBState, filesWithTopics: filesWithTopics }
            attachmentAndCommentsStateCopy = { ...attachmentAndCommentsStateCopy, CBState: CBState }
            return { ...state, attachmentAndCommentsState: attachmentAndCommentsStateCopy, errorMsg: '' };

        }

        case DISPATCH_TYPES.CB_ERROR: {
            return { ...state, errorMsg: action.payload }
        }

        default:
            return state;
    }
}

export default function EditRequestAllLinesWrapper(props: AllLinesPropsType) {

    const { allLines, df, onDone, onClose, disableEditPr = false, vertexData = {loading:false,data:{}} } = props;
    const queryClient = useQueryClient();

    const headerRef = useRef(null);
    const generalDetailsRef = useRef();
    const itemDetailsRef = useRef();
    const poSendingOptionsRef = useRef();
    const attachmentsAndCommentsRef = useRef();
    const [{ invoices, docFlowHeader, initialItemLines, itemLines, accDetailsEditedLines, accountDetailsLineNumbers, accountDetailsData, pricingConditionData, initialTotalVal, totalValDynamic,
        attachmentAndCommentsState,
        totalValDynamicInThresholdCurr,
        errorMsg,
        hideResendPoButton,
        prNewItemAdded,
        prFieldsModified
    }, dispatchMethod] = useReducer(reducerMethod, null, () =>
        defineInitialState(allLines, df));
    const { CBState } = attachmentAndCommentsState
    const dispatch = useDispatch();
    const sending: boolean = useSelector(
        (state: RootStateStore) => state.docFlows.documentFlows.data.onUpdateLoading
    );
    const { sectionDiv, tab_selector_selected_tab, header, boldFont } = newEditRequestPageStyles({});
    const genericEmails = itemLines[0] ? itemLines[0].PoOutRecipient.split(",") : []
    const { hasFeature } = React.useContext(FeaturesContext);
    const [showBackButtonPopUp, setShowBackButtonPopUp] = React.useState(false);
    const { data: accTabData, isLoading: accDetailsLoading } = useFetchAccTabData(df.PurchaseReqNo, "", df.SystemAlias); // here always PR number should be taken for account tab
    const { data: pricingCondionsData, isLoading: PricingCondionsLoading } = useFetchPricingCondData(df.SystemAlias, itemLines[0]?.ExtReqNo, df.PurchaseReqNo);

    // Resend Po State Management

    const [disableResendPoPdfSection, setDisableResendPoPdfSection] = useState(allLines[0]?.Devact_Flag === 'X' ? false : true)
    const [finalResendPodata, setFinalResendPoData] = useState({ 'changedStatus': false, postData: {} });

    // General Tab Titile is Resend PO Kind 
    const [finalTitle, setFinalTitle] = useState(allLines[0]?.Title_resend);

    const [showResendPoPdfButton, setShowResendPoPdfButton] = useState('block');
    const [resendPoNotificationMessage, setResendPoNotificationMessage] = useState('');

    // -- Resend Po End

    function justToicsArr(filesWithTopics) {
        let topicsArr = filesWithTopics.map(el => {

            if (el.topic?.value) return el.topic.value;

            return el.topic;
        })
        return topicsArr
    }

    const onDoneFinal = () => {
        if (accDetailsEditedLines.length > 0) {
            queryClient.invalidateQueries({ queryKey: ["accountsTab" + df.PurchaseReqNo + df.SystemAlias] });
        }
        onDone();
    }

    function areAllTopicsAreValid() {
        // case 1 - atleast one file is selected, which has isCB = true;

        if (!CBState.filesWithTopics.length) {
            dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `You need to have either an attachment with "Event Summary" as a topic, or three attachments with Offer no. 1, 2, and 3 as topics` })
            // scrollTo(attachmentsAndCommentsRef.current)
            return false;
        }

        // case 2 - checking for exception case

        // if exception is selected, automatically for all files topic will be assigned as exception,    

        if (CBState.exceptionSelected) {
            // checking for if exception Type is selected

            if (CBState.exceptionalType) {
                dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `` })
                return true;
            }
            else {
                dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `Kindly select Exception Type` })
                // scrollTo(attachmentsAndCommentsRef.current)
                return false;
            }
        }


        // case 3 - If filesWithTopics has length > 0

        const topicsArr = justToicsArr(CBState.filesWithTopics);

        if (CBState.filesWithTopics.length) {
            // if any file contains topic as null
            if (topicsArr.includes(null)) {
                dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `All attached files must have the topic value assigned` })
                // scrollTo(attachmentsAndCommentsRef.current)
                return false;
            }

            // check for if the selected topics contains Event Summary

            if (topicsArr.includes('Event summary')) {
                dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `` })
                return true;
            }

            // check for offer no 1, offer no 2, offer no3

            if (topicsArr.includes('Offer no. 1 (selected supplier)') &&
                topicsArr.includes("Offer no. 2") &&
                topicsArr.includes("Offer no. 3")
            ) {
                dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `` })
                return true;
            }

        }
        dispatchMethod({ type: DISPATCH_TYPES.CB_ERROR, payload: `You need to have either an attachment with "Event Summary" as a topic, or three attachments with Offer no. 1, 2, and 3 as topics` })
        // scrollTo(attachmentsAndCommentsRef.current)
        return false;
    }

    function addCBDetailsToLineItems(lineItem) {
        //  Adding CB details

        const TotalPoAmount = (CBState.showCompetitiveBidding && !CBState.currencyWarning)
            && itemLines[0].PrCurrency?.trim() !== itemLines[0].Threshold_currency?.trim() ?
            `${Number(totalValDynamic)} ${itemLines[0].PrCurrency} (${totalValDynamicInThresholdCurr} ${itemLines[0].Threshold_currency})` :
            `${Number(totalValDynamic)} ${itemLines[0].PrCurrency}`

        const SupplierStatus = itemLines[0].Supplier_Status?.trim();
        const IsCBRequired = CBState.showCompetitiveBidding;
        const CCIndicator = df.CollectiveNumber ? `${df.CollectiveNumber} - ${CBState.complianceIndicatorState}` : `Unknown`;
        const IsEasyRequest = itemLines[0].ExtReqNo === "EASY_REQUEST_PR" || itemLines[0].ExtReqNo.slice(0, 3) === "EPR"

        // do not send mail to PO creator for Easy Req PR     
        if (!!IsEasyRequest) {
            lineItem = { ...lineItem, POCreatoremail: '' }
        }
        return {
            ...lineItem,
            TotalPOAmount: TotalPoAmount,
            SupplierStatus: SupplierStatus,
            IsCBRequired: IsCBRequired,
            CCIndicator: CCIndicator,
            IsEasyRequest: IsEasyRequest
        }
    }

    const findEmailBoolean = (data: any, totalVal: number, totalValDynamic: number, currencyConversionValue: number) => {

        // if its easy pr deletion then no email to be sent
        if (data?.isToBeDeleted === "X") {
            return false
        };

        if (data.SendPDF === "X") return true;  // send email if po sending options have been changed

        const propertiesToIgnore = ["SupplierStatus", "TotalPOAmount", "IsCBRequired", "CCIndicator", "IsEasyRequest", "CBFileData"];
        const tempValues = { ...data };
        propertiesToIgnore.forEach(val => delete tempValues[val]);
        const tempData = Object.keys(tempValues);

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this section is to check if only total amount related fields - Quantity, Price, Per Unit were changed
        // if so, and if total value has decreased then dontsend the email
        const fieldsAffectingTotalAmount = ["PrQuantityRaw", "PrPriceRaw", "PrNetPriceRaw", "PrNetPriceUnit"];
        let tempValuesToPopOut = [...tempData];
        fieldsAffectingTotalAmount.forEach(item => {
            tempValuesToPopOut = tempValuesToPopOut.filter(el => el !== item);
        })
        if ((tempValuesToPopOut.length === 0) &&
            ((totalValDynamic * currencyConversionValue) < (totalVal * currencyConversionValue))
        ) { return false };
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // email is skipped if no change is made to the below fields in the requests
        const fieldsToSendEmail = ["PR_PaymentTerms", "PrQuantityRaw", "PrPriceRaw", "PrNetPriceRaw", "PrCurrency", "PrNetPriceUnit", "Pr_wbsid", "Pr_CostCenter", "Pr_GlAccount", "PR_PROFIT", "Textsupplier"];
        let shouleEmailBeSent: boolean = false; // it will be enabled if any change is made to any field within fieldsToSendEmail array

        tempData.forEach(item => {
            if (fieldsToSendEmail.filter(el => el === item).length > 0) { shouleEmailBeSent = true };
        })
        if (!shouleEmailBeSent) { return false };

        return true;
    };

    const checkCompCodePurchOrg = (lineItem: any) => {
        // for certain comp code and purch org, skip the email if its Easy Request PR
        let prchOrgCompCodes: any = PataflagService.getInstance().valueOf(RemoteConfigurableValues.SKIP_EMAIL_FOR_EASY_REQ);
        try { prchOrgCompCodes = JSON.parse(prchOrgCompCodes); } catch (e) { }
        const compCodesList = prchOrgCompCodes?.compCode?.split(",") || [];
        const purchOrgList = prchOrgCompCodes?.purchOrg?.split(",") || [];

        return [compCodesList, purchOrgList];
    }

    const checkIfEmailIsNeeded = (actualFirstLineItem, filesUploaded: any, allItems: any, totalVal: number, totalValDynamic: number, currencyConversionValue: number) => {
        if(hasFeature(Features.RC_172318_STOP_EDIT_EASY_REQ_KIND_PR_MAILS)) {
            if (filesUploaded.length > 0) { return true }; // if files are uploaded send emails by default
            if (allItems.length === 0) return false; // in case of only po sending options changed, then this will be empty
            if (allItems.length > 0 && allItems[0].IsEasyRequest) { return false }
            // if (allAccountItems.length > 0 && allAccountItems[0].IsEasyRequest) { return false }
            return true;
        }else{
            // this function is to ensure email is sent only on certain conditions
            // if it returns false then no email will be sent

            if (filesUploaded.length > 0) { return true }; // if files are uploaded send emails by default

            if (allItems.length === 0) return false; // in case of only po sending options changed, then this will be empty

            if (!allItems[0].IsEasyRequest) { return true } // this func is valid only for easy request PRs

            const [compCodesList, purchOrgList] = checkCompCodePurchOrg(actualFirstLineItem);

            if (compCodesList.includes(actualFirstLineItem?.CompCode) || purchOrgList.includes(actualFirstLineItem?.PuchOrg)) {
                return false;
            }

            const emailArray = allItems.map(item =>
                findEmailBoolean(item, totalVal, totalValDynamic, currencyConversionValue),
            );

            return emailArray.includes(true)
        }
    };

    const checkIfAnyThingChanged = (): [any[], any[], FormData, FormDataEntryValue[]] => {
        const formData = new FormData();

        // in this array, we gonna merge the CB data with itemLines and then push.
        // this itemLinesCopy will be sent to the dispatch method..
        let itemLinesCopy = []

        if (attachmentAndCommentsState.files.length) {
            attachmentAndCommentsState.files.forEach((file, i) => formData.append("Files", file, file.name))
        }


        const finalAllDocFlowItems = [];
        const sortedInitialLines = sortItem(initialItemLines);
        itemLines.forEach((item, i) => {
            let itemCopy = { ...item };

            // Add CB details to each line item here
            // do not send mail to PO creator for Easy Req PR
            itemCopy = addCBDetailsToLineItems(itemCopy)

            // adding CB files data to only first line item
            if (i === 0) {
                CBState.filesWithTopics.forEach(el => {
                    formData.append('Files', el.file, el.fileName)
                });
                let CBFileData = {};
                CBFileData['exceptionalType'] = CBState.exceptionalType;
                CBFileData['files'] = CBState.filesWithTopics.map(el => {
                    return {
                        // isCB: el.isCB,
                        fileName: el.fileName,
                        uniqId: el.uniqId,
                        topic: el.topic.value
                    }
                });
                itemCopy = { ...itemCopy, CBFileData: CBFileData }
            }

            const finalItem = compareObj(sortedInitialLines[i], item);
            if (!_.isEmpty(finalItem)) {
                finalAllDocFlowItems.push({
                    ...finalItem,
                    PrNo: item.PrNo,
                    PrItem: item.PrItem,
                    SystemAlias: item.SystemAlias,
                    IsEasyRequest: item.ExtReqNo === "EASY_REQUEST_PR" || item.ExtReqNo.slice(0, 3) === "EPR"
                })
            };
            itemLinesCopy.push(itemCopy)
        });
        const filesUploaded = formData?.getAll("Files");

        // if(hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) && !_.isEmpty(vertexData.data)){
        //     const newItemsCopy:any = itemLinesCopy.map((data:any)=>{
        //         const newVertexData = vertexData.data.data;
        //         let newItem = {};
        //         if(data.Taxcode === 'I1' || data.Potaxcode === 'I1'){
        //             const newData = newVertexData.lineItems.filter(item=>item.lineItemNumber === data.PrItem);
        //             if(newData.length > 0 && newData.length === 1)
        //                 newItem = {...data,total: newVertexData.total.toString(), totalTax: newData[0].totalTax.toString()};
        //         }
        //         return _.isEmpty(newItem) ? data : newItem;
        //     });
        //     console.log(newItemsCopy)
        //     return [newItemsCopy, finalAllDocFlowItems, formData, filesUploaded];
        // }

        return [itemLinesCopy, finalAllDocFlowItems, formData, filesUploaded];
    }

    const checkIfEmailToBeSkipped = (lineItem: any, filesUploaded: any, finalAllDocFlowItems: any) => {
        if(hasFeature(Features.RC_169649_ENABLE_EMAIL_ACC_TAB_AND_PLANT_VENDOR) || hasFeature(Features.RC_172318_STOP_EDIT_EASY_REQ_KIND_PR_MAILS)) {
            if(hasFeature(Features.RC_169649_ENABLE_EMAIL_ACC_TAB_AND_PLANT_VENDOR)) {
                const filesDataOnlyAvailable = Object.keys(finalAllDocFlowItems[0]).length === 6 ? true : false;
                if (finalAllDocFlowItems[0].IsEasyRequest || ((filesUploaded.length === 0 || filesUploaded.length > 0) && filesDataOnlyAvailable)) { return false };
                return true;
            }else{
                if(finalAllDocFlowItems.length === 0 || finalAllDocFlowItems[0].IsEasyRequest || ((filesUploaded.length === 0 || filesUploaded.length > 0) && finalAllDocFlowItems.length === 0)) return false;
                return true;
            }
        }else{
            const [compCodesList, purchOrgList] = checkCompCodePurchOrg(itemLines[0]);
            if (compCodesList.includes(lineItem?.CompCode) || purchOrgList.includes(lineItem?.PuchOrg)) {
                return false;
            };
            if (filesUploaded.length > 0 && finalAllDocFlowItems.length === 0) { return false };
            return true
        }
    }

    const submitData = (finalAllDocFlowItems: any[], filesUploaded: FormDataEntryValue[], itemLinesCopy: any[], formData: FormData) => {

        // coz of US 165599, when ever anything on item line is changes, corresponding acc details has to be sent to SAP. hence the messy below code. 
        // also these acc lines needs to capture any changes done at acc item lines as well and everything else changed on edit details page. 

        const isItEasyRequestPr = getCondtions(itemLines[0]).isItEasyRequestPR;

        let tempFinalAllDocFLowItems = [...finalAllDocFlowItems];

        let lineItemsWithAccLineItems = [];

        // below messy code is to send acc details for the lines which had some changes
        
        tempFinalAllDocFLowItems.forEach(item => {
            let tempAccItems = buildItemsToSend(accountDetailsData[item?.PrItem], df.SystemAlias, isItEasyRequestPr);
            tempAccItems = tempAccItems?.map(accLine => {
                const tempLineItem = { ...item };

                // below code is to ensure that acc details are sent to SAP only from Acc items and not from item lines
                delete tempLineItem["Pr_GlAccount"];
                delete tempLineItem["Pr_CostCenter"];
                delete tempLineItem["Pr_wbsid"];
                delete tempLineItem["PR_PROFIT"];
                delete tempLineItem["PR_AccCategory"];
                
                // below code is to Not SEND PoComments chanages to SAP (when swith off toogle)
                if(!hasFeature(Features.RC_172495_SEND_PO_COMMENTS_TO_SAP)){
                    delete tempLineItem["PoComments"];
                    tempLineItem.commentsChangeIgnore = false;
                }

                return { ...accLine, ...tempLineItem }
            });
            lineItemsWithAccLineItems = [...lineItemsWithAccLineItems, ...tempAccItems];
        });

        tempFinalAllDocFLowItems = [...lineItemsWithAccLineItems];

        // below change is to capture any changes made in acc details section for the lines which didnt have any item level changes

        if (accDetailsEditedLines.length > 0) {
            if (!(tempFinalAllDocFLowItems.length > 0)) {
                accDetailsEditedLines.forEach(lineNumber => {
                    let tempAccItems = buildItemsToSend(accountDetailsData[lineNumber], df.SystemAlias, isItEasyRequestPr);
                    tempFinalAllDocFLowItems = [...tempFinalAllDocFLowItems, ...tempAccItems];
                });
            } else {
                const linesAlreadyAdded = finalAllDocFlowItems.map(item => item.PrItem);
                const linesToBeAdded = accDetailsEditedLines.filter(lineNumber => !linesAlreadyAdded.includes(lineNumber));
                linesToBeAdded.forEach(lineNumber => {
                    let tempAccItems = buildItemsToSend(accountDetailsData[lineNumber], df.SystemAlias, isItEasyRequestPr);
                    tempFinalAllDocFLowItems = [...tempFinalAllDocFLowItems, ...tempAccItems];
                });
            }
        };

        if (pricingConditionData?.some(item => item?.editedItem)) {

            let priceCondWithItemChanges = [];

            // below messy code is to send add item changes to price conditions
            console.log(pricingConditionData);

            const priceConditionsToSend = pricingConditionData?.filter(item => item?.editedItem);

            priceCondWithItemChanges = priceConditionsToSend.map(priceItem => {
                const tempPriceCond = buildPricingCondsToSend(priceItem, df.SystemAlias, isItEasyRequestPr);
                const tempItem = finalAllDocFlowItems.filter(item => item.PrItem === priceItem?.itemNumber)[0];
                return {
                    ...tempPriceCond,
                    ...tempItem
                }
            });

            tempFinalAllDocFLowItems = [...tempFinalAllDocFLowItems, ...priceCondWithItemChanges];
        }

        if (hasFeature(Features.RC_163611_RESEND_PO) && showResendPoPdfButton === 'block') {
            if (tempFinalAllDocFLowItems.length === 0) {
                itemLines.forEach(item => (
                    tempFinalAllDocFLowItems.push({
                        IdNo: 'D5',
                        PrNo: item.PrNo,
                        PrItem: item.PrItem,
                        SystemAlias: item.SystemAlias,
                        IsEasyRequest: item.ExtReqNo === "EASY_REQUEST_PR" || item.ExtReqNo.slice(0, 3) === "EPR",
                        ...finalResendPodata.postData,
                        newId: 'D5'
                    })
                )
                )
            } else {
                tempFinalAllDocFLowItems = tempFinalAllDocFLowItems.map(item => ({
                    ...item,
                    ...finalResendPodata.postData,
                }));
            }
        } else if (hasFeature(Features.RC_163611_RESEND_PO) && finalResendPodata?.changedStatus) {
            if (tempFinalAllDocFLowItems.length === 0) {
                itemLines.forEach(item => (
                    tempFinalAllDocFLowItems.push({
                        ...finalResendPodata.postData,
                        PrNo: item.PrNo,
                        PrItem: item.PrItem,
                        SystemAlias: item.SystemAlias,
                        IsEasyRequest: item.ExtReqNo === "EASY_REQUEST_PR" || item.ExtReqNo.slice(0, 3) === "EPR"
                    })
                )
                )
            } else {
                tempFinalAllDocFLowItems = tempFinalAllDocFLowItems.map(item => ({
                    ...item,
                    ...finalResendPodata.postData,
                }));
            }
        }

        if(hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) && !_.isEmpty(vertexData.data)){
            const newtempFinalAllDocFLowItems:any = tempFinalAllDocFLowItems.map((data:any)=>{
                const newVertexData = vertexData.data.data;
                let newItem = {};
                const newData = newVertexData.lineItems.filter(item=>item.lineItemNumber === data.PrItem);
                if(newData.length > 0 && newData.length === 1)
                    newItem = {...data,total: newVertexData.total.toString(), totalTax: newData[0].totalTax.toString()};
                return _.isEmpty(newItem) ? data : newItem;
            });
            tempFinalAllDocFLowItems = newtempFinalAllDocFLowItems;
            console.log(tempFinalAllDocFLowItems);
        }

        if(hasFeature(Features.RC_169649_ENABLE_EMAIL_ACC_TAB_AND_PLANT_VENDOR) || hasFeature(Features.NBS_168421_SHOW_SAP_SCOPE_TAB)){
            if(hasFeature(Features.RC_169649_ENABLE_EMAIL_ACC_TAB_AND_PLANT_VENDOR)){
                console.log(1)
                console.log(tempFinalAllDocFLowItems) // docflowItems this data for SAP saving usage
                console.log(docFlowHeader) // prData 
                console.log(initialItemLines) // oldItemsData
                console.log(itemLinesCopy) // newItems or df // used to prepare data for send mail and sap scopr
                const ItemsAndAccountsDeatilsChangesData = prepareItemsAndAccountsChangesData(tempFinalAllDocFLowItems,itemLinesCopy); // Merging any pr changes with itemscopy data
                console.log(ItemsAndAccountsDeatilsChangesData)
                dispatch(
                    new NewRequestChangeAllDocFlowItemsWithAccountDetails(
                        tempFinalAllDocFLowItems,
                        onDoneFinal,
                        docFlowHeader,
                        initialItemLines,
                        checkIfEmailIsNeeded(itemLines[0], filesUploaded, ItemsAndAccountsDeatilsChangesData, initialTotalVal, totalValDynamic, attachmentAndCommentsState.currencyConversionValue),
                        ItemsAndAccountsDeatilsChangesData,
                        formData,
                        checkIfEmailToBeSkipped(itemLines[0], filesUploaded, tempFinalAllDocFLowItems),
                        accTabData
                    )
                );
            }else{
                console.log(2)
                dispatch(new NewRequestChangeAllDocFlowItems(
                        tempFinalAllDocFLowItems,
                        onDoneFinal,
                        docFlowHeader,
                        initialItemLines,
                        checkIfEmailIsNeeded(itemLines[0], filesUploaded, finalAllDocFlowItems, initialTotalVal, totalValDynamic, attachmentAndCommentsState.currencyConversionValue),
                        itemLinesCopy,
                        formData,
                        checkIfEmailToBeSkipped(itemLines[0], filesUploaded, finalAllDocFlowItems)
                    ) 
                );
            }
        }else{
            console.log(3)
            dispatch(
                new RequestChangeAllDocFlowItems(
                    tempFinalAllDocFLowItems,
                    onDoneFinal,
                    docFlowHeader,
                    initialItemLines,
                    checkIfEmailIsNeeded(itemLines[0], filesUploaded, finalAllDocFlowItems, initialTotalVal, totalValDynamic, attachmentAndCommentsState.currencyConversionValue),
                    itemLinesCopy,
                    formData,
                    checkIfEmailToBeSkipped(itemLines[0], filesUploaded, finalAllDocFlowItems)
                )
            );
        }
    };

    const handleSubmit = () => {
        // if this PR has CB, then it has to go through some checks before submitting
        // if checks are not satisfying then we show error in areAllTopicsAreValid fun and return from handleSubmit fun
        if (CBState.showCompetitiveBidding) {
            const isAlltopicsAreValid = areAllTopicsAreValid()
            if (!isAlltopicsAreValid) return;
        }

        const [itemLinesCopy, finalAllDocFlowItems, formData, filesUploaded]: [any[], any[], FormData, FormDataEntryValue[]] = checkIfAnyThingChanged();

        if (finalAllDocFlowItems.length > 0 || filesUploaded.length > 0 || (hasFeature(Features.RC_163611_RESEND_PO) && finalResendPodata?.changedStatus) || accDetailsEditedLines.length > 0 || pricingConditionData?.some(item => item?.editedItem)) {
            submitData(finalAllDocFlowItems, filesUploaded, itemLinesCopy, formData);
        } else return;
    }

    const handleResendPoSubmit = () => {
        const [itemLinesCopy, finalAllDocFlowItems, formData, filesUploaded]: [any[], any[], FormData, FormDataEntryValue[]] = checkIfAnyThingChanged();
        submitData(finalAllDocFlowItems, filesUploaded, itemLinesCopy, formData);
    }

    const backHandler = () => {

        const [itemLinesCopy, finalAllDocFlowItems, formData, filesUploaded]: [any[], any[], FormData, FormDataEntryValue[]] = checkIfAnyThingChanged();

        if ((finalAllDocFlowItems.length > 0 || filesUploaded.length > 0 || (hasFeature(Features.RC_163611_RESEND_PO) && finalResendPodata?.changedStatus) || accDetailsEditedLines.length > 0)) {
            setShowBackButtonPopUp(true);
        } else { onClose() };
    };

    const onSaveClick = (val) => {
        if (val) {
            handleSubmit();
        }
        else if (!val) {
            onClose();
        }
    };

    useEffect(() => {
        if (accTabData && accTabData.length > 0) {
            // setAccountDetailsData(accTabData);
            dispatchMethod({ type: DISPATCH_TYPES.LOAD_ACCOUNT_DETAILS_INITIAL_DATA, payload: accTabData });;
        };
    },
        [accTabData]);

    useEffect(() => {
        if (pricingCondionsData && pricingCondionsData.length > 0) {
            // setAccountDetailsData(accTabData);
            dispatchMethod({ type: DISPATCH_TYPES.LOAD_PRICING_CONDITIONS_INITIAL_DATA, payload: pricingCondionsData });
        };
    },
        [pricingCondionsData]);


    // This is being used for giving className to menu elements when scrolling down to specific section.
    useEffect(() => {
        const prEditor = document.querySelector('#edit-request-mainpage');
        const sectionAll = document.querySelectorAll(`#edit-request-mainpage > section[id]`);

        // This function calculates the offset of the sections to the top of PR list and sets the visibleSection accordingly.

        const scrollHandler = () => {
            const scrollY = prEditor.scrollTop;
            sectionAll.forEach((current) => {
                const sectionHeight = current['offsetHeight'];
                const sectionTop = current['offsetTop'];
                const sectionId = current.getAttribute('id');
                // 80 is the spacing for fixed header and section header margins.
                // 40 is for the spaces between sections.

                let a = 120;
                let b = 60;

                if (scrollY >= sectionTop - a && scrollY < sectionTop + sectionHeight - b) {
                    const link = document.querySelector(`.${sectionId}-link`);
                    link.classList.add(tab_selector_selected_tab);
                } else if (sectionId === 'generalDetails' && scrollY <= sectionTop) {
                    // Here, we handle general details link if user scrolls up higher than PR.
                    document?.querySelector(`.generalDetails-link`)?.classList.add(tab_selector_selected_tab);
                } else {
                    document?.querySelector(`.${sectionId}-link`)?.classList.remove(tab_selector_selected_tab);
                }
            });
        };

        const throttleScroll = () => {
            let isBlocked = false;
            return () => {
                if (!isBlocked) {
                    scrollHandler();
                    isBlocked = true;
                    setTimeout(() => {
                        isBlocked = false;
                        scrollHandler();
                    }, 250);
                }
            };
        };

        setTimeout(() => {
            scrollTo(document?.getElementById('generalDetails'));
        }, 300)
        prEditor.addEventListener('scroll', throttleScroll(), { passive: true });

        // MAKING API CALLS TO GET INVOICES

        async function fetchInvoices() {
            const response: SetStateAction<Invoice[]> = await MasterService.fetchInvoices(
                itemLines[0].PrNo,
                "BUS2105",
                itemLines[0].SystemAlias
            );
            dispatchMethod({ type: DISPATCH_TYPES.GET_INVOICES, payload: response });
        }

        fetchInvoices();

        const showCBbasedOnSupplierStatus: boolean = CBState.showCBbasedOnSupplierStatus;
        const thres_Currency: string = itemLines[0].Threshold_currency;
        const thres_PrCurrency: string = itemLines[0].PrCurrency;

        fetchCurrencyConversion(showCBbasedOnSupplierStatus, thres_Currency, thres_PrCurrency).then((result) => {
            if (!result.ToCurrency && !result.ToAmount) {
                // show some warning msg... like selected currency is unable to convert to
                // threshold currency (EUR), Kindly change the currency or else system will consider
                // default currency as threshold currency.
                console.log("Currency error");
                dispatchMethod({
                    type: DISPATCH_TYPES.CURRENCY_CONVERSION_CHANGE,
                    payload: {
                        currencyConversionValue: 1,
                        currencyWarning: `Currency conversion failed for ${result.prCurrency}: System will consider only value without currency conversion`
                    }
                });
            }

            else {
                dispatchMethod({
                    type: DISPATCH_TYPES.CURRENCY_CONVERSION_CHANGE,
                    payload: {
                        currencyConversionValue: Number(result.ToAmount?.trim()) / result.amount,
                        currencyWarning: ""
                    }
                });
            }

        })
            .catch((e) => { console.log(e) })


        // Remove scroll event handler on unmount.
        return () => {
            prEditor.removeEventListener('scroll', throttleScroll());
        };
    }, []);

    const scrollTo = (ele) => {
        !!ele && ele.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    useEffect(() => {
        const message = getResendPoPdfNotificationMessage(prFieldsModified, prNewItemAdded);
        const [itemLinesCopy, finalAllDocFlowItems, formData, filesUploaded]: [any[], any[], FormData, FormDataEntryValue[]] = checkIfAnyThingChanged();
        if ((finalAllDocFlowItems.length > 0 || filesUploaded.length > 0)) {
            setShowResendPoPdfButton('none')
            setResendPoNotificationMessage(message)
        } else {
            setShowResendPoPdfButton('block')
            setResendPoNotificationMessage('')
        }
    }, [hideResendPoButton])


    const resendPoClasses = useResendPoStyles({});

    return <>
        {sending ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '95vh' }}>
                <PopupLoading text={"Updating..."} />
            </div>
        ) : <div className='list-of-pr'>
            <VerticalView style={{
                position: 'sticky',
                top: 0,
                zIndex: 9999
            }}>
                <NewBannerWrapperForMultiEdit
                    title={`${disableEditPr ? 'View' : 'Edit'} PR: ${df.PurchaseReqNo.replace(/^0+/, '')}`}
                    onClose={backHandler}
                    titleStyle={{ color: '#5D5858', fontSize: 18, marginLeft: 0 }}
                ></NewBannerWrapperForMultiEdit>
            </VerticalView>

            <nav style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#F9F9F9',
                position: 'sticky',
                top: 56.9,
                zIndex: 9999,
                height: 60,
                alignItems: 'center',
            }}>
                <TabSelector
                    scrollTo={scrollTo}
                    headerRef={headerRef}
                    generalDetailsRef={generalDetailsRef}
                    itemDetailsRef={itemDetailsRef}
                    poSendingOptionsRef={poSendingOptionsRef}
                    attachmentsAndCommentsRef={attachmentsAndCommentsRef}
                    showPoSendingOptions={getCondtions(itemLines[0]).isItEasyRequestPR}
                />

                {
                    !disableEditPr &&
                    <CloseBtn
                        style={{
                            color: 'orange', boxShadow: 'none', cursor: 'pointer',
                            marginRight: 20,
                        }}
                        onClick={handleSubmit}
                        variant="contained" color="primary">Submit</CloseBtn>
                }


            </nav>
            {errorMsg ? <div style={{ color: 'red', display: 'flex', justifyContent: 'center', position: 'sticky', top: 115, zIndex: 9999, backgroundColor: 'white', paddingTop: 30, paddingBottom: 10 }}>{errorMsg}</div> : null}

            <div className={sectionDiv}
                id={`edit-request-mainpage`}
                style={{ overflow: 'auto', maxHeight: 'calc(100vh - 125px)' }}
            >
                {/* <GeneralDetails /> */}
                <section className={`pr-editor-scroll-target`} ref={generalDetailsRef} id={'generalDetails'}>
                    <div className={`${header} ${boldFont}`} style={{ marginTop: 30 }} >GENERAL DETAILS</div>
                    {
                        getUnDeletedItems(itemLines).length > 0 ? <GeneralDetails disbaleGeneralDetails={disableEditPr} setFinalTitle={setFinalTitle} finalTitle={finalTitle} setFinalResendPoData={setFinalResendPoData} finalResendPodata={finalResendPodata} docFlowHeader={docFlowHeader}
                            values={itemLines[0]}
                            dispatchMethod={dispatchMethod}
                            zone={props.zone}
                        /> : <div>No records to display</div>
                    }

                </section >

                {/* <ItemDetails /> */}
                <section className={`pr-editor-scroll-target`} ref={itemDetailsRef} id={'itemDetails'}>
                    <ItemDetails documentFlow={docFlowHeader} itemLines={getUnDeletedItems(itemLines)}
                        totalValDynamic={totalValDynamic}
                        dispatchMethod={dispatchMethod}
                        showCBbasedOnSupplierStatus={CBState.showCBbasedOnSupplierStatus}
                        accountDetailsData={accountDetailsData}
                        accountDetailsLineNumbers={accountDetailsLineNumbers}
                        accDetailsEditedLines={accDetailsEditedLines}
                        accDetailsLoading={accDetailsLoading}
                        setFinalResendPoData={setFinalResendPoData}
                        finalResendPodata={finalResendPodata}
                        disableDetails={disableEditPr}
                        pricingCondionsData={pricingConditionData}
                        vertexData={vertexData}
                    ></ItemDetails>
                </section >

                {
                    hasFeature(Features.RC_163611_RESEND_PO) && getCondtions(itemLines[0]).isItEasyRequestPR &&
                    <section className={`pr-editor-scroll-target`} ref={poSendingOptionsRef} id={'poSendingOptions'}>
                        <div className={`${header} ${boldFont}`}>PO SENDING OPTIONS</div>
                        <ResendPoPdfReceipnts disablePoPdfOptions={disableEditPr} documentData={df} documnetRow={itemLines[0]} finalTitle={finalTitle} setFinalResendPoData={setFinalResendPoData} showResendPoPdfButton={showResendPoPdfButton} handleResendPoSubmit={handleResendPoSubmit} disableResendPoPdfSection={disableResendPoPdfSection} resendPoNotificationMessage={resendPoNotificationMessage} msgWidth={(prFieldsModified && prNewItemAdded) ? '46%' : '34%'} />
                    </section>
                }

                <div>
                    <section className={`pr-editor-scroll-target`} ref={attachmentsAndCommentsRef} id={'attachmentsAndComments'}>
                        <div className={`${header} ${boldFont}`}>ATTACHMENTS & COMMENTS</div>
                        {
                            getUnDeletedItems(itemLines).length > 0 ? <AttachmentsAndComments
                                // values={itemLines[0]}
                                itemLines={getUnDeletedItems(itemLines)}
                                invoices={invoices}
                                dispatchMethod={dispatchMethod}
                                attachmentAndCommentsState={attachmentAndCommentsState}
                                totalValDynamicInThresholdCurr={totalValDynamicInThresholdCurr}
                                totalValDynamic={totalValDynamic}
                                disableDetails={disableEditPr}
                            /> :
                                <div>No records to display</div>
                        }

                    </section>
                </div>

                {
                    getUnDeletedItems(itemLines).length > 0 && itemLines[0].PoNo && (
                        <div className={disableEditPr ? resendPoClasses.disableDiv : resendPoClasses.enableDiv}>
                            {hasFeature(Features.RC_164276_REMOVE_CHANGE_REQUEST) && !getCondtions(itemLines[0]).isItEasyRequestPR &&
                                <>
                                    <div style={{ margin: "50px 0 10px 0", fontSize: 14, color: '#5d5858', fontWeight: 500 }}>
                                        Notify Change Request To:
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap", }}>
                                        {
                                            itemLines[0].PoCreator !== "JOBRUN" && !!!itemLines[0].ExtReqNo &&
                                            <EditAvatarContainer
                                                values={itemLines[0]}
                                                df={df}
                                                label1="PO Creator"
                                                email={itemLines[0].POCreatoremail}
                                            ></EditAvatarContainer>
                                        }
                                        {genericEmails.length > 0 &&
                                            genericEmails.map(el => el && <EditAvatarContainer
                                                key={el}
                                                el={el}
                                                label1="Generic Emails"
                                                email={el}
                                            ></EditAvatarContainer>)}
                                    </div>
                                </>
                            }
                            {!hasFeature(Features.RC_164276_REMOVE_CHANGE_REQUEST) &&
                                <>
                                    <div style={{ margin: "50px 0 10px 0", fontSize: 14, color: '#5d5858', fontWeight: 500 }}>
                                        Notify Change Request To:
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap", }}>
                                        {
                                            itemLines[0].PoCreator !== "JOBRUN" && !!!itemLines[0].ExtReqNo &&
                                            <EditAvatarContainer
                                                values={itemLines[0]}
                                                df={df}
                                                label1="PO Creator"
                                                email={itemLines[0].POCreatoremail}
                                            ></EditAvatarContainer>
                                        }
                                        {genericEmails.length > 0 &&
                                            genericEmails.map(el => el && <EditAvatarContainer
                                                key={el}
                                                el={el}
                                                label1="Generic Emails"
                                                email={el}
                                            ></EditAvatarContainer>)}
                                    </div>
                                </>
                            }
                        </div>
                    )
                }
            </div>
            {showBackButtonPopUp && <EditRequestBackButton onSaveClick={onSaveClick} shouldOpen={showBackButtonPopUp}
                setShowBackButtonPopUp={setShowBackButtonPopUp}
            />}
        </div>}
    </>
}

// AttachmentsAndComments

import React from 'react';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { AttachmentSection } from './AttachmentSection';
import { newEditRequestPageStyles } from '../newEditRequest/newEditRequestPageStyles';

interface AttachmentsAndCommentsSectionProps {
    itemLines : DocFlowItem[];
    invoices: any    
    attachmentAndCommentsState: any
    dispatchMethod: Function
    totalValDynamicInThresholdCurr: number
    totalValDynamic: number
    disableDetails?: boolean
}

export default function AttachmentsAndCommentsSection ({
    itemLines, 
    invoices,
    attachmentAndCommentsState,
    dispatchMethod,
    totalValDynamicInThresholdCurr,
    totalValDynamic,
    disableDetails=false
}: AttachmentsAndCommentsSectionProps) {        

    const styles = newEditRequestPageStyles({});

    return (
        <div>    
            {attachmentAndCommentsState.CBState.showCompetitiveBidding &&             
            <AttachmentSection 
                key={"Competitive"}     
                itemLines = {itemLines}         
                sectionHeading={"Competitive Bidding justification"}
                isItCompetitiveBidding={true}            
                dispatchMethod = {dispatchMethod}
                attachmentAndCommentsState = {attachmentAndCommentsState}
                totalValDynamicInThresholdCurr = {totalValDynamicInThresholdCurr}
                totalValDynamic = {totalValDynamic}
                disableDetails={disableDetails}
            />

            }     
            
            <AttachmentSection key={"Optional Documents"} 
                // values= {values}
                itemLines = {itemLines}
                sectionHeading={"Optional Documents (e.g. Project briefs, Technical Drawings,etc.)"}
                isItCompetitiveBidding={false} 
                invoices={invoices}     
                dispatchMethod = {dispatchMethod}  
                attachmentAndCommentsState = {attachmentAndCommentsState}
                totalValDynamic = {totalValDynamic}
                disableDetails={disableDetails}

/>
</div >
    );
}
import React from "react";
import { PRStyledCell } from "src/app/purchaseRequisition/PR.styles";
import MaterialTable, { Column } from "material-table";
import { TableLoadingComponent } from "src/app/shared/components/loading";
import { useTableStyles } from "../../../../dataTable/docFlow/docFlowTable.styles";
import { ItemTableCellComponent } from "../../../itemTableCell.component";
import { Button, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { isNil } from "ramda";
import { CustomFastAsyncSelect, CustomFastInput, CustomFastSelect, CustomFastStyledAsyncSelect } from "src/app/shared/components/form";
import { PricingConditions } from "src/app/docFlows/domains/documentFlow/pricingConditions";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { newEditRequestPageStyles } from "./newEditRequestPageStyles";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { SelectContainer } from "src/app/shared/components/filters/filters.styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50"
        },
        secondary: {
            main: "#ff9100"
        }
    }
});

type pricingConditionsTableProps = {
    totalCount: number;
    data: PricingConditions[];
    conditionTypesData: any;
    currencySet: any;
    addPriceConditionsItem: any;
    documentFlow: DocumentFlow;
    handleChange: any;
    handleDelete: any;
    selectedItem: DocFlowItem;
};

export const PricingConditionsTable = (props: pricingConditionsTableProps) => {
    const { docFlowTableClass, removeBorderForCheckBox } = useTableStyles({});
    const styles = newEditRequestPageStyles({});

    // below is the mapping of calculationType

    // A = PERCENTAGE
    // B = AMOUNT
    // C = QUANTITY

    const materialFromPslColumns = (): Column<any>[] => {
        const columns = [
            {
                title: "Condition Type", field: "condType", sorting: false, cellStyle: { width: "25%" }, render: (item: PricingConditions, i) => {
                    return <CustomFastSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderRadius: 0, borderBottom: '1px solid', marginBottom: 7 })
                        }}
                        menuPortalTarget={document.body}
                        disabled={item["isItInitialItem"]}
                        isMulti={false}
                        clearable={false}
                        customDisplay={option => option.label ? `${option.value} - ${option.label}` : option.value}
                        initialValue={item.condType ? { value: item.condType, label: item.condTypeDesc } : null}
                        options={props.conditionTypesData}
                        onChange={(v) => props.handleChange({ condType: v?.value, condTypeDesc: v?.label, calculationType: v?.calculationType }, item?.condItemCounter)}
                    />
                }
            },
            {
                title: "Condition Percentage", field: "condRate", cellStyle: { width: "5%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <CustomFastInput
                        type={"number"}
                        disabled={item?.calculationType !== "A"}
                        initialValue={item?.condRate}
                        onChange={(v) => props.handleChange({ condRate: v }, item?.condItemCounter)}
                    />
                )
            },
            {
                title: "Condition Amount", field: "condRate", cellStyle: { width: "10%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <CustomFastInput
                        type={"number"}
                        disabled={item?.calculationType === "A"}
                        initialValue={item?.condRate}
                        onChange={(v) => props.handleChange({ condRate: v }, item?.condItemCounter)}
                    />
                )
            },
            {
                title: "Condition Per Quantity", field: "pricingUnit", cellStyle: { width: "5%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <CustomFastInput
                        type={"number"}
                        disabled={item?.calculationType !== "C"}
                        initialValue={item?.pricingUnit}
                        onChange={(v) => props.handleChange({ pricingUnit: v }, item?.condItemCounter)}
                    />
                )
            },
            {
                title: "Condition Unit", field: "conditionUnit", cellStyle: { width: "5%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <CustomFastInput
                        type={"text"}
                        initialValue={item?.conditionUnit}
                        onChange={(v) => props.handleChange({ conditionUnit: v }, item?.condItemCounter)}
                    />
                )
            },
            {
                title: "Condition Currency", field: "baseCurr", cellStyle: { width: "10%" }, sorting: false, render: (item: PricingConditions, i) => {
                    return <CustomFastSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderRadius: 0, borderBottom: '1px solid', marginBottom: 7 })
                        }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        clearable={false}
                        customDisplay={option => option.value}
                        initialValue={item.baseCurr ? { value: item.baseCurr } : null}
                        options={props.currencySet ? props.currencySet : []}
                        onChange={(v) => props.handleChange({ baseCurr: v?.value }, item?.condItemCounter)}
                    />
                }
            },
            {
                title: "Condition Supplier / Vendor", field: "vendorNo", cellStyle: { width: "25%" }, sorting: false, render: (item: PricingConditions, i) => {
                    return <SelectContainer key={`${item.prNo}${i}`} style={{ width: '300px' }}>
                        <CustomFastAsyncSelect
                            menuPortalTarget={document.body}
                            onLoadLimit={3}
                            onLoad={(x: string) => MasterService.fetchSuppliersFromAzure(x, props.documentFlow.POCompanyCode, true)}
                            customDisplay={(option) => `${option.id} - ${option.name}`}
                            initialValue={item.vendorNo ? { id: item.vendorNo, name: item.VendorName } : null}
                            onChange={(v) => props.handleChange({ vendorNo: v.id, VendorName: v.name }, item?.condItemCounter)}
                        />
                    </SelectContainer>
                }
            },
            {
                title: "Condition Total Value", field: "condAmtInBaseCurr", cellStyle: { width: "10%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <div>
                        {item?.condAmtInBaseCurr ? `${item?.condAmtInBaseCurr} ${item.baseCurr}` : ""}
                    </div>
                )
            },
            {
                title: "", field: "TotalconditionAmt", cellStyle: { width: "5%" }, sorting: false, render: (item: PricingConditions, i) => (
                    <div style={{ color: "orange" }} onClick={() => props.handleDelete(item["isItInitialItem"], item.condItemCounter)}>
                        <DeleteForeverIcon />
                    </div>
                )
            }
        ]
        return columns
    };

    const theColumns = materialFromPslColumns();
    const columnsNotNull = theColumns.filter(el => !isNil(el));

    return <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgb(217, 217, 217)", margin: "20px", minWidth: "1000px" }} className={`${docFlowTableClass} ${removeBorderForCheckBox}`}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{
                    boxShadow: "none",
                    zIndex: 999,
                }}
                columns={columnsNotNull}
                data={props.data}
                components={{
                    OverlayLoading: () => <TableLoadingComponent />,
                    Cell: props => <PRStyledCell {...props} />
                }}
                options={{
                    paging: false,
                    toolbar: false,
                    rowStyle: {
                        // borderBottom: "hidden", // this is to ensure no border is seen under checkbox
                        padding: "10px 5px",
                        fontSize: ".8rem", color: "#7c7777",
                        whiteSpace: "nowrap",
                        height: "60px",
                        borderBottom: "1px solid #E0E0E0"
                    },
                    headerStyle: {
                        fontSize: ".8rem", fontWeight: "bold",
                        height: "60px",
                        padding: "10px 5px",
                        whiteSpace: "nowrap",
                        color: "#fff",
                        backgroundColor: "orange",
                        borderBottom: 'none'
                    },
                }}
            />
        </MuiThemeProvider>
        <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
            <Button
                className={styles.deleteButton}
                onClick={props.addPriceConditionsItem}
                style={{
                    paddingLeft: 14, paddingRight: 14,
                    color: "#F19001",
                    cursor: "pointer",
                    borderRadius: "50px",
                    width: "fit-content"
                }}>
                <AddCircleOutlineIcon />
                <div style={{ marginLeft: "5px" }} >Add New</div>
            </Button>
        </div>
    </div>
}
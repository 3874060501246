import * as React from "react";
import { assoc, isNil } from 'ramda';
import MaterialTable, { Column } from "material-table";
import { TableContainer } from "../../../shared/components/layout/styles";
import { getFormStatus, getScope, ISupplier, PSL, PslStatus, SupplierStatus } from "../../domains/psl";
import TablePagination from "@material-ui/core/TablePagination";
import { User } from "../../../shared/domains/user/user";
import { OrderDirection } from "../../../../lib/odata";
import { useTranslation } from "react-i18next";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router";
import Chips, { DiversityChip } from "../../../shared/components/filters/chips";
import Chip from "@material-ui/core/Chip";
import { useTableStyles } from "./pslTable.styles";
import { DateTime } from "luxon";
import { FileName } from "../../../competitiveBidding/containers/cb.styles";
import { DownloadItem, UploadItemTypes } from "../../../shared/actions/upload.actions";
import { useDispatch } from "react-redux";
import { ILanguage } from "../../../shared/domains/master/master.service";
import { MaterialColumns } from "../../../shared/domains/user/materialColumns";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import { FeaturesContext } from "../../../shared/domains/core/pataflag.context";
import { Features } from "../../../shared/domains/core/pataflag";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification, { NotificationType } from "../../../shared/components/notification";
import { useAddMaterial } from "../../domains/easyRequest.hooks";
import { IMaterial, PslMaterial, WaysOfBuyingText } from "../../domains/pslMaterial";
import { DocumentFlowService } from "../../../docFlows/domains/documentFlow/documentFlow.service";
import { UserInteractionType } from "../../../shared/domains/user/userInteractionType";
import styled from "styled-components";
import StarRounded from "@material-ui/icons/StarRounded";
import InfoIcon from '@material-ui/icons/Info';
import {PSLTypes, UpdateFavourite} from '../../actions/psl.actions';
import { getAllIndexes } from "src/app/shared/utils/arraysHelper";
import { Link, Tooltip } from "@material-ui/core";
import { PslVendorInfo } from "src/app/shared/components/modal/pslVendorInfo";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { LocationInfo, LocationType } from "../../containers/pslForm/component/LocationInfo";

interface MaterialTableProps {
  showBorder: boolean;
  loading: boolean;
  data: IMaterial[];
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onNewPage: (page: number) => void;
  onNewPageSize: (pageSize: number) => void;
  onNewOrder: (field: string, direction: OrderDirection) => void;
  user: User;
  userColumns: { id: MaterialColumns, show: boolean, hidden: boolean }[],
  languages: ILanguage[],
  showMore?: boolean
  onDelete: (materialId: number) => void;
  onReactivate: (pslId: number) => void;
  paginationOptions: number[]
}

export const StyledIcon = styled(StarRounded)`
  color: #F7A122;
  cursor: pointer;
`;

export const FavIcon = styled(StyledIcon)`
  color: grey;
`;

export function GetMaterialTableColumns(columns: { id: MaterialColumns, show: boolean, hidden: boolean }[], format: any, isViewOnly: boolean,
  t: any, languages: ILanguage[], pslExpiryDays: number, setSelectedVendor?: (vendorId: string | number)=>void, 
  setIsModalOpen?: (isOpen: boolean) => void,
  pageSize?: any, onNewPageSize?: (e: any) => void, paginationOptions?: any[], userColumns?: any[], showMore?: boolean,
  isViewOnlyUser?: boolean, isLoading?: boolean, selectedMaterialId?: any, setSelectedMaterialId?: (e: any) => void, addMaterial?: (e: any)=>void,
  isAtMarket?: boolean, setSelectedMaterial?: (e: any) => void, setAnchorEl?: (e: any) => void): Column<any>[] {
  const cellStyle = { padding: '15px 0 15px 15px' };
  const styledColumn = (column: Column<any>) => assoc('cellStyle', cellStyle, column);
  const { hasFeature } = React.useContext(FeaturesContext);
  const { statusChipClass, moreClass } = useTableStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  const toolTipclass = useStylesForToolTip({});
  const useStyles = makeStyles(() =>
    createStyles({
      hidden: {
        display: "none"
      },
      orange: {
        color: "#F19001"
      }
    })
  )
  const classes = useStyles({});
  const downloadPslAttachment = (name: string) => {
    name?.startsWith("http") ?
      window.open(name, '_blank')
      : dispatch(new DownloadItem(UploadItemTypes.PLS_Vendor_Sourcing, name));
  }
  const editwithDisabledPsl = async (currentPslId: number) => {
    localStorage.setItem("isDisabledForm", "true");
    history.push(`/psl/edit/${currentPslId}`);
  }

  const pageOptionsSelect = <Select disableUnderline={true}
    style={{ fontSize: "0.875rem" }}
    classes={{ icon: classes.orange, root: classes.orange }}
    value={pageSize}
    onChange={e => onNewPageSize(Number(e.target.value))}>
    {paginationOptions?.map(pageSize => (
      <MenuItem key={pageSize} value={pageSize}>
        {pageSize} lines
      </MenuItem>
    ))}
  </Select>

  return columns.map((el) => {
    switch (el.id) {
      case MaterialColumns.PARENT_VENDOR:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'supplierCode',
          render: (material: IMaterial) => {
            if (material?.childSupplierCode && material?.scope == 1) {
              return <span>{material?.childSupplierCode} - {material?.childSupplierName}
                {hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
                  setSelectedVendor(material?.childSupplierCode);
                  setIsModalOpen(true)
                }} /></span>}
              </span>
            }
            if (material?.parentCode && material?.scope == 0) {
              return <span>{material?.parentCode} - {material?.parentName}
                {hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
                  setSelectedVendor(material?.parentCode);
                  setIsModalOpen(true)
                }} /></span>}
              </span>
            }
            return ''
          }
        });
      case MaterialColumns.LOCAL_CHILD_VENDOR:
      case MaterialColumns.CHILD_VENDOR:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'childSupplierCode',
          render: (material: IMaterial) => material?.childSupplierCode && material?.scope != 1 ? <span style={{ textDecoration: 'underline' }}>{material?.childSupplierCode} - {material?.childSupplierName}
            {setSelectedVendor ? hasFeature(Features.PSL_166244_FEATURE_VENDORINFO) && <span style={{ verticalAlign: 'middle', paddingLeft: '5px', cursor: 'pointer' }}><InfoIcon fontSize="small" onClick={() => {
              setSelectedVendor(material.childSupplierCode);
              setIsModalOpen(true)
            }} /></span> : <></>}
          </span> : null
        });
      case MaterialColumns.PLANT:
        return hasFeature(Features.PSL_124675_Plants) ? styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (material: IMaterial) => <span>{material?.plants?.map((el, i) => <div key={i}>{el.code} - {el.name}</div>)}</span>, hidden: !el.show || el.hidden
        }) : null;
      case MaterialColumns.KEY_WORDS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden,
          render: (material: IMaterial) => material?.keyWords?.length ? <div style={{ maxWidth: '240px' }}><Chips source={material?.keyWords.map(el => ({ title: el, id: el }))} minimumItems={1000} onDelete={() => { }} /></div> : null
        });
      case MaterialColumns.PSL_ID:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'pslId', hidden: !el.show || el.hidden,
          render: (material: IMaterial) => <Link underline="hover" color="inherit"
           onClick={()=> editwithDisabledPsl(material.pslId)}
           >
          {material.pslIdWithPrefix || material.pslId}
        </Link>
        });
      case MaterialColumns.PART_ID:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'supplierPartId', hidden: !el.show || el.hidden });
      case MaterialColumns.SHORT_DESCRIPTION:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'shortDescription', hidden: !el.show || el.hidden });
      case MaterialColumns.ITEM_DESCRIPTION:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'itemDescription', hidden: !el.show || el.hidden });
      case MaterialColumns.UNIT_OF_MEASURE:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'unitOfMeasure', hidden: !el.show || el.hidden });
      case MaterialColumns.UNIT_PRICE:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'unitPrice', hidden: !el.show || el.hidden });
      case MaterialColumns.PRICE_PER:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'pricePer', hidden: !el.show || el.hidden });
      case MaterialColumns.CURRENCY:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'currencyCode', hidden: !el.show || el.hidden });
      case MaterialColumns.MATERIAL_NUMBER:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'materialNumber',
          render: (mat: IMaterial) => <div>{mat.materialNumber} - {mat.materialName}</div>
        });
      case MaterialColumns.SPEND_CATEGORY_L1:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'SpendCat1Id', hidden: !el.show || el.hidden,
          render: (mat: IMaterial) => <div>{mat.spendCat1DisplayValue}</div>
        });
      case MaterialColumns.SPEND_CATEGORY_L2:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'SpendCat2Id', hidden: !el.show || el.hidden,
          render: (mat: IMaterial) => <div>{mat.spendCat2DisplayValue}</div>
        });
      case MaterialColumns.SPEND_CATEGORY_L3:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'SpendCat3Id', hidden: !el.show || el.hidden,
          render: (mat: IMaterial) => <div>{mat.spendCat3DisplayValue}</div>
        });
      case MaterialColumns.LANGUAGE:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'languageId',
          render: (material: IMaterial) => <div>{(languages ?? []).find(el => el.id === material.languageId)?.name ?? material.languageTag}</div>
        });
      case MaterialColumns.SUPPLIER_LEAD_TIME:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'supplierLeadTime', hidden: !el.show || el.hidden });
      case MaterialColumns.REPLICATION_STATUS:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: '', hidden: !el.show || el.hidden });
      case MaterialColumns.SUPPLIER_CONTACT:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'pslVendorContactName', hidden: !el.show || el.hidden });
      case MaterialColumns.SUPPLIER_STATUS:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'statusId',
          render: (material: IMaterial) => material?.statusId !== SupplierStatus.BLANK ?
            <Chip label={Object.keys(SupplierStatus).find(key => SupplierStatus[key] === material?.statusId)?.replace('_', ' ')} className={statusChipClass} /> : null
        });
      case MaterialColumns.BUSINESS_UNIT:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden,
          render: (material: IMaterial) => <div style={{ maxWidth: '240px' }}><Chips source={material?.businessUnits?.map(el => ({ title: el.name, id: el.id }))}
            minimumItems={1000} onDelete={() => { }} /></div>
        });
      case MaterialColumns.VALID_FROM:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'validFrom',
          render: (material: IMaterial) => <span>{material?.validFrom ? DateTime.fromJSDate(new Date(material.validFrom)).toFormat("dd/MM/yyyy") : ""}</span>
        });
      case MaterialColumns.VALID_TO:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'validTo',
          render: (material: IMaterial) => <span>{material?.validTo ? DateTime.fromJSDate(new Date(material.validTo)).toFormat("dd/MM/yyyy") : ""}</span>
        });
      case MaterialColumns.ZONE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (material: IMaterial) => <span>{material?.zones?.map((el, i) => <div key={i}>{el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.MARKET:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (material: IMaterial) => <span>{material?.markets?.map((el, i) => <div key={i}>{el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.COMPANY_CODE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (material: IMaterial) => <span>{material?.companyCodes?.map((el, i) => <div key={i}>{el.code} - {el.name}</div>)}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.AGREEMENTS:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'agreements', hidden: !el.show || el.hidden });
      case MaterialColumns.BUYER_NAME:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'strategicBuyerEmailAddress', hidden: !el.show || el.hidden,
          render: (material: IMaterial) => <span>{(isViewOnly && material.hideBuyer) ? "" : material.strategicBuyerEmailAddress}</span>
        });
      case MaterialColumns.Market_Contact_Name:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'marketContactName', hidden: !el.show || el.hidden
        });
      case MaterialColumns.TEAM_NAME:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'teamName', hidden: !el.show || el.hidden });
      case MaterialColumns.NOTE_REQUESTER:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'noteRequester', hidden: !el.show || el.hidden });
      case MaterialColumns.NOTE_LOCAL_PROCUREMENT:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'noteLocalProcurement', hidden: !el.show || el.hidden });
      case MaterialColumns.MANUFACTURER_PART_NUMBER:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'manufacturerPartNumber', hidden: !el.show || el.hidden });
      case MaterialColumns.MANUFACTURER_NAME:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'manufacturerName', hidden: !el.show || el.hidden });
      case MaterialColumns.TENDER_NUMBER:
        return styledColumn({ sorting: true, title: t(`psl_columns:${el.id}`), field: 'tenderNumber', hidden: !el.show || el.hidden });
      case MaterialColumns.PRICE_LIST_LINK:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'priceList',
          render: (material: IMaterial) => {
            const isUrl = material?.priceList?.startsWith('http')
            return <span
              style={{
                cursor: isUrl ? 'pointer' : 'default',
                color: isUrl ? '#2689EB' : ''
              }}
              onClick={() => {
                isUrl ? window.open(material.priceList) : ''
              }}

            >{material?.priceList}</span>
          },
          hidden: !el.show || el.hidden
        });
      case MaterialColumns.STATUS_JUSTIFICATION:
        return styledColumn({ sorting: false, title: t(`psl_columns:${el.id}`), field: 'statusJustification', hidden: !el.show || el.hidden });
      case MaterialColumns.CREATED_ON:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'createdOn',
          render: (material: IMaterial) => <span>{material?.createdOn ? DateTime.fromJSDate(new Date(material?.createdOn)).toFormat("dd/MM/yyyy") : ""}</span>,
          hidden: !el.show || el.hidden
        });
      case MaterialColumns.CREATED_BY_USER:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'createdByUserEmail',
          render: (material: IMaterial) => <span>{material?.createdByUser?.email}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.UPDATED_ON:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'modifiedOn',
          render: (material: IMaterial) => <span>{material?.modifiedOn ? DateTime.fromJSDate(new Date(material?.modifiedOn)).toFormat("dd/MM/yyyy") : ""}</span>,
          hidden: !el.show || el.hidden
        });
      case MaterialColumns.UPDATED_BY_USER:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), field: 'modifiedByUserEmail',
          render: (material: IMaterial) => <span>{material?.modifiedByUser?.email}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.PRICE_AVAILABLE:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          render: (material: IMaterial) => <span>{material?.priceList || material?.unitPrice ? 'Yes' : 'No'}</span>, hidden: !el.show || el.hidden
        });
      case MaterialColumns.RELATED_TO_EASYBUY:
        return styledColumn({
          sorting: true, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'relatedToEasyBuy',
          render: (material: IMaterial) => <span>{material?.relatedToEasyBuy ? 'YES' : 'NO'}</span>
        });
      case MaterialColumns.STATUS_ATTACHMENTS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden,
          render: (material: IMaterial) => <span style={{ display: 'flex', flexDirection: 'column' }}>
            {material?.statusAttachments?.map((el, i) => <FileName key={i} onClick={() => downloadPslAttachment(el)}>{el}</FileName>)}
          </span>
        });
      case MaterialColumns.FORM_STATUS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), hidden: !el.show || el.hidden, field: 'formStatus',
          render: (psl: IMaterial) => {
            const status = getFormStatus(psl, pslExpiryDays);
            switch (status) {
              case 'Pending Localization-VFT Maintenance':
                return <Tooltip placement={"right"} title={'There is no linkage existing between Parent & Child Vendor for the Market in Vendor Family Tree ( VFT) in Master Data'}><div>{status}</div></Tooltip>
              default:
                return <span>{status}</span>
            }
          }
        });
      case MaterialColumns.SCOPE:
        const findScopeLocalChild = (pslIdWithPrefix) => {
          if (pslIdWithPrefix.charAt(0) === "L") {
            return "- Local";
          } else if (pslIdWithPrefix.charAt(0) === "C") {
            return "- Child";
          } else {
            return "";
          }
        }
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'scope', hidden: !el.show || el.hidden,
          render: (psl: ISupplier) => <span>{getScope(psl?.scope)}{hasFeature(Features.PSL_163225_COLUMN_STATUS) && findScopeLocalChild(psl.pslIdWithPrefix)}</span>
        });
      case MaterialColumns.APPROVERS:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'approvers', hidden: !el.show || el.hidden,
          render: (material: IMaterial) => (material.approvers?.map((el) => (<div>{el}</div>)))
        });
      case MaterialColumns.Supplier_Diversity:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'diversity', hidden: !el.show || el.hidden,
          // render: (material: IMaterial) => <DiversityChip diversity= {[{ code: "Currently Testing it only", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: true }, { code: "WBE", text: "Currently Testing it only", defined: false }]}></DiversityChip>
          render: (material: IMaterial) => material?.diversity?.length ? <DiversityChip diversity={material.diversity}></DiversityChip> : null
        });
      case MaterialColumns.Way_Of_Buying:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'waysOfBuying', hidden: !el.show || el.hidden,
          render: (material: IMaterial) => material.scope === 0 ? (material.waysOfBuying?.map((el) => (<div>{WaysOfBuyingText[el]}</div>))) : null
        });
      case MaterialColumns.Localizer:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`), field: 'localizer', hidden: !el.show || el.hidden,
          render: (material: IMaterial) => (material.localizer?.map((el) => (<div>{el}</div>)))
        });
      case MaterialColumns.LOCATION:
        return styledColumn({
          sorting: false, title: t(`psl_columns:${el.id}`),
          hidden: !el.show || el.hidden,
          render: (material: IMaterial) => {

            let locationCollections = {
              zones: material?.zones ? material.zones : [],
              markets: material?.markets ? material.markets : [],
              companyCodes: material?.companyCodes ? material.companyCodes : [],
            }

            return (
              <span style={{ color: '#F19001', display: 'block' }}>
                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.ZONE} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{material?.zones?.length > 0 && `Zone - ${material.zones.length}`}</div>
                </Tooltip>

                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.MARKET} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{material?.markets?.length > 0 && `Market - ${material.markets.length}`}</div>
                </Tooltip>

                <Tooltip
                  interactive
                  classes={{ tooltip: toolTipclass.customTooltipForVendorHover }}
                  title={
                    <LocationInfo locationCollections={locationCollections} showInfo={LocationType.CODE} />
                  }
                  placement="right-end"
                >
                  <div style={{width: '100px'}}>{material?.companyCodes?.length > 0 && `Code - ${material.companyCodes.length}`}</div>
                </Tooltip>
              </span>
            )
          }
        });
      case MaterialColumns.REFRENCE_NUMBER:
        if(hasFeature(Features.PSL_166920_SHOW_REF_NUMBER)){
          return styledColumn({
            sorting: true, 
            title: t(`psl_columns:${el.id}`), 
            field: 'referenceId', 
            hidden: !el.show || el.hidden,
            render: (material: IMaterial) => <span style={{whiteSpace: 'nowrap'}}>{material.referenceId}</span>
          });
        }else{
          return null
        }
      case MaterialColumns.MATERIAL_ID:
        if(hasFeature(Features.PSL_168065_SHOW_MATERIAL_ID)){
          return styledColumn({
            sorting: true, 
            title: t(`psl_columns:${el.id}`), 
            field: 'materialId', 
            hidden: !el.show || el.hidden,
            render: (material: IMaterial) => <span style={{whiteSpace: 'nowrap'}}>{material.materialId}</span>
          });
        }else{
          return null
        }
      case MaterialColumns.ADD_TO_EASY_REQUEST:
        return styledColumn({
          sorting: false, title: pageOptionsSelect, field: '', render: (material: IMaterial) => {
            return <div className={moreClass}>
              {(!isViewOnlyUser
                && userColumns.filter(col => col.id == MaterialColumns.ADD_TO_EASY_REQUEST).pop()?.show
                && PslMaterial.build(material).isActive()
                && PslMaterial.build(material).isAtMarket()
              )
                && (
                  (isLoading && material.materialId == selectedMaterialId) &&
                  <div style={{ width: 70 }}><CircularProgress /></div>
                  || <div style={{
                    height: '32px',
                    width: '108px',
                    border: '2px solid #F19001',
                    borderRadius: '15px',
                    textAlign: 'center',
                    paddingTop: '4px',
                    fontSize: '16px',
                    fontWeight: 500,
                    cursor: 'pointer',
                    color: '#F19001'
                  }}
                    onClick={() => {
                      setSelectedMaterialId(material.materialId)
                      addMaterial(material.materialId)
                    }}
                  >Add to Cart</div>
                )}
              {isAtMarket && showMore && !isViewOnlyUser &&
                <div onClick={(event) => {
                  setSelectedMaterial(material);
                  setAnchorEl(event.currentTarget);
                }}>
                  {
                    hasFeature(Features.PSL_167588_HIDE_MORE_OPTIONS_FOR_REFNUMBER) ?
                      material?.referenceId ? null : <MoreVertIcon />
                      :
                      <MoreVertIcon />
                  }
                </div>
              }
            </div>
          }
        });
      default:
        return null;
    }
  }).filter(el => !isNil(el));
}

export function PslMaterialTable(props: MaterialTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { hasFeature } = React.useContext(FeaturesContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMaterial, setSelectedMaterial] = React.useState<IMaterial>(null);
  const [selectedMaterialId, setSelectedMaterialId] = React.useState<number>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = React.useState<string | number>('');
  const isRequestor = props.user.isRequestor()
  const isAtMarket = props.user.isAtMarket()
  const isViewOnlyUser = props.user.isViewOnly();
  const format = props.user.profileSettings.dateTimeFormat;
  const handleClose = () => setAnchorEl(null);
  const editMaterial = () => {
    localStorage.setItem("isDisabledForm", "false");

    handleClose();
    history.push(`/psl/edit/${selectedMaterial.pslId}`);
  }
  const deleteMaterialPsl = () => {
    handleClose()
    props.onDelete(selectedMaterial.materialId)
  }
  const reactivatePsl = () => {
    handleClose()
    props.onReactivate(selectedMaterial.pslId)
  }
  const historyPsl = async () => {
    handleClose();
    await DocumentFlowService.logUserInteractionData(UserInteractionType.ViewPslHistory);
    history.push('/psl/history/' + selectedMaterial.pslId + '/material/' + selectedMaterial.materialId);
  }
  const { mutate: addMaterial, isLoading, error, isSuccess, isError, data } = useAddMaterial()
  const { pslTableClass, moreClass } = useTableStyles({});
  const dispatch = useDispatch();
  const cellStyle = { padding: '15px 0 15px 15px' };
  const columns: Column<any>[] = GetMaterialTableColumns(props.userColumns, format, isRequestor, 
    t, props.languages, 
    props.user.pslExpiryDays, setSelectedVendor, setIsModalOpen,
    props.pageSize, props.onNewPageSize, props.paginationOptions, props.userColumns, props.showMore,
    isViewOnlyUser, isLoading, selectedMaterialId,
    setSelectedMaterialId, addMaterial, isAtMarket, setSelectedMaterial, setAnchorEl);
  const useStyles = makeStyles(() =>
    createStyles({
      hidden: {
        display: "none"
      },
      orange: {
        color: "#F19001"
      }
    })
  )
  const classes = useStyles({});
  

  const removedNulls = columns.filter(el => !isNil(el));

  const columnsWithMore = (): Column<any>[] => {
    var withMoreOption: Column<any>[] = removedNulls;

    if (hasFeature(Features.PSL_FAVORITE)) {
      withMoreOption.unshift({
        sorting: false, title: "", field: '', render: (material: IMaterial) => {
          return <React.Fragment>
            {material.isFavorite ? <StyledIcon onClick={() => {
              let index = getAllIndexes(props.data, "pslId", material.pslId);
              dispatch(new UpdateFavourite(false, index, material.pslId));
            }} /> :
              <FavIcon onClick={() => {
                let index = getAllIndexes(props.data, "pslId", material.pslId);
                dispatch(new UpdateFavourite(true, index, material.pslId));
              }} />}
          </React.Fragment>
        }
      });
    }

    return withMoreOption;
  }

  const isPslArchived = () => selectedMaterial && isPslArchived2(selectedMaterial)
  const isPslArchived2 = (material: IMaterial) => material && (material.materialOrVendorDeleted || material.validTo && DateTime.fromJSDate(new Date(material.validTo)).toISODate() < DateTime.local().toISODate());
  const isPendingChildPsl = selectedMaterial && new PSL(selectedMaterial).isChildPslWithAnyPendingChanges()
  return <>
    {isError && <Notification type={NotificationType.error} message={error.toString()} />}
    {isSuccess && <Notification type={NotificationType.success} message={`EZPR No:${data[0]?.prId}, Material added correctly`} />}
    <TableContainer className={pslTableClass} style={{marginTop: '20px'}}>
      <Menu
        key={"material-table"}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(props.onReactivate && isPslArchived()) && []
          || [
            <MenuItem key={"edit"} onClick={editMaterial}>Edit Form</MenuItem>,
            props.onDelete && !isPendingChildPsl && <MenuItem key={"delete"} onClick={deleteMaterialPsl}>Delete Line</MenuItem>
          ]}
      </Menu>
      <MaterialTable
        isLoading={props.loading}
        columns={columnsWithMore()}
        data={props.data}
        style={{
          borderSpacing: '10px',
          borderCollapse: 'separate',
          border: props.showBorder ? '1px solid #2689EB' : null
        }}
        components={{
          Pagination: (componentProps: any) => <TablePagination
            {...componentProps}
            rowsPerPageOptions={props.paginationOptions}
            rowsPerPage={props.pageSize}
            count={props.totalCount}
            page={props.currentPage}
            onChangePage={(evt: any, page: number) => {
              props.onNewPage(page);
            }}
            onChangeRowsPerPage={(event: any) => props.onNewPageSize(event.target.value)}
            classes={{ input: classes.hidden, caption: classes.hidden }}
          />
        }}
        options={{
          toolbar: false,
          draggable: false,
          pageSizeOptions: [],
          pageSize: props.data.length > 0 && props.data.length < props.pageSize ? props.data.length : props.pageSize,
          rowStyle: {
            backgroundColor: '#F9F9F9'
          },
          headerStyle: {
            padding: cellStyle.padding,
            whiteSpace: 'nowrap',
            fontWeight: 700,
            color: '#4A4A4A'
          }
        }}
        onOrderChange={(orderBy: number, orderDirection: ("asc" | "desc")) => {
          const column = columns[orderBy - 1]?.field;
          const order = orderDirection as OrderDirection;
          props.onNewOrder(column as string, order);
        }}
      >
      </MaterialTable>
      <PslVendorInfo isModalOpen={isModalOpen} vendorId={selectedVendor} setIsModalOpen={setIsModalOpen} />
    </TableContainer>
  </>
}
import React from "react";
import { CustomFastDate, CustomFastInput, CustomFastAsyncSelect, CustomFastSelect } from "../../../shared/components/form";
import { Avatar, createStyles, Input, makeStyles, TextField, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DateTime } from "luxon";
import { MasterService } from "../../../shared/domains/master/master.service";
import { isEmpty } from "ramda";
import { extractInitials } from "./AvatarWithPopover";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import { useFetchCountryRegion, useFetchCurrencyDecimals, useFetchTaxCode } from "src/app/shared/domains/master/master.hooks";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            fontSize: 14,
            fontStyle: "italic",
            marginBottom: 5
        },
        avatarLabel: {
            marginBottom: 5,
            marginTop: 10,
            fontWeight: "bold",
        },
        select: {
            marginBottom: "10px!important"
        },
        lastSelect: {
            marginBottom: "5em!important"
        }
    })
);

export const NewPrItem2 = ({ values, df, setValues, setEnableSave, dfItem, totalChangeFn, setErrorMsg, currencyConversionValue, fetchCurrencyConversion }) => {
    const { label, select } = useStyles({});
    const { hasFeature } = React.useContext(FeaturesContext);
    const { data: taxCodes } = useFetchTaxCode(dfItem.CompCode || df.POCompanyCode, dfItem.SystemAlias);
    const { data: currencyDecimals } = useFetchCurrencyDecimals(dfItem.SystemAlias);
    const { data: countryRegions } = useFetchCountryRegion();
    const [taxCodesData, setTaxCodesData] = React.useState([]);
    const [countryRegionsData, setCountryRegionsData] = React.useState([]);
    const isItFO: boolean = values.PoDocType === "FO"; // check US - 157625 for the conditions 
    const isItZSIM: boolean = values.MaterialType === "ZSIM";
    const isItEasyRequestPR = dfItem.ExtReqNo === "EASY_REQUEST_PR" || dfItem.ExtReqNo.slice(0, 3) === "EPR";
    const isTherePO: boolean = !isEmpty(values.PoNo);
    const isTherePR: boolean = !isEmpty(values.PrNo);
    const isThereGR: boolean = Number((values.GrQty || "0,00").replace(",", ".")) !== 0;
    const isThereIR: boolean = Number((values.IrAmount || "0,00").replace(",", ".")) !== 0;
    const isItMulti: boolean = values.PR_PROFIT?.trim() === "MULTI" ||
        values.CostCenter?.trim() === "MULTI" ||
        values.Pr_CostCenter?.trim() === "MULTI" ||
        values.Profit_Center?.trim() === "MULTI" ||
        values.WbsElementTxt?.trim() === "MULTI" ||
        values.Pr_WbsElementTxt?.trim() === "MULTI";
    const [decimal, setDecimal] = React.useState<number | null>(null);
    const [unitPrice, setUnitPrice] = React.useState(0);

    React.useEffect(() => {
        setUnitPrice(priceInitialValue(isItFO ? values.PrPriceRaw : values.PrNetPriceRaw));
    }, [values.PrPriceRaw, values.PrNetPriceRaw]);

    const applyUnitPriceChanges = (e) => {
        const tempUnitPrice = isItFO ? { PrPriceRaw: e.target.value } : { PrNetPriceRaw: e.target.value };
        applyChange(tempUnitPrice);
    }
    const convertToNum = (value) => {
        const tempValue = typeof (value) === 'string' ? Number(value.replace(/[\s]/g, '').replace(',', '.')) : Number(value);
        return tempValue;
    };

    React.useEffect(() => {
        const tempDecimal: { currency: string, decimal: string } = currencyDecimals?.filter(curr => curr.currency === values.PrCurrency)[0];
        const finalDecimal = tempDecimal?.decimal ? parseInt(tempDecimal?.decimal) : null;
        setDecimal(finalDecimal);
        console.log(finalDecimal);
    }, [currencyDecimals, values.PrCurrency]);

    React.useEffect(() => {
        const tempUnitPrice = isItFO ? { PrPriceRaw: priceInitialValue(values.PrPriceRaw).toString() } :
            { PrNetPriceRaw: priceInitialValue(values.PrNetPriceRaw).toString() };
        applyChange(tempUnitPrice)
    }, [decimal]);

    React.useEffect(() => {
        setTaxCodesData(taxCodes);
        setCountryRegionsData(countryRegions);
    }, [taxCodes, countryRegions]);

    const checkIfValid = (value) => {
        const tempvalue = convertToNum(value);
        if (isNaN(tempvalue) || tempvalue <= 0) { return true }
        return false
    };

    const applyChange = (vals: { [key: string]: any }) => {
        const newItem = {
            ...values,
            ...vals
        };

        setErrorMsg('')
        // below 3 fields user cant put in 0 or negative values
        const itemsToCheck = ["PrQuantityRaw", "PrPriceRaw", "PrNetPriceRaw", "PrNetPriceUnit"];

        itemsToCheck.forEach(item => {
            if (checkIfValid(newItem[item])) {
                newItem[item] = values[item];
            };
            if (convertToNum(newItem[item]) === convertToNum(dfItem[item])) {
                newItem[item] = dfItem[item];
            };
        }
        );
        const itemsToCheck2 = ["PrDeliveryDate", "Validity_Start", "Validity_End",];
        itemsToCheck2.forEach(item => {
            if (newItem[item] === "Invalid DateTime") {
                newItem[item] = dfItem[item]
            };
        }
        );
        setEnableSave(true);
        setValues(newItem);
    };

    React.useEffect(() => {
        console.log(JSON.stringify(values) === JSON.stringify(dfItem));

        if (JSON.stringify(values) === JSON.stringify(dfItem)) {
            setEnableSave(false);
        }
    }, [values]);

    const priceInitialValue = (value) => {
        let price = typeof (value) === 'string' ?
            Number(value.replace(/[\s]/g, '').replace(',', '.')) : value;
        return (decimal === null || decimal > 0) ? price : Number(price.toString().split(".")[0]);
    };

    const genericEmails = values.PoOutRecipient.split(",");

    const supplierComments = (item) => {
        return item ? item?.replace(/^,/, '')?.trim() : "";
    };

    const EditAvatarContainer = ({ el, df, values, label1, email }: { el?: string, df?: any, values?: any, label1: string, email?: string }) => {
        return (
            <div style={{ display: "flex", margin: "20px 20px 20px 0" }}>
                <Avatar style={{ fontSize: "1.1rem", width: "36px", height: "36px", border: "2px solid #F2F4F8" }}
                >
                    {el ? el.substring(0, 2) : extractInitials(df?.Creatorfnam, df?.Creatorlnam, "")}
                </Avatar>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                    <span style={{ fontSize: ".8rem", fontWeight: "bold", color: "#F19001" }}>{label1}</span>
                    {label1 !== "Generic Emails" && <span style={{ fontSize: ".7rem", fontWeight: "bold", color: "black", lineHeight: "1" }}>{`${df.Creatorfnam} ${df.Creatorlnam}`}</span>}
                    {<span style={{ fontSize: ".7rem" }}>{email.toLocaleLowerCase()}</span>}
                </div>
            </div>)
    }


    const applyCurrencyChanges = (value: any) => {
        if (value) {
            fetchCurrencyConversion(values.Threshold_currency, value.value)
            applyChange({ PrCurrency: value.value })
        }
        else { applyChange({ PrCurrency: null }) }
    };

    React.useEffect(() => {
        let lineItem = values.PrItem.replace(/^0+/, "");
        let temp = isItFO ? 'PrPriceRaw' : 'PrNetPriceRaw'
        let totalValofElement = (Number(values[temp].toString().replace(/ /g, '').replace(',', '.')) * Number(values.PrQuantityRaw?.toString().replace(',', '.').replace(/ /g, ''))) / (Number(values.PrNetPriceUnit?.toString()))

        totalChangeFn(lineItem, totalValofElement)

    }, [values.PrQuantityRaw, values.PrPriceRaw, values.PrNetPriceRaw, values.PrNetPriceUnit, currencyConversionValue])

    return (
        <>
            <Grid container>

                <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={3}>
                        <Grid className={label}>
                            Material
                        </Grid>
                        <Grid className={select}>
                            <CustomFastAsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isMulti={false}
                                onLoadLimit={2}
                                clearable={false}
                                onLoad={x =>
                                    MasterService.fetchMaterial(x, [values.Plant], values.SystemAlias)
                                }
                                customDisplay={option =>
                                    option.label ? `${option.value} - ${option.label}` : option.value
                                }
                                disabled={isItFO || (!isItFO && isTherePO) || (!isItFO && isThereGR) || (!isItFO && isThereIR)}
                                initialValue={
                                    values.PrMaterial
                                        ? {
                                            value: values.PrMaterial.replace(/^0+/, "")
                                        }
                                        : {}
                                }
                                onChange={value =>
                                    value
                                        ? applyChange({
                                            PrMaterial: value.value
                                        })
                                        : null
                                }
                                customTooltipMessage="Type to find the materials"
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid className={label}>
                            Material Description
                        </Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                disabled={!isItZSIM}
                                type={"text"}
                                initialValue={values.PrItemTxt}
                                onChange={value => applyChange({ PrItemTxt: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid className={label}>
                            Delivery Date
                        </Grid>
                        <Grid className={select}>
                            <CustomFastDate
                                initialValue={DateTime.fromFormat(
                                    values.PrDeliveryDate,
                                    "yyyyMMdd"
                                ).toISO()}
                                onChange={value =>
                                    applyChange({
                                        PrDeliveryDate: DateTime.fromISO(value).toFormat("yyyyMMdd")
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid className={label}>Purch. Group</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                disabled={isItFO || (!isItFO && isTherePO) || (!isItFO && isThereGR) || (!isItFO && isThereIR)}
                                type={"text"}
                                initialValue={values.PurchGrp}
                                onChange={value => applyChange({ PurchGrp: value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={2}>
                        <Grid className={label}>Quantity</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                disabled={isItFO}
                                type={"number"}
                                initialValue={typeof (values.PrQuantityRaw) === 'string' ?
                                    Number(values.PrQuantityRaw.replace(',', '.')) :
                                    values.PrQuantityRaw}
                                onChange={value => applyChange({ PrQuantityRaw: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={2}>
                        <Grid className={label}>Unit</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                disabled={true}
                                type={"text"}
                                initialValue={values.PrQuantityUom}
                                onChange={value => applyChange({ PrQuantityUom: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={2}>
                        <Grid className={label}>Unit Price</Grid>
                        <Grid className={select}>
                            <Input
                                style={{
                                    margin: 0,
                                    fontSize: 14,
                                    height: '100%',
                                    width: "100%"
                                }}
                                type={"number"}
                                value={unitPrice}
                                onChange={applyUnitPriceChanges}

                            />

                        </Grid>
                    </Grid>
                    <Grid item sm={2}>
                        <Grid className={label}>Per Unit</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                disabled={isItFO}
                                type={"number"}
                                initialValue={parseInt(values.PrNetPriceUnit)}
                                onChange={value => applyChange({ PrNetPriceUnit: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={2}>
                        <Grid className={label}>Currency</Grid>
                        <Grid className={select}>
                            <CustomFastAsyncSelect
                                clearable={false}
                                disabled={isItFO}
                                styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                }}
                                defaultOptions={true}
                                isMulti={false}
                                onLoadLimit={0}
                                loadOnOpen={true}
                                onLoad={x => MasterService.fetchCurrencies(x, dfItem.SystemAlias)}
                                customDisplay={option => option.value}
                                initialValue={
                                    values.PrQuantityUom
                                        ? {
                                            value: values.PrCurrency
                                        }
                                        : null
                                }
                                onChange={value => applyCurrencyChanges(value)}
                            />
                        </Grid>
                    </Grid>
                    {isItEasyRequestPR && <Grid item sm={2}>
                        <Grid className={label}>Tax Code</Grid>
                        <Grid className={select}>
                            <CustomFastSelect
                                isMulti={false}
                                clearable={false}
                                customDisplay={option => option.value}
                                initialValue={{ value: values?.Taxcode }}
                                options={taxCodesData}
                                onChange={value => applyChange({ Taxcode: value?.value })} />
                        </Grid>
                    </Grid>}
                </Grid>
                {isItEasyRequestPR && isItFO && <>
                    <div style={{ margin: "20px 0 20px 0", fontWeight: "bold" }}>
                        Validity Date
                    </div>
                    <Grid container spacing={2} wrap={"nowrap"}>
                        <Grid item sm={6}>
                            <Grid className={label}>
                                Start Date
                            </Grid>
                            <Grid className={select}>
                                <CustomFastDate
                                    initialValue={DateTime.fromFormat(
                                        values.Validity_Start,
                                        "yyyyMMdd"
                                    ).toISO()}
                                    onChange={value =>
                                        applyChange({
                                            Validity_Start: DateTime.fromISO(value).toFormat("yyyyMMdd")
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <Grid className={label}>
                                End date
                            </Grid>
                            <Grid className={select}>
                                <CustomFastDate
                                    initialValue={DateTime.fromFormat(
                                        values.Validity_End,
                                        "yyyyMMdd"
                                    ).toISO()}
                                    onChange={value =>
                                        applyChange({
                                            Validity_End: DateTime.fromISO(value).toFormat("yyyyMMdd")
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
                <div style={{ margin: "20px 0 20px 0", fontWeight: "bold" }}>
                    Delivery Address
                </div>
                <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={4}>
                        <Grid className={label}>Name</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.Name1}
                                onChange={value => applyChange({ Name1: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={4}>
                        <Grid className={label}>Name Two</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.Name2}
                                onChange={value => applyChange({ Name2: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={1}>
                        <Grid className={label}>House #</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.Houseno}
                                onChange={value => applyChange({ Houseno: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid className={label}>Street</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.Street}
                                onChange={value => applyChange({ Street: value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={3}>
                        <Grid className={label}>Postal Code</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.Postalcode}
                                onChange={value => applyChange({ Postalcode: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid className={label}>City</Grid>
                        <Grid className={select}>
                            <CustomFastInput
                                type={"text"}
                                initialValue={values.City}
                                onChange={value => applyChange({ City: value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={2}>
                        <Grid className={label}>Country</Grid>
                        <Grid className={select}>
                            <CustomFastSelect
                                isMulti={false}
                                clearable={false}
                                customDisplay={option => option.value}
                                initialValue={{ value: values?.Country }}
                                options={_.uniq(countryRegionsData?.map(el => el.value)).map(el => ({ value: el }))}
                                onChange={value => applyChange({ Country: value?.value })} />
                        </Grid>
                    </Grid>
                    <Grid item sm={4}>
                        <Grid className={label}>State / Province</Grid>
                        <Grid className={select}>
                            <CustomFastSelect
                                isMulti={false}
                                disabled={!values?.Country}
                                clearable={false}
                                customDisplay={option =>
                                    option.label
                                        ? `${option.value} - ${option.label}`
                                        : option.value
                                }
                                initialValue={{ value: values?.Region }}
                                options={countryRegionsData?.filter(el => el.value === values?.Country).map(el => ({ value: el.Region, label: el.label }))}
                                onChange={value => applyChange({ Region: value?.value })} />
                        </Grid>
                    </Grid>
                </Grid>
                {(!isItMulti && (values.Pr_wbsid || values.Pr_CostCenter || values.PR_PROFIT)) &&
                    <>
                        <div style={{ margin: "20px 0 20px 0", fontWeight: "bold" }}>
                            Account Details
                        </div>
                        <Grid container spacing={2} wrap={"nowrap"}>
                            {values.Pr_wbsid &&
                                <Grid item sm={6}>
                                    <Grid className={label}>WBS / Project</Grid>
                                    <Grid className={select}>
                                        <CustomFastAsyncSelect
                                            disabled={(isItFO && (isThereGR || isThereIR)) || (!isItFO && isThereGR && isTherePO && isTherePR)}
                                            clearable={false}
                                            isMulti={false}
                                            onLoadLimit={3}
                                            onLoad={(x) => MasterService.fetchProjectId(x?.trim(), [{ id: dfItem?.CompCode || df.POCompanyCode }], dfItem?.SystemAlias)}
                                            customDisplay={option =>
                                                option.label
                                                    ? option.label
                                                    : option.value
                                            }
                                            initialValue={{ value: values.Pr_wbsid }}
                                            onChange={value => applyChange({ Pr_wbsid: value.WBSElement })} />
                                    </Grid>
                                </Grid>
                            }
                            {values.Pr_CostCenter &&
                                <Grid item sm={6}>
                                    <Grid className={label}>Cost Center</Grid>
                                    <Grid className={select}>
                                        <CustomFastAsyncSelect
                                            disabled={(isItFO && (isThereGR || isThereIR)) || !isItFO && isThereGR && isTherePO && isTherePR}
                                            clearable={false}
                                            isMulti={false}
                                            onLoadLimit={3}
                                            onLoad={(x) => MasterService.fetchCostCenter(x?.trim(), [{ id: dfItem?.CompCode || df.POCompanyCode }], dfItem?.SystemAlias)}
                                            customDisplay={option => `${option.value} - ${option.label}`}
                                            initialValue={{ value: values.Pr_CostCenter, label: values.Pr_CostCenterTxt }}
                                            onChange={value => applyChange({ Pr_CostCenter: value.value })} />
                                    </Grid>
                                </Grid>}
                            {values.PR_PROFIT &&
                                <Grid item sm={6}>
                                    <Grid className={label}>Profit Center</Grid>
                                    <Grid className={select}>
                                        <CustomFastAsyncSelect
                                            disabled={(isItFO && (isThereGR || isThereIR)) || !isItFO && isThereGR && isTherePO && isTherePR}
                                            clearable={false}
                                            isMulti={false}
                                            onLoadLimit={3}
                                            onLoad={(x) => MasterService.fetchProfitCenter(x?.trim(), dfItem?.SystemAlias)}
                                            customDisplay={option =>
                                                option.label
                                                    ? `${option.value} - ${option.label}`
                                                    : option.value
                                            }
                                            initialValue={{ value: values.PR_PROFIT }}
                                            onChange={value => applyChange({ PR_PROFIT: value.value })} />
                                    </Grid>
                                </Grid>}
                            <Grid item sm={6}>
                                <Grid className={label}>G/L Number</Grid>
                                <Grid className={select}>
                                    <CustomFastAsyncSelect
                                        disabled={(isItFO && (isThereGR || isThereIR)) || !isItFO && isThereGR && isTherePO && isTherePR}
                                        clearable={false}
                                        isMulti={false}
                                        onLoadLimit={3}
                                        onLoad={(x) => MasterService.fetchGlAccount(x?.trim(), dfItem?.SystemAlias)}
                                        customDisplay={option => `${option.value} - ${option.label}`}
                                        initialValue={{ value: values.Pr_GlAccount, label: values.Pr_GlAccountTxt }}
                                        onChange={value => applyChange({ Pr_GlAccount: value.value })} />

                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }


                <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={6}>
                        <div style={{ margin: "20px 0 20px 0", fontWeight: "bold" }}>
                            Spender
                        </div>
                        <Grid className={select}>
                            <CustomFastAsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isMulti={false}
                                clearable={false}
                                onLoadLimit={3}
                                loadOnOpen={true}
                                onLoad={x => MasterService.fetchRequestor(x, null, null)}
                                customDisplay={option =>
                                    option.label && option.value
                                        ? `${option.label} - ${option.value}`
                                        : option.label
                                            ? option.label
                                            : option.value
                                }
                                initialValue={
                                    values.PrRequisitioner
                                        ? {
                                            value: values.PrRequisitioner
                                        }
                                        : values.PrReqFname && values.PrReqLname
                                            ? {
                                                label: values.PrReqFname + " " + values.PrReqLname
                                            }
                                            : values.PrRequisitioner &&
                                                values.PrReqFname &&
                                                values.PrReqLname
                                                ? {
                                                    value: values.PrRequisitioner,
                                                    label: values.PrReqFname + " " + values.PrReqLname
                                                }
                                                : null
                                }
                                onChange={value =>
                                    value
                                        ? applyChange({
                                            PrRequisitioner: value.value,
                                            PrReqFname: value.label.substr(0, value.label.indexOf(" ")),
                                            PrReqLname: value.label.substr(value.label.indexOf(" ") + 1)
                                        })
                                        : applyChange({
                                            PrRequisitioner: "",
                                            PrReqFname: "",
                                            PrReqLname: ""
                                        })
                                }
                                customTooltipMessage="Type the UserID to start searching (3 chars min.)"
                            />
                        </Grid>
                    </Grid>

                </Grid>
                {!isItFO && <Grid container spacing={2} wrap={"nowrap"}>
                    <Grid item sm={12}>
                        <div style={{ margin: "20px 0 10px 0", fontWeight: "bold" }}>
                            Comments for Supplier
                        </div>
                        <Grid>
                            <CustomFastInput
                                type={"text"}
                                initialValue={supplierComments(values.Textsupplier)}
                                onChange={value => applyChange({ Textsupplier: value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>}
                {
                    values.PoNo && (
                        <div>
                            <div style={{ margin: "20px 0 0 0", fontWeight: "bold" }}>
                                Notify Change Request To:
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", }}>
                                {
                                    values.PoCreator !== "JOBRUN" && !!!values.ExtReqNo &&
                                    <EditAvatarContainer
                                        values={values}
                                        df={df}
                                        label1="PO Creator"
                                        email={values.POCreatoremail}
                                    ></EditAvatarContainer>
                                }
                                {genericEmails.length > 0 &&
                                    genericEmails.map(el => el && <EditAvatarContainer
                                        el={el}
                                        label1="Generic Emails"
                                        email={el}
                                    ></EditAvatarContainer>)}
                            </div>
                        </div>
                    )
                }
            </Grid >
        </>
    );
};

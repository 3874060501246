import React, { useEffect, useState } from 'react';
import { ResetButton as CloseBtn, SelectContainer } from 'src/app/shared/components/filters/filters.styles';
import { AvatarWithPopover, extractInitials } from '../AvatarWithPopover';
import { Avatar, Checkbox, Grid, Switch, Tooltip, Typography } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import _ from 'lodash';
import { useResendPoStyles } from './resendingPoStyles';
import { CustomFastAsyncSelect } from 'src/app/shared/components/form';
import { PSLService } from 'src/app/preferredSuppliers/domains/psl.service';
import TextInputWithMultiInputField from './hooks/textInputWithMultiInput';
import SendIcon from '@material-ui/icons/Send';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { Features, RemoteConfigurableValues } from 'src/app/shared/domains/core/pataflag';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { ReactMultiEmail } from 'react-multi-email';
import "react-multi-email/style.css";
import { DateTime } from 'luxon';
import { useStylesForToolTip } from 'src/app/shared/components/tooltip/toolTip.styles';
import { getResendPoPdfNotificationMessage } from './helper';
import { PataflagService } from 'src/app/shared/services/pataflag.service';

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 35,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(18px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

export const ResendPoPdfReceipnts = ({ documentData, documnetRow, setFinalResendPoData, showResendPoPdfButton, handleResendPoSubmit, disableResendPoPdfSection, resendPoNotificationMessage, msgWidth, finalTitle, disablePoPdfOptions=false }) => {

    const {
        Requester_Resend: requestorChecked,
        Spender_Resend: spendorChecked,
        Supplier_Resend: suppliersSapData,
        Other_Resend: othersSapData,
        P2P_Email: p2pSapData,
        VenMasemail: vedorMasterSapData,
        PRCreatoremail: requestorEmail,
        PRReqemail: spendorEmail,
        PrReqFname: spendorFirstName,
        PrReqLname: spendorLastName,
        PrRequisitioner: spendorId,
        Vendor_Flag: vendorFlag,
        Hide_Price: hidePriceSapData,
        Title_resend: titleResendSapData
    } = documnetRow;

    const {
        PrCreatorFirstName: requestorFirstName,
        PrCreatorLastName: requestorLastName,
        PrCreatorId: requestorId
    } = documentData;

    const { hasFeature } = React.useContext(FeaturesContext);
    const classesForToolTip = useStylesForToolTip({});

    const checkPrCutOffDate = () => {
        // this was added as part of us 166308

        let poCutOffDate: any = PataflagService.getInstance().valueOf(RemoteConfigurableValues.PO_PDF_CUTOFF_DATE);

        if (!poCutOffDate) return true;

        const poPdfCutOffDate: DateTime = DateTime.fromFormat(poCutOffDate, "yyyyMMdd"); // hard coded as per bruno for US 161442
        const prCreationDate: DateTime = DateTime.fromFormat(documentData?.PRCreationDate, "yyyyMMdd");

        return prCreationDate > poPdfCutOffDate

    }

    const IsPrCreatedAfterDate: boolean = checkPrCutOffDate();

    const showNotificationBar = showResendPoPdfButton === 'none' ? true : false

    const isP2pEmailsDataBelongsToSuppliersOrOthers = vendorFlag === '' ? 'others' : 'suppliers';

    const resendPoClasses = useResendPoStyles({});
    const [supplierBackGroundColor, setSupplierBackGroundColor] = useState('white')
    const [othersBackGroundColor, setOthersBackGroundColor] = useState('white')

    const prepareArrayData = (stringData) => {
        let emailsData = stringData.split(';').filter((email) => email !== "");
        const emails = emailsData.map((email) => ({ 'id': email, 'title': email }))
        return emails;
    }

    const prepareStringData = (arrayData) => {
        let emailsData = arrayData.map((email) => email.id).filter((email) => email !== undefined);
        let emails = emailsData.length > 0 ? emailsData.join(';') : '';
        return emails;
    }

    const concatSupplierSapResendPoData = () => {
        let newSupplierData = suppliersSapData;
        if (isSupplierP2pOutputTableChecked) {
            newSupplierData = newSupplierData + ";" + p2pSapData
        }
        if (isSupplierVendorMasterDataChecked) {
            newSupplierData = newSupplierData + ";" + vedorMasterSapData
        }
        return newSupplierData;
    }

    const concatSupplierPostResendPoData = () => {
        let newSupplierData = [...suppliersData];
        if (isSupplierP2pOutputTableChecked) {
            newSupplierData.push(supplierP2pData)
        }
        if (isSupplierVendorMasterDataChecked) {
            newSupplierData.push(supplierVendorMasterData)
        }
        return prepareStringData(newSupplierData)
    }

    const getSuppliersData = () => {
        let newSupplierData = suppliersSapData + (suppliersData ? ';' + prepareStringData(suppliersData) : '');
        return newSupplierData && newSupplierData.split(';').filter(function (value, index, self) {
            return self.indexOf(value) === index;
        }).join(';');
    }

    const getOthersData = () => {
        let newOthersData = othersSapData + (othersData ? ';' + prepareStringData(othersData) : '');
        return newOthersData && newOthersData.split(';').filter(function (value, index, self) {
            return self.indexOf(value) === index;
        }).join(';');
    }

    const getSuppliersStringData = (field: string) => {
        let newSupplierData = prepareStringData(suppliersData) + (suppliersSapData ? ';' + suppliersSapData : '');
        let emails = ''
        switch (field) {
            case 'P2P Output Table':
                emails = newSupplierData ? newSupplierData + ";" + p2pSapData : p2pSapData;
                return emails.split(';').filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                }).join(';');
            case 'Vendor Master Data':
                emails = newSupplierData ? newSupplierData + ";" + vedorMasterSapData : vedorMasterSapData;
                return emails.split(';').filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                }).join(';');
            default:
                return emails;
        }
    }

    const getOthersStringData = (field: string) => {
        let newOthersData = prepareStringData(othersData) + (othersSapData ? ';' + othersSapData : '');
        let emails = ''
        switch (field) {
            case 'P2P Output Table':
                emails = newOthersData ? newOthersData + ";" + p2pSapData : p2pSapData;
                // emails = newOthersData ? newOthersData +(isP2pEmailsDataBelongsToSuppliersOrOthers === 'others' ? ";"+p2pSapData : '') : (isP2pEmailsDataBelongsToSuppliersOrOthers === 'others' ? ";"+p2pSapData : '');
                return emails.split(';').filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                }).join(';');
            default:
                return emails;
        }
    }

    const getSuppliersArrayData = (field: string) => {
        let newSupplierData = suppliersData;
        let emails = [];
        let sapArrayData = [];
        switch (field) {
            case 'P2P Output Table':
                sapArrayData = prepareArrayData(p2pSapData);
                if (newSupplierData.length === 0) {
                    return emails
                } else {
                    emails = newSupplierData.filter(val => {
                        return !sapArrayData.find((val2) => {
                            return val.id === val2.id
                        })
                    });
                }
                return emails;
            case 'Vendor Master Data':
                sapArrayData = prepareArrayData(vedorMasterSapData);
                if (newSupplierData.length === 0) {
                    return emails
                } else {
                    emails = newSupplierData.filter(val => {
                        return !sapArrayData.find((val2) => {
                            return val.id === val2.id
                        })
                    });
                }
                return emails;
            default:
                return emails;
        }
    }

    const getOthersArrayData = (field: string) => {
        let newOthersData = othersData;
        let emails = [];
        let sapArrayData = [];
        switch (field) {
            case 'P2P Output Table':
                sapArrayData = prepareArrayData(p2pSapData);
                // p2pSapArrayData = isP2pEmailsDataBelongsToSuppliersOrOthers === 'others' ? prepareArrayData(p2pSapData) : [] 
                if (newOthersData.length === 0) {
                    return emails
                } else {
                    emails = newOthersData.filter(val => {
                        return !sapArrayData.find((val2) => {
                            return val.id === val2.id
                        })
                    });
                }
                return emails;
            default:
                return emails;
        }
    }

    const sapResendPoData = {
        Requester_Resend: requestorChecked,
        Spender_Resend: spendorChecked,
        Supplier_Resend: hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin) ? suppliersSapData : concatSupplierSapResendPoData(),
        Other_Resend: othersSapData,
        Hide_Price: hidePriceSapData,
        status: 'T',
        Title_resend: titleResendSapData,
    };

    // below state is needed to ensure first time thru, requestor is highlighted normally,
    // then its greyed out if no check boxes selected
    const [firstTime, setFirstTime] = useState(true);

    const [isRequestorChecked, setRequestorChecked] = useState(sapResendPoData.Requester_Resend ? true : false)
    const [isSpendorChecked, setSpendorChecked] = useState(sapResendPoData.Spender_Resend ? true : false)

    const [resendPoRequestorEmail, setRequestorEmail] = useState(isRequestorChecked ? sapResendPoData.Requester_Resend : '')
    const [resendPoSpendorEmail, setSpendorEmail] = useState(isSpendorChecked ? sapResendPoData.Spender_Resend : '')

    const [isSuppliersChecked, setSuppliersChecked] = useState(suppliersSapData ? true : false)
    const [suppliersData, setSuppliersData] = useState(suppliersSapData ? prepareArrayData(suppliersSapData) : [])

    const [isOthersChecked, setOthersChecked] = useState(othersSapData ? true : false)
    const [othersData, setOthersData] = useState(othersSapData ? prepareArrayData(othersSapData) : [])

    const [isSupplierP2pOutputTableChecked, setSupplierP2pOutputTableChecked] = useState(false)
    const [supplierP2pData, setSupplierP2pData] = useState(hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin) ? (isP2pEmailsDataBelongsToSuppliersOrOthers === 'suppliers' ? prepareArrayData(p2pSapData) : []) : p2pSapData ? prepareArrayData(p2pSapData) : [])

    const [isSupplierVendorMasterDataChecked, setSupplierVendorMasterDataChecked] = useState(false)
    const [supplierVendorMasterData, setSupplierVednorMasterData] = useState(vedorMasterSapData ? prepareArrayData(vedorMasterSapData) : [])

    const [isSupplierPreferredSupplierListChecked, setSupplierPreferredSupplierListChecked] = useState(false)
    const [supplierPreferrrdListData, setSupplierPreferrrdListData] = useState([])

    const [isOthersP2pOutputTableChecked, setOthersP2pOutputTableChecked] = useState(false)
    const [othersP2pData, setOthersP2pData] = useState(hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin) ? (isP2pEmailsDataBelongsToSuppliersOrOthers === 'others' ? prepareArrayData(p2pSapData) : []) : [])

    const initialHidePrice = hidePriceSapData && hidePriceSapData === 'X' ? true : false;
    const [isHideShowPriceToggleOn, setHideShowPriceToggleOn] = useState(initialHidePrice);

    const [toggleOn, setToogleOn] = React.useState(false);

    const handleChange = (value, setData) => {
        const newValues = value ? value.map(el => ({ title: el.title, id: el.id })) : [];
        if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
            if (newValues.length === 0) {
                setData(newValues);
                setSupplierP2pOutputTableChecked(false);
                setSupplierVendorMasterDataChecked(false);
            } else {
                setData(newValues);
            }
        } else {
            setData(newValues);
        }
    };

    const applyRequestorChecked = (value: boolean) => {
        if (value === false) {
            setRequestorChecked(value)
            setRequestorEmail('')
        } else {
            setRequestorChecked(value)
            setRequestorEmail(sapResendPoData.Requester_Resend ? sapResendPoData.Requester_Resend : requestorEmail)
        }
    }

    const applySpendorChecked = (value: boolean) => {
        setFirstTime(false);
        if (value === false) {
            setSpendorChecked(value)
            setSpendorEmail('')
        } else {
            setSpendorChecked(value)
            setSpendorEmail(sapResendPoData.Requester_Resend ? sapResendPoData.Spender_Resend : spendorEmail)
        }
    }

    const applySuppliersChecked = (value: boolean) => {
        setFirstTime(false);
        if (value === false) {
            setSuppliersChecked(value)
            setSupplierP2pOutputTableChecked(value)
            setSupplierVendorMasterDataChecked(value)
            setSupplierPreferredSupplierListChecked(value)
            if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
                setSuppliersData(prepareArrayData(getSuppliersData()).length > 0 ? prepareArrayData(getSuppliersData()) : []);
            } else {
                setSuppliersData(suppliersSapData ? prepareArrayData(suppliersSapData) : [])
            }
        } else {
            if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
                setSuppliersData(prepareArrayData(getSuppliersData()).length > 0 ? prepareArrayData(getSuppliersData()) : []);
            }
            setSuppliersChecked(value)
        }
    }

    const applySupplierP2pOutputTableChecked = (value: boolean) => {
        if (value === true) {
            setSupplierP2pOutputTableChecked(supplierP2pData.length > 0 ? true : false)
        } else {
            setSupplierP2pOutputTableChecked(false)
        }
    }

    const applySupplierVendorMasterDataChecked = (value: boolean) => {
        if (value === true) {
            setSupplierVendorMasterDataChecked(supplierVendorMasterData.length > 0 ? true : false)
        } else {
            setSupplierVendorMasterDataChecked(false)
        }
    }

    const applySupplierPreferredSupplierListChecked = (value: boolean) => {
        if (value === true) {
            setSupplierPreferredSupplierListChecked(supplierPreferrrdListData.length > 0 ? true : false)
        } else {
            setSupplierPreferredSupplierListChecked(false)
        }
    }

    const applyOthersChecked = (value: boolean) => {
        setFirstTime(false);
        if (value === false) {
            setOthersChecked(value)
            setOthersP2pOutputTableChecked(value)
            if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
                setOthersData(prepareArrayData(getOthersData()).length > 0 ? prepareArrayData(getOthersData()) : []);
            } else {
                setOthersData(othersSapData ? prepareArrayData(othersSapData) : [])
            }
        } else {
            if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
                setOthersData(prepareArrayData(getOthersData()).length > 0 ? prepareArrayData(getOthersData()) : []);
            }
            setOthersChecked(value)
        }
    }

    const applyOthersP2pOutputTableChecked = (value: boolean) => {
        if (value === true) {
            setOthersP2pOutputTableChecked(othersP2pData.length > 0 ? true : false)
        } else {
            setOthersP2pOutputTableChecked(false)
        }
    }

    const postResendPoData = {
        Requester_Resend: resendPoRequestorEmail ? resendPoRequestorEmail : '',
        Spender_Resend: resendPoSpendorEmail ? resendPoSpendorEmail : '',
        Supplier_Resend: isSuppliersChecked ? prepareStringData(suppliersData) : '',
        Other_Resend: isOthersChecked ? prepareStringData(othersData) : '',
        Hide_Price: isHideShowPriceToggleOn === true ? 'X' : '',
        status: 'T',
        Title_resend: titleResendSapData,
    }

    const updateResendPoChanges = () => {
        let resendPo = {
            "changedStatus": false, "postData": {
                Requester_Resend: resendPoRequestorEmail ? resendPoRequestorEmail.replace(/[\n/\nS]/g, '') : '',
            }
        };
        if (!_.isEqual(sapResendPoData, postResendPoData)) {
            resendPo['changedStatus'] = true;
            resendPo['postData'] = postResendPoData;
            return resendPo
        } else {
            return resendPo
        }
    }

    const handleHideShowPriceToggle = (event) => {
        setHideShowPriceToggleOn(event.target.checked);
    }

    const handleToggleChange = (event) => {
        setFirstTime(false);

        applyRequestorChecked(event.target.checked);
        applySpendorChecked(event.target.checked);
        applySuppliersChecked(event.target.checked);
        applySupplierP2pOutputTableChecked(event.target.checked);
        applySupplierVendorMasterDataChecked(event.target.checked);
        applySupplierPreferredSupplierListChecked(event.target.checked);
        applyOthersChecked(event.target.checked)
        applyOthersP2pOutputTableChecked(event.target.checked)
        setToogleOn(event.target.checked);
    };

    useEffect(() => {
        setFinalResendPoData(updateResendPoChanges());
        if (!(isSpendorChecked || isSuppliersChecked || isOthersChecked)) {
            applyRequestorChecked(true)
        }
    }, [isRequestorChecked, isSpendorChecked, isSuppliersChecked, isOthersChecked, suppliersData, othersData, toggleOn, isHideShowPriceToggleOn])

    useEffect(() => {
        if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
            if (isSupplierP2pOutputTableChecked) {
                setSuppliersData(prepareArrayData(getSuppliersStringData('P2P Output Table')))
                console.log('supplier p2p table checked')
            } else {
                setSuppliersData(getSuppliersArrayData('P2P Output Table'))
            }
        }
    }, [isSupplierP2pOutputTableChecked])

    useEffect(() => {
        if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
            if (isSupplierVendorMasterDataChecked) {
                setSuppliersData(prepareArrayData(getSuppliersStringData('Vendor Master Data')))
                console.log('supplier vendor checked')
            } else {
                setSuppliersData(getSuppliersArrayData('Vendor Master Data'))
            }
        }
    }, [isSupplierVendorMasterDataChecked])

    useEffect(() => {
        if (isOthersP2pOutputTableChecked) {
            setOthersData(prepareArrayData(getOthersStringData('P2P Output Table')))
            console.log('others p2p table checked')
        } else {
            setOthersData(getOthersArrayData('P2P Output Table'))
        }
    }, [isOthersP2pOutputTableChecked])

    useEffect(() => {
        setFinalResendPoData({ "changedStatus": false, "postData": sapResendPoData })
        if (hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin)) {
            setSuppliersData(suppliersSapData ? prepareArrayData(getSuppliersData()) : [])
            setOthersData(othersSapData ? prepareArrayData(getOthersData()) : [])
        }
    }, [])

    return (
        <>
            {
                !disablePoPdfOptions && hasFeature(Features.RC_164177_SHOW_RESEND_PO_PDF_BUTTON_ON_CONDITIONS) &&
                <Tooltip
                    title={!IsPrCreatedAfterDate ? "Recipients cannot be changed for PRs created before February 20th, 2024" : ""}
                    classes={{ tooltip: classesForToolTip.customTooltip }}
                >
                    <div style={{ backgroundColor: !IsPrCreatedAfterDate ? "#f5f5f5" : "none" }}>
                        <div style={{ display: (showNotificationBar || (initialHidePrice !== isHideShowPriceToggleOn)) ? 'block' : 'none', paddingBottom: '15px', width: msgWidth, maxWidth: '46%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '8px', border: 'solid 2px #7bc1e7', backgroundColor: '#edf7fc', borderRadius: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#3da3dc' }}>
                                    <ErrorOutlineIcon />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                                    {(initialHidePrice !== isHideShowPriceToggleOn) ? getResendPoPdfNotificationMessage(true, false) :
                                        resendPoNotificationMessage}
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '10px 10px 25px 10px', display: 'flex', gap: '9px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AntSwitch disabled={!IsPrCreatedAfterDate} checked={toggleOn} onChange={handleToggleChange} name="checked" />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{toggleOn ? 'Deselect All' : 'Select All'}</span>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            }

            <div className={disablePoPdfOptions ? resendPoClasses.disableDiv : disableResendPoPdfSection ? resendPoClasses.disabledDiv : resendPoClasses.enableDiv}>
                <Tooltip
                    title={!IsPrCreatedAfterDate ? "Recipients cannot be changed for PRs created before February 20th, 2024" : ""}
                    classes={{ tooltip: classesForToolTip.customTooltip }}

                >
                    <div style={{ backgroundColor: !IsPrCreatedAfterDate ? "#f5f5f5" : "none" }}>
                        <div style={{ display: 'flex', gap: '2px' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        disabled={!IsPrCreatedAfterDate}
                                        checked={isRequestorChecked}
                                        defaultChecked={isRequestorChecked}
                                        onChange={(_, checked) => applyRequestorChecked(checked)}
                                        size="small"
                                        style={{
                                            color: IsPrCreatedAfterDate && (firstTime || ((isSpendorChecked || isSuppliersChecked || isOthersChecked) && isRequestorChecked)) ? "orange" : "gray",
                                        }}
                                    />
                                </div>
                                <div className={IsPrCreatedAfterDate && (firstTime || ((isSpendorChecked || isSuppliersChecked || isOthersChecked) && isRequestorChecked)) ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AvatarWithPopover
                                            item={{}}
                                            firstName={requestorFirstName}
                                            lastName={requestorLastName}
                                            userId={requestorId}
                                            customStyles={{ fontSize: 14, width: 37, height: 36 }}
                                            email={requestorEmail}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#F19001" }}>{'Requestor'}</span>
                                            <span style={{ fontSize: "12px", fontWeight: 500, color: "#7E7E7E", lineHeight: "1" }}>{requestorEmail}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        disabled={!IsPrCreatedAfterDate}
                                        checked={isSpendorChecked}
                                        defaultChecked={isSpendorChecked}
                                        onChange={(_, checked) => applySpendorChecked(checked)}
                                        size="small"
                                        style={{
                                            color: IsPrCreatedAfterDate && isSpendorChecked ? "orange" : "gray",
                                        }}
                                    />
                                </div>
                                <div className={IsPrCreatedAfterDate && isSpendorChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AvatarWithPopover
                                            item={{}}
                                            firstName={spendorFirstName}
                                            lastName={spendorLastName}
                                            userId={spendorId}
                                            customStyles={{ fontSize: 14, width: 37, height: 36 }}
                                            email={hasFeature(Features.RC_165911_SPENDOR_UPDATE_WITH_PO_SENDING) ? spendorChecked : spendorEmail}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                            <span style={{ fontSize: "14px", fontWeight: "bold", color: "#F19001" }}>{'Spender'}</span>
                                            <span style={{ fontSize: "12px", fontWeight: 500, color: "#7E7E7E", lineHeight: "1" }}>{hasFeature(Features.RC_165911_SPENDOR_UPDATE_WITH_PO_SENDING) ? (spendorChecked ? `${spendorChecked}` : `${spendorFirstName} ${spendorLastName}`) : spendorEmail ? `${spendorEmail}` : `${spendorFirstName} ${spendorLastName}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br />
                        <div style={{ display: 'flex', gap: '2px', backgroundColor: !IsPrCreatedAfterDate ? "#f5f5f5" : supplierBackGroundColor }} onMouseEnter={() => setSupplierBackGroundColor('white')} onMouseLeave={() => setSupplierBackGroundColor('white')}>
                            <div style={{ display: 'flex', gap: '30px', paddingTop: '10px', paddingBottom: '10px' }}>

                                <div>
                                    <div style={{ display: 'flex' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                disabled={!IsPrCreatedAfterDate}
                                                checked={isSuppliersChecked}
                                                defaultChecked={isSuppliersChecked}
                                                onChange={(_, checked) => applySuppliersChecked(checked)}
                                                size="small"
                                                style={{
                                                    color: IsPrCreatedAfterDate && isSuppliersChecked ? "orange" : "gray",
                                                }}
                                            />
                                        </div>

                                        {
                                            isSuppliersChecked &&
                                            <div style={{ display: 'flex', alignItems: 'center' }} className={IsPrCreatedAfterDate && isSuppliersChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv}>
                                                <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(241, 144, 1)' }}>Suppliers</span>
                                            </div>
                                        }

                                        {
                                            !isSuppliersChecked &&
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} className={IsPrCreatedAfterDate && isSuppliersChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv}>
                                                <Avatar
                                                    style={{ fontSize: "1.1rem", width: "36px", height: "36px", border: "2px solid #F2F4F8" }}
                                                    aria-owns={open ? "mouse-over-popover" : undefined}
                                                    aria-haspopup="true"
                                                >
                                                    <PersonOutlineOutlinedIcon />
                                                </Avatar>
                                                <div>
                                                    <span style={{ fontSize: '15px', color: 'gray' }}>Suppliers</span>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                {
                                    isSuppliersChecked &&
                                    <div style={{ flex: '0 0 200%', maxWidth: '1120px', width: '1120px' }}>

                                        <div>
                                            <SelectContainer>
                                                {!IsPrCreatedAfterDate ?
                                                    <TextInputWithMultiInputField
                                                        defaultValues={supplierP2pData}
                                                    /> :
                                                    <ReactMultiEmail

                                                        placeholder='Type an email'
                                                        emails={
                                                            suppliersData.map((el: { id: any; title: any; }) => el.id)
                                                        }
                                                        onChange={(_emails: string[]) => {
                                                            handleChange(_emails?.map(email => ({ id: email, title: email })), setSuppliersData)
                                                        }}
                                                        getLabel={(email: string,
                                                            index: number,
                                                            removeEmail: (index: number) => void
                                                        ) => {
                                                            return (
                                                                <div data-tag key={index}>
                                                                    <div data-tag-item>{email}</div>
                                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                        ×
                                                                    </span>
                                                                </div>
                                                            );
                                                        }}
                                                    />}
                                            </SelectContainer>
                                        </div><br />
                                        <div style={{ display: 'flex', gap: '64px' }}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Checkbox
                                                            checked={isSupplierP2pOutputTableChecked}
                                                            defaultChecked={isSupplierP2pOutputTableChecked}
                                                            onChange={(_, checked) => setSupplierP2pOutputTableChecked(checked)}
                                                            size="small"
                                                            style={{
                                                                color: IsPrCreatedAfterDate && isSupplierP2pOutputTableChecked ? "orange" : "gray",
                                                            }}
                                                            disabled={IsPrCreatedAfterDate && (supplierP2pData.length > 0) ? false : true}
                                                        />
                                                    </div>
                                                    <div className={IsPrCreatedAfterDate && isSupplierP2pOutputTableChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>P2P Output Table</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ flex: '0 0 80%' }}>
                                                <TextInputWithMultiInputField
                                                    defaultValues={supplierP2pData}
                                                />
                                            </div>
                                        </div><br />

                                        <div style={{ display: 'flex', gap: '47px' }}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Checkbox
                                                            checked={isSupplierVendorMasterDataChecked}
                                                            defaultChecked={isSupplierVendorMasterDataChecked}
                                                            onChange={(_, checked) => setSupplierVendorMasterDataChecked(checked)}
                                                            size="small"
                                                            style={{
                                                                color: IsPrCreatedAfterDate && isSupplierVendorMasterDataChecked ? "orange" : "gray",
                                                            }}
                                                            disabled={IsPrCreatedAfterDate && (supplierVendorMasterData.length > 0) ? false : true}
                                                        />
                                                    </div>
                                                    <div className={IsPrCreatedAfterDate && isSupplierVendorMasterDataChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>Vendor Master Data</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ flex: '0 0 80%' }}>
                                                <TextInputWithMultiInputField
                                                    defaultValues={supplierVendorMasterData}
                                                />
                                            </div>
                                        </div><br />

                                        <div style={{ display: 'flex', gap: '30px' }}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Checkbox
                                                            checked={false}
                                                            defaultChecked={false}
                                                            onChange={(_, checked) => setSupplierPreferredSupplierListChecked(checked)}
                                                            size="small"
                                                            style={{
                                                                color: IsPrCreatedAfterDate && isSupplierPreferredSupplierListChecked ? "orange" : "gray",
                                                            }}
                                                            disabled={IsPrCreatedAfterDate && (supplierPreferrrdListData.length > 0) ? false : true}
                                                        />
                                                    </div>
                                                    <div className={IsPrCreatedAfterDate && isSupplierPreferredSupplierListChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>Preferred Supplier List</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ flex: '0 0 80%' }}>
                                                <TextInputWithMultiInputField
                                                    defaultValues={supplierPreferrrdListData}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>
                        <div className={!IsPrCreatedAfterDate && isOthersChecked ? resendPoClasses.grayColorBackgroud : resendPoClasses.whiteColorBackgroud} style={{ display: 'flex', gap: '2px', backgroundColor: !IsPrCreatedAfterDate ? "#f5f5f5" : othersBackGroundColor }} onMouseEnter={() => setOthersBackGroundColor('white')} onMouseLeave={() => setOthersBackGroundColor('white')}>
                            <div style={{ display: 'flex', gap: '49px', paddingTop: '10px', paddingBottom: '10px' }}>
                                <div>
                                    <div style={{ display: 'flex' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                disabled={!IsPrCreatedAfterDate}
                                                checked={isOthersChecked}
                                                defaultChecked={isOthersChecked}
                                                onChange={(_, checked) => applyOthersChecked(checked)}
                                                size="small"
                                                style={{
                                                    color: IsPrCreatedAfterDate && isOthersChecked ? "orange" : "gray",
                                                }}
                                            />
                                        </div>

                                        {
                                            isOthersChecked &&
                                            <div style={{ display: 'flex', alignItems: 'center' }} className={IsPrCreatedAfterDate && isOthersChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv}>
                                                <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(241, 144, 1)' }}>Others</span>
                                            </div>
                                        }

                                        {
                                            !isOthersChecked &&
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} className={IsPrCreatedAfterDate && isOthersChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv}>
                                                <Avatar
                                                    style={{ fontSize: "1.1rem", width: "36px", height: "36px", border: "2px solid #F2F4F8" }}
                                                    aria-owns={open ? "mouse-over-popover" : undefined}
                                                    aria-haspopup="true"
                                                >
                                                    <PersonOutlineOutlinedIcon />
                                                </Avatar>
                                                <div>
                                                    <span style={{ fontSize: '15px', color: 'gray' }}>Others</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    isOthersChecked &&
                                    <div style={{ flex: '0 0 200%', width: '1120px', maxWidth: '1120px' }}>

                                        <div>
                                            <SelectContainer>
                                                <CustomFastAsyncSelect
                                                    disabled={IsPrCreatedAfterDate && isOthersChecked ? false : true}
                                                    onLoad={PSLService.fetchPslUserEmails}
                                                    onLoadLimit={3}
                                                    isMulti={true}
                                                    initialValue={
                                                        othersData.map((el: { id: any; title: any; }) => ({
                                                            value: el.id,
                                                            label: el.title
                                                        }))
                                                    }
                                                    onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), setOthersData)}
                                                />
                                            </SelectContainer>
                                        </div><br />

                                        <div style={{ display: 'flex', gap: '64px' }}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Checkbox
                                                            checked={isOthersP2pOutputTableChecked}
                                                            defaultChecked={isOthersP2pOutputTableChecked}
                                                            onChange={(_, checked) => setOthersP2pOutputTableChecked(checked)}
                                                            size="small"
                                                            style={{
                                                                color: IsPrCreatedAfterDate && isOthersP2pOutputTableChecked ? "orange" : "gray",
                                                            }}
                                                            disabled={IsPrCreatedAfterDate && (othersP2pData.length > 0) ? false : true}
                                                        />
                                                    </div>
                                                    <div className={IsPrCreatedAfterDate && isOthersP2pOutputTableChecked ? resendPoClasses.enableDiv : resendPoClasses.disabledDiv} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '15px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>P2P Output Table</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ flex: '0 0 80%' }}>
                                                <TextInputWithMultiInputField
                                                    defaultValues={othersP2pData}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Tooltip>
                {
                    !disablePoPdfOptions && <>
                    {
                        hasFeature(Features.RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin) ?
                            <div style={{ display: (documnetRow.PoNo === '' || !(toggleOn || isSpendorChecked || isSuppliersChecked || isOthersChecked) || (initialHidePrice !== isHideShowPriceToggleOn)) ? "none" : "block" }}>
                                <div style={{ padding: '15px', display: showResendPoPdfButton }}>
                                    <CloseBtn
                                        style={{
                                            color: 'orange', boxShadow: 'none', cursor: 'pointer',
                                            marginRight: 20,
                                        }}
                                        onClick={handleResendPoSubmit}
                                        variant="contained" color="primary">
                                        <SendIcon />&nbsp;&nbsp;&nbsp;Resend PO PDF
                                    </CloseBtn>
                                </div>
                            </div>
                            :
                            <div style={{ padding: '15px', display: showResendPoPdfButton }}>
                                <CloseBtn
                                    style={{
                                        color: 'orange', boxShadow: 'none', cursor: 'pointer',
                                        marginRight: 20,
                                    }}
                                    onClick={handleResendPoSubmit}
                                    variant="contained" color="primary">
                                    <SendIcon />&nbsp;&nbsp;&nbsp;Resend PO PDF
                                </CloseBtn>
                            </div>
                    }
                    </>
                }
                
            </div>
            {
                !disablePoPdfOptions && hasFeature(Features.RC_164600_SHOW_HIDE_PRICE) &&

                // <div style={{ padding: '15px 10px 25px 10px', display: 'flex', gap: '9px' }}>
                //     <div style={{ display: 'flex', alignItems: 'center' }}>
                //         <AntSwitch disabled={!IsPrCreatedAfterDate} checked={isHideShowPriceToggleOn} onChange={handleHideShowPriceToggle} name="checked" />
                //     </div>
                //     <div style={{ display: 'flex', alignItems: 'center' }}>
                //         <span>{isHideShowPriceToggleOn ? 'Hide Price' : 'Hide Price'}</span>
                //     </div>
                // </div>

                // Tooltip Messsage Added

                <div style={{ padding: '15px 10px 25px 10px', display: 'flex', gap: '9px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip
                            interactive
                            classes={{ tooltip: classesForToolTip.hidePriceTooltip }}
                            title={
                                <div>
                                    <span>
                                        When selected, this option hides the material prices on the PDF Purchase Order, ensuring that suppliers cannot view them. This is especially useful when the captured material prices represent estimated budgets that you wish to keep confidential from your suppliers.
                                    </span>
                                </div>
                            }>
                            <AntSwitch disabled={!IsPrCreatedAfterDate} checked={isHideShowPriceToggleOn} onChange={handleHideShowPriceToggle} name="checked" />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{isHideShowPriceToggleOn ? 'Hide Price' : 'Hide Price'}</span>
                    </div>
                </div>
            }
        </>
    )
}

export default ResendPoPdfReceipnts;


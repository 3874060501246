import React from "react";
import clsx from "clsx";
import { CustomFastInput } from "../../../shared/components/form";
import { FeedbackList } from "../../../shared/containers/dashboard/components/feedback/feedback.styles";
import { createStyles, makeStyles, Theme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DateTime } from "luxon";
import { MasterService } from "../../../shared/domains/master/master.service";
import { StyledButton } from "../../../shared/components/layout/styles";
import { useDispatch } from "react-redux";
import { DownloadDocument } from "../../actions/documentFlow.actions";
import { DropzoneDialog } from 'material-ui-dropzone'
import CloseIcon from '@material-ui/icons/Close';
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
      fontStyle: "italic",
      marginBottom: 5
    },
    fileName: {
      fontSize: 11,
    },
  })
);

export const PoSendingOptions = ({ invoices, open, setOpen, values, setValues, setEnableSave, files, setFiles }) => {
  const dispatch = useDispatch();
  const { label, fileName } = useStyles({});
  const { hasFeature } = React.useContext(FeaturesContext);

  const applyChange = (vals: { [key: string]: any }) => {
    const newItem = {
      ...values,
      ...vals
    };
    setValues(newItem);
    setEnableSave(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSave = (fs) => {
    setFiles(files.concat(fs));
    setOpen(false);
  };

  const handleDelete = (file) => {
    const filesAfterDeleting = files.filter(x => x !== file);
    setFiles(filesAfterDeleting);
  }

  const handleOnClick = async invoice => {
    const fileName = (invoice.Descr.substr(0, invoice.Descr.lastIndexOf(".")) || invoice.Descr) + "." + invoice.TechType;
    if (invoice.TechType === "URL") {
      const response = await MasterService.fetchInvoiceDataInNewTab(values.PrNo, 'BUS2105', invoice.AttaId, values.SystemAlias)
      const openURL = response.includes("http") ? response : "https://" + response;
      window.open(openURL, "_blank")
      return;
    }
    dispatch(new DownloadDocument(values.PrNo, 'BUS2105', invoice.AttaId, fileName, values.SystemAlias));
  }

  return (
    <>
      <Grid container>
        <Grid item sm={12} className={label}>
          <div>Indicate to the Operational Buyers whether you want them to send the PDF of the PO again
            <p style={{fontSize: ".8rem"}}>Note: this is just informative. A new version of the PO might be sent automatically by the system even if you select "No" as this depends on your market SAP settings</p>
          </div>
        </Grid>
        <Grid item sm={12}>
          <FeedbackList>
            <li key="yes" className={clsx('item', 'type', values.SendPDF ? 'selected' : '')}
              onClick={value => applyChange({ SendPDF: "X" })}><div className="text">Yes</div>
            </li>
            <li key="no" className={clsx('item', 'type', !values.SendPDF ? 'selected' : '')}
              onClick={value => applyChange({ SendPDF: "" })}><div className="text">No</div>
            </li>
          </FeedbackList>
        </Grid>
      </Grid>
    </>
  );
};

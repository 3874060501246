import { Avatar, Button, makeStyles, Menu, Tooltip } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { ContactCardComponent } from "src/app/shared/components/contact-card/contactCard.component";
import { CustomFastAsyncSelect } from "src/app/shared/components/form";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { Features } from "src/app/shared/domains/core/pataflag";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { extractInitials } from "../../../AvatarWithPopover";

export const SpenderComponent = ({ lineItem, applyChanges, disbaleItemDetails=false }: { lineItem: DocFlowItem, applyChanges: any , disbaleItemDetails?: boolean}) => {
    const { hasFeature } = React.useContext(FeaturesContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const toolTipclass = useStylesForToolTip({});
    const [spender, setSpender] = useState<{ PrRequisitioner: string, PrReqFname: string, PrReqLname: string, Spender_Resend: string }>(
        {
            PrRequisitioner: lineItem.PrRequisitioner,
            PrReqFname: lineItem.PrReqFname,
            PrReqLname: lineItem.PrReqLname,
            Spender_Resend: lineItem.Spender_Resend
        }
    );

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSave = () => {
        console.log(spender);
        applyChanges({ ...spender }, lineItem.PrItem);
        handleClose();
    };

    return (
        <div style={{ display: 'flex', marginLeft: 14 }}>
            <Tooltip
                interactive
                classes={{ tooltip: (hasFeature(Features.RC_163396_Hover_Contact_Card) && lineItem.PRReqemail) ? toolTipclass.contactCardToolTip : toolTipclass.customTooltip }}
                title={(hasFeature(Features.RC_163396_Hover_Contact_Card) && lineItem.PRReqemail) ?
                    <ContactCardComponent email={lineItem.PRReqemail} /> :
                    <div style={{ fontSize: 14, padding: "5px 0" }}>
                        <div style={{ paddingBottom: 5 }}>{`${lineItem.PrReqFname} ${lineItem.PrReqLname}`}</div>
                        <div>{`UserID: ${lineItem.PrRequisitioner}`}</div>
                    </div>
                }
                placement="bottom-start"
            >
                <Avatar style={{ fontSize: "14px", width: "32px", height: "32px", border: "2px solid #F2F4F8", cursor: "pointer" }}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    {extractInitials(lineItem.PrReqFname, lineItem.PrReqLname, lineItem.PrRequisitioner)}
                </Avatar></Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div
                    style={{
                        margin: "12px 20px", fontSize: 14,
                        width: '250px',
                    }}>
                    <div style={{ marginBottom: "20px", fontSize: 14, fontWeight: "bolder" }}>SPENDER</div>
                    <CustomFastAsyncSelect
                        disabled={disbaleItemDetails}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        clearable={false}
                        onLoadLimit={3}
                        loadOnOpen={true}
                        onLoad={x => MasterService.fetchRequestor(x, null, null)}
                        customDisplay={option =>
                            option.label && option.value
                                ? `${option.label} - ${option.value}`
                                : option.label
                                    ? option.label
                                    : option.value
                        }
                        initialValue={
                            spender.PrRequisitioner
                                ? {
                                    value: spender.PrRequisitioner
                                }
                                : spender.PrReqFname && spender.PrReqLname
                                    ? {
                                        label: spender.PrReqFname + " " + spender.PrReqLname
                                    }
                                    : spender.PrRequisitioner &&
                                        spender.PrReqFname &&
                                        spender.PrReqLname
                                        ? {
                                            value: spender.PrRequisitioner,
                                            label: spender.PrReqFname + " " + spender.PrReqLname
                                        }
                                        : null
                        }
                        onChange={value =>
                            value
                                ? setSpender({
                                    PrRequisitioner: value.value,
                                    PrReqFname: value.label.substr(0, value.label.indexOf(" ")),
                                    PrReqLname: value.label.substr(value.label.indexOf(" ") + 1),
                                    Spender_Resend: value.email
                                })
                                : setSpender({
                                    PrRequisitioner: "",
                                    PrReqFname: "",
                                    PrReqLname: "",
                                    Spender_Resend: ""
                                })
                        }
                        customTooltipMessage="Type the UserID to start searching (3 chars min.)"
                    />
                    <div style={{ display: "flex", justifyContent: "right", marginTop: "10px" }}>
                        <Button color="secondary" onClick={handleClose} >Cancel</Button>
                        <Button color="secondary" onClick={handleSave} > Save </Button>
                    </div>
                </div>
            </Menu>
        </div>
    );

};

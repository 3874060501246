import { useHistory } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocFlowItem } from "../../domains/prpo/poItem";
import { isEmpty } from "ramda";
import { EditPrPopup } from "./EditPrPopup";
import { EditPoPopup } from "./EditPoPopup";
import { IPromiseExecutor } from "../../../shared/domains/core/actions";
import {
  SaveDocFlowItem,
  RequestChangeDocFlowItem,
  DocumentFlowFetchLoad,
  DocumentFlowResubmitSuccessAction,
} from "../../actions/documentFlow.actions";
import DocumentFlow from "../../domains/documentFlow/documentFlow";
import { RootStateStore } from "../../../application.reducers";
import { PopupLoading } from "../../../shared/components/loading";
import { DocumentFlowService } from "../../domains/documentFlow/documentFlow.service";
import { MrpUser, User } from "../../../shared/domains/user/user";
import { FeaturesContext } from "../../../shared/domains/core/pataflag.context";
import { Features } from "../../../shared/domains/core/pataflag";
import { UserInteractionType } from "../../../shared/domains/user/userInteractionType";
import { compCodes, doWeNeedRteUrl, doWeNeedUrl } from "../dataTable/docFlow/docFlowTableRelated/PrLink";
import { EditPrPopup2 } from "./EditPrPopup2";
import EditRequestAllLinesWrapper from "./editRequestAllLines/EditRequestAllLinesWrapper";
import { MultiLineEditExpandPage } from "src/app/purchaseRequisition/PR.styles";
import { DrawerForMultiLineEdit } from "src/app/shared/components/layout/styles";
import { useCreateFairmrktRfq, useResubmitPrHook } from "../../hooks/docflow.hooks";
import { QueryActions } from "../../reducers/queryableDocumentFlowReducer";
import Notification, { NotificationType } from "../../../shared/components/notification";
import { Portal } from "@material-ui/core";
import { verifyAndGetVertexPayload } from "../../helpers/functions";
import { vertexSampleResponse } from "../../helpers/constants";
interface MenuProps {
  user: User;
  df: DocumentFlow;
  anchorEl: HTMLElement;
  dfItem: DocFlowItem;
  setAnchorEl: Dispatch<HTMLElement>;
  showNewUrls?: boolean;
  allLines?: DocFlowItem[];
  zone: string
}

export const getErrorMessage = (errorObject: any) => {
  const message: any = errorObject;
  const status: string = message?.response?.status?.toString();

  if (message?.response?.data?.nodata) {
    return "RFQ Initiation failed - " + message?.response.data.nodata;
  };

  if (message?.response?.data?.detail) {
    return "RFQ Initiation failed at Fairmarkt, cross check the PR Data once, failed with error code - " + status;
  };

  return errorObject?.toString();
}

export default function ItemDrilldownMenuComponent(props: MenuProps) {
  const history = useHistory();
  const { hasFeature } = React.useContext(FeaturesContext);
  const dispatch = useDispatch();
  const { df, dfItem, anchorEl, setAnchorEl, showNewUrls, allLines } = props;
  const [showEditPr, setShowEditPr] = React.useState(false);
  const [showEditPr2, setShowEditPr2] = React.useState(false);
  const [showEditPo, setShowEditPo] = React.useState(false);
  const [ccEdit, setCCEdit] = React.useState([]);
  const [poEdit, setPOEdit] = React.useState([]);
  const [disableEditPr, setDisableEditPr] = React.useState(false);
  const { mutateAsync: reSubmitPRForOB, error: resubmitError, isSuccess: isResubmitSuccess, isError: isResubmitError } = useResubmitPrHook(() => dispatch(new DocumentFlowResubmitSuccessAction()));

  const { mutateAsync: initialRfq, error: initiateRfqError, isSuccess: isInitiateRfqSuccess, isError: isInitiateRfqError } = useCreateFairmrktRfq();

  const user: MrpUser = useSelector((state: RootStateStore) => MrpUser.fromUser(state.shared.user.currentUser));
  const isBuyer = (user.isAtMarket() || user.isAboveMarket());

  const newPrUrl = "https://globe7dev.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpdev.nestle.com:52802/launchpad%23RGTPP01_ME53N-execute%3FP_BANFN%3d" + df?.PurchaseReqNo;
  const newPoUrl = "https://globe7dev.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpdev.nestle.com:52802/launchpad%23RGTPT01_ME23N-execute%3FP_EBELN%3d" + df?.PurchaseOrdNo;
  const newRtePRUrl = "https://globe7rte.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpaoarte.nestle.com:55802/launchpad%23RGTPP01_ME53N-execute%3FP_BANFN%3d" + df?.PurchaseReqNo;
  const newRtePOUrl = "https://globe7rte.nestle.com/irj/servlet/prt/portal/prtroot/com.nestle.globe.ep7core.xx.redirect.Redirect_window?REDIRECTURL=https://erpaoarte.nestle.com:55802/launchpad%23RGTPT01_ME23N-execute%3FP_EBELN%3d" + df?.PurchaseOrdNo;

  const sending: boolean = useSelector(
    (state: RootStateStore) =>
      state.docFlows.documentFlows.data.onClosePOLoading
  );

  const showEditPrAndPoOptionOnChange = hasFeature(Features.RC_166262_EDIT_PR_FOR_SPECIFIC_USERS)

  const [vertexDetails, setVertexDetails] = React.useState({ loading: false, data: {} });

  useEffect(() => {
    async function fetchCC() {
      const response = await DocumentFlowService.fetchCCThatCanEditRequests();
      setCCEdit(response);
    }
    async function fetchPO() {
      const response = await DocumentFlowService.fetchPOThatCanEditRequests();
      setPOEdit(response);
    }
    fetchCC();
    fetchPO();
  }, []);

  async function logUserInteractionData(action: UserInteractionType) {
    await DocumentFlowService.logUserInteractionData(action);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleEditPr() {
    setShowEditPr(true);
  }

  async function handleEditPr2() {
    setShowEditPr2(true);
    setDisableEditPr(false);
    if (hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS)) {
      const isNeedToCallVertexWithPayload = verifyAndGetVertexPayload(allLines, dfItem, df);
      console.log(isNeedToCallVertexWithPayload)
      if (isNeedToCallVertexWithPayload?.status) {
        // setVertexDetails({loading:true,data: {}})
        setVertexDetails({ loading: false, data: vertexSampleResponse })
        const vertexData = await DocumentFlowService.fetchTaxDataFromVertex(isNeedToCallVertexWithPayload?.payload);
        // console.log(vertexData.data)
        // setVertexDetails({loading:false,data: vertexData.data})
      }
    }
  }

  function handleViewMore() {
    setShowEditPr2(true);
    setDisableEditPr(true)
  }

  function handleEditPo() {
    setShowEditPo(true);
  }

  function handleOnDonePr() {
    setShowEditPr(false);
    setAnchorEl(null);
  }
  function handleOnDonePr2() {
    setShowEditPr2(false);
    setDisableEditPr(false);
    setAnchorEl(null);
  }
  function handleOnDonePo() {
    setShowEditPo(false);
    setAnchorEl(null);
  }

  function redirect(link: string, openBlank: boolean) {
    handleClose();
    openBlank ? window.open(link, "_blank") : history.push(link);
  }

  const requestChangeDocFlowItem = (docFlow: DocumentFlow) => (
    executor: IPromiseExecutor,
    docFlowItem: DocFlowItem,
    onDone: () => void,
    docFlowItemOld: DocFlowItem,
    sendEmailToCreator: boolean,
    docflowItemNew: DocFlowItem,
    formData: any
  ) =>
    dispatch(
      new RequestChangeDocFlowItem(
        executor,
        docFlowItem,
        onDone,
        docFlow,
        docFlowItemOld,
        sendEmailToCreator,
        docflowItemNew,
        formData
      )
    );

  const saveDocFlowItem = (docFlow: DocumentFlow) => (
    executor: IPromiseExecutor,
    docFlowItem: DocFlowItem,
    onDone: () => void
  ) => dispatch(new SaveDocFlowItem(executor, docFlowItem, onDone, docFlow));


  const replaceBlank = (item) => { // this is to ensure grqty and iramount are never "" 
    if (item) return item;         // otherwise caneditPR will always return false
    return "0,000"
  };

  const canEditPR = (i: DocFlowItem) => {
    const canItBeEdited = i?.ExtReqNo === "EASY_REQUEST_PR" || i?.ExtReqNo.slice(0, 3) === "EPR" || i?.ExtReqNo === "";
    const isItZARB = (i.PoDocType || "").trim() === "ZARB";

    if (i.PR_AccCategory === "F") return false;

    if (showEditPrAndPoOptionOnChange)
      return (
        !isItZARB &&
        !isEmpty(i.PrNo) &&
        canItBeEdited &&
        (props.user.prChange || props.user.prCreate));
    else
      return (
        !isItZARB &&
        !isEmpty(i.PrNo) &&
        canItBeEdited && i.PR_Change === "" &&
        (props.user.prChange || props.user.prCreate));
  };

  const canEditPO = (i: DocFlowItem) => {
    if (showEditPrAndPoOptionOnChange)
      return (
        (i.PoDocType || "").trim().indexOf("ZARB") !== 0 &&
        !isEmpty(i.PoNo) &&
        !(
          !/^0+$/.test(i.GrQty.replace(/[,.]/g, "")) ||
          !/^0+$/.test(i.IrAmount.replace(/[,.]/g, ""))
        ) &&
        (props.user.poChange || props.user.poCreate) &&
        (ccEdit.some(cc => cc.id === i.CompCode) ||
          poEdit.some(po => po.id === i.PuchOrg))
      );
    else
      return (
        (i.PoDocType || "").trim().indexOf("ZARB") !== 0 &&
        !isEmpty(i.PoNo) &&
        !(
          !/^0+$/.test(i.GrQty.replace(/[,.]/g, "")) ||
          !/^0+$/.test(i.IrAmount.replace(/[,.]/g, ""))
        ) && i.po_change === "" &&
        (props.user.poChange || props.user.poCreate) &&
        (ccEdit.some(cc => cc.id === i.CompCode) ||
          poEdit.some(po => po.id === i.PuchOrg))
      );
  };

  const canClosePO = (i: DocFlowItem) => {
    return (
      (i.PoDocType || "").trim().indexOf("ZARB") !== 0 &&
      !isEmpty(i.PoNo) &&
      (props.user.poChange || props.user.poCreate) &&
      (ccEdit.some(cc => cc.id === i.CompCode) ||
        poEdit.some(po => po.id === i.PuchOrg))
    );
  };

  const canAccessEB = (i: DocFlowItem) => {
    return (i.PoDocType || "").trim().indexOf("ZARB") === 0;
  };

  const canAccessSapPr = (i: DocFlowItem) => {
    return !isEmpty(i.PrNo) && !!isEmpty(i.PoNo);
  };

  const reSubmitPr = async () => {
    const newItems = allLines?.map(item => ({
      PrNo: item?.PrNo,
      PrItem: item?.PrItem,
      IdNo: "D4",
    }));
    dispatch(new DocumentFlowFetchLoad()); //this is to trigger loading on whole doc table
    await reSubmitPRForOB(newItems);
    dispatch(new QueryActions.newTerm(null, df.SystemAlias));
  }

  const canAccessSapPo = (i: DocFlowItem) => {
    return !isEmpty(i.PoNo);
  };
  const prWorkFlow = "https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=a018221697bc91108996b31de053afe4&sysparm_domain_restore=false&sysparm_stack=no";
  const prOwnership = "https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=942db1e3973c19508996b31de053afc6&sysparm_domain_restore=false&sysparm_stack=no";
  const poReOpen = "https://nestle.service-now.com/sp?id=sc_cat_item&sys_id=513a17cb97acd1d0f92bffdce053afaa&sysparm_domain_restore=false&sysparm_stack=no";


  return (
    <>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 260,
            width: "auto"
          }
        }}
      >
        {sending ? (
          <PopupLoading text={"Updating..."} />
        ) : (
          <>
            {/* {dfItem && canEditPR(dfItem) && (
              <MenuItem key={"Edit PR"} onClick={handleEditPr}>
                Edit Request
              </MenuItem>
            )} */}
            {
              hasFeature(Features.RC_166717_DISABLE_EDIT_PR_PAGE_INPUTS) &&
              !!allLines?.filter(el => {
                let bool = el.PR_Change === 'X' ? true : false
                return bool
              }).length
              &&
              (
                <MenuItem key={"View More Details"} onClick={handleViewMore}>
                  View More Details
                </MenuItem>
              )
            }
            {
              !!allLines?.filter(el => {
                let bool = !!el.PR_Deleted ? el.PR_Deleted !== 'X' : true
                return bool
              }).length
              && canEditPR(allLines[0]) &&
              (
                <MenuItem key={"Edit PR2"} onClick={handleEditPr2}>
                  Edit Request
                </MenuItem>
              )}
            {df && df?.OB_Rej_Ind === "R" && hasFeature(Features.RC_163025_RESUBMIT_BUTTON) && (
              <MenuItem key={"Resubmit"} onClick={reSubmitPr}>
                Resubmit
              </MenuItem>
            )}
            {dfItem && canEditPO(dfItem) && (
              <MenuItem key={"Edit PO"} onClick={handleEditPo}>
                Edit PO
              </MenuItem>
            )}
            {/* {dfItem && canClosePO(dfItem) && (
              <MenuItem
                key={"Close PO"}
                onClick={() => handleClosePO(dfItem)}
                disabled={
                  dfItem.DelCompInd === "X" || dfItem.FinalInvInd === "X"
                }
              >
                Close Item/PO
              </MenuItem>
            )} */}
            {dfItem && canAccessSapPr(dfItem) && (
              <MenuItem
                key={"View in SAP"}
                onClick={async () => {
                  window.open(doWeNeedUrl(DocumentFlow.getCompanyCode(df)) ? newPrUrl : doWeNeedRteUrl(df.SystemAlias) ? newRtePRUrl : df.ViewPRSAPLink, "_blank");
                  await logUserInteractionData(UserInteractionType.ViewedInSAPRequestCockpitPR);
                }}
              >
                View in SAP
              </MenuItem>
            )}
            {dfItem && canAccessSapPo(dfItem) && (
              <MenuItem
                key={"View in SAP"}
                onClick={async () => {
                  window.open(doWeNeedUrl(DocumentFlow.getCompanyCode(df)) ? newPoUrl : doWeNeedRteUrl(df.SystemAlias) ? newRtePOUrl : df.ViewPOSAPLink, "_blank");
                  await logUserInteractionData(UserInteractionType.ViewedInSAPRequestCockpitPO);
                }}
              >
                View in SAP
              </MenuItem>
            )}
            {dfItem && canAccessEB(dfItem) && (
              <MenuItem
                key={"View in Easy Buy"}
                onClick={() => window.open(df.ViewPREBLink, "_blank")}
              >
                View in Easy Buy
              </MenuItem>
            )}
            {showNewUrls && dfItem && !isEmpty(dfItem.PrNo) && canAccessEB(dfItem) && !compCodes.includes(df.POCompanyCode) && (
              <MenuItem
                key={"Update PR Workflow"}
                onClick={async () => {
                  window.open(prWorkFlow, '_blank');
                  await DocumentFlowService.logUserInteractionData(UserInteractionType.UpdatePRWorkflow);
                }}
              >
                Update PR Workflow
              </MenuItem>
            )}
            {showNewUrls && dfItem && !isEmpty(dfItem.PrNo) && canAccessEB(dfItem) && (
              <MenuItem
                key={"Update PR Ownership"}
                onClick={async () => {
                  window.open(prOwnership, '_blank');
                  await DocumentFlowService.logUserInteractionData(UserInteractionType.UpdatePROwnership);
                }}
              >
                Update PR Ownership
              </MenuItem>
            )}
            {showNewUrls && dfItem && !isEmpty(dfItem.PoNo) && canAccessEB(dfItem) && (
              <MenuItem
                key={"Request PO Reopen/Closure"}
                onClick={async () => {
                  window.open(poReOpen, '_blank');
                  await DocumentFlowService.logUserInteractionData(UserInteractionType.RequestPOReopenOrClosure);
                }}
              >
                Request PO Reopen/Closure
              </MenuItem>
            )}
            {showNewUrls && dfItem && dfItem.MyGrConfUrl && (
              <MenuItem
                key={"Post GR in myGR (Confirm)"}
                disabled={!dfItem.MyGrConfUrl}
                onClick={async () => {
                  redirect(dfItem.MyGrConfUrl, true);
                  await logUserInteractionData(UserInteractionType.PostedGRinMyGRAppRequestCockpit);
                }}
              >
                {dfItem.MyGrConfLabel || "Post GR in myGR (Confirm)"}
              </MenuItem>
            )}
            {showNewUrls && dfItem && dfItem.MyGrExcUrl && (
              <MenuItem
                key={"Post GR in myGR (Exception)"}
                disabled={!dfItem.MyGrExcUrl}
                onClick={async () => {
                  redirect(dfItem.MyGrExcUrl, true);
                  await logUserInteractionData(UserInteractionType.PostedGRinMyGRExceptionRequestCockpit);
                }}
              >
                {dfItem.MyGrExcLabel || "Post GR in myGR (Exception)"}
              </MenuItem>
            )}
            {showNewUrls && dfItem && dfItem.MyGrComUrl && (
              <MenuItem
                key={"View GR in myGR"}
                disabled={!dfItem.MyGrComUrl}
                onClick={async () => {
                  redirect(dfItem.MyGrComUrl, true);
                  await logUserInteractionData(UserInteractionType.ViewedGRinMyGRAppRequestCockpit);
                }}
              >
                {dfItem.MyGrComLabel || "View GR in myGR"}
              </MenuItem>
            )}
            {showNewUrls && dfItem && dfItem.MigoLabel && (
              <MenuItem
                key={"Post GR in SAP"}
                disabled={!dfItem.MigoLabel}
                onClick={async () => {
                  redirect(dfItem.MigoUrl, true);
                  await logUserInteractionData(UserInteractionType.PostedGRInSAPRequestCockpit);
                }}
              >
                {dfItem.MigoLabel || "Post GR in SAP"}
              </MenuItem>
            )}
            {isBuyer && hasFeature(Features.MRP_FAIRMRKT_INITIATE_RFQ_168423_ITEM_LEVEL) && (
              <MenuItem
                key={"Initiate RFQ"}
                onClick={async () => {
                  await initialRfq({ prNo: df.PurchaseReqNo, itemNo: dfItem.PrItem });
                }}
              >
                {"Initiate RFQ"}
              </MenuItem>
            )}
          </>
        )}
      </Menu>
      {showEditPr && !isEmpty(dfItem.PrNo) && (
        <EditPrPopup
          requestChangeDocFlowItem={requestChangeDocFlowItem(df)}
          onClose={() => setShowEditPr(false)}
          dfItem={dfItem}
          df={df}
          onDone={handleOnDonePr}
        />
      )}

      {showEditPr2 && !isEmpty(dfItem.PrNo) && (
        // <EditPrPopup2
        //   requestChangeDocFlowItem={requestChangeDocFlowItem(df)}
        //   onClose={() => setShowEditPr2(false)}
        //   dfItem={dfItem}
        //   df={df}
        //   onDone={handleOnDonePr}
        // />


        //  use the Slider form PA and BA page
        <DrawerForMultiLineEdit
          anchor="right"
          open={showEditPr2}
          onClose={() => setShowEditPr2(false)}
        >
          <MultiLineEditExpandPage>
            <EditRequestAllLinesWrapper
              allLines={allLines.filter(el => {
                let bool = !!el.PR_Deleted ? el.PR_Deleted !== 'X' : true
                return bool
              })}
              df={df}
              onDone={handleOnDonePr2}
              onClose={() => setShowEditPr2(false)}
              disableEditPr={disableEditPr}
              zone={props.zone}
              vertexData={vertexDetails}
            />
          </MultiLineEditExpandPage>
        </DrawerForMultiLineEdit>

      )}

      {showEditPo && !isEmpty(dfItem.PoNo) && (
        <EditPoPopup
          saveDocFlowItem={saveDocFlowItem(df)}
          onClose={() => setShowEditPo(false)}
          dfItem={dfItem}
          onDone={handleOnDonePo}
        />
      )}
      {(isResubmitError || isInitiateRfqError) && <Portal ><Notification type={NotificationType.error} message={isResubmitError ? resubmitError.toString() : getErrorMessage(initiateRfqError)} /></Portal>}
      {(isResubmitSuccess || isInitiateRfqSuccess) && <Portal ><Notification type={NotificationType.success} message={isInitiateRfqSuccess ? `RFQ initiated sucessfully for PR ${df.PurchaseReqNo} and Item ${dfItem.PrItem}` : `PR ${df.PurchaseReqNo} Resubmitted Successfully`} /></Portal>}
    </>
  );
}

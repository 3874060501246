import React, { useEffect, useState } from 'react';
import { ValidityFilterDate } from 'src/app/shared/components/filters/filters';
import { CustomFastAsyncSelect, CustomFastDate, CustomFastInput, CustomFastSelect } from 'src/app/shared/components/form';
import { usePaymentTerms } from '../hooks/mulitlineEditPr.hooks';
import { AvatarWithPopover } from '../../AvatarWithPopover';
import DocumentFlow from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { newEditRequestPageStyles } from './newEditRequest/newEditRequestPageStyles';
import { DISPATCH_TYPES } from '../EditRequestAllLinesWrapper';
import { getCondtions } from '../helper';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { Features } from 'src/app/shared/domains/core/pataflag';
import { DateTime } from 'luxon';
import { Input, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { MasterService } from 'src/app/shared/domains/master/master.service';
import { NewStyledPaper, StyledIconButton, StyledInput, StyledPaper } from 'src/app/shared/components/search/search.styles';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';

export default function GeneralDetails({ docFlowHeader, values, dispatchMethod, setFinalResendPoData, finalResendPodata, setFinalTitle, finalTitle, zone, disbaleGeneralDetails = false }: { docFlowHeader: DocumentFlow, values: DocFlowItem, dispatchMethod: Function, setFinalResendPoData: Function, finalResendPodata: any, setFinalTitle: Function, finalTitle: any, zone: string, disbaleGeneralDetails?: boolean }) {
    const {
        VendorName: suppliers,
        VendorNo: supplierCode,
        PrCreatorFirstName: requestorFirstName,
        PrCreatorLastName: requestorLastName,
        PrCreatorId: requestorId,
        DocType: wayOfBuying,
        POCompanyCode: POCompanyCode,
    } = docFlowHeader
    const {
        CompCode,
        PurchGrp,
        Validity_Start,
        Validity_End,
        SystemAlias,
        PR_PaymentTerms,
        PO_PaymentTerms,
        PRCreatoremail,
        Title_resend: projectOrderTitle,
        Supplier: suppliersCode,
        SupplierTxt: supplierText,
        Requester_Resend: requestorChecked,
        Spender_Resend: spendorChecked,
        Supplier_Resend: suppliersSapData,
        Other_Resend: othersSapData,
        Hide_Price: hidePriceSapData,
    } = values
    const { paraWithMargin, generalDetailsItemsStyles, generalDetailsDropDownStyles } = newEditRequestPageStyles({});
    const { data: paymentTermsData } = usePaymentTerms(CompCode || POCompanyCode, SystemAlias);
    const incoTermOptions = values?.AllIncoterms_Data?.split(";")?.filter(item => !!item)?.map(el => ({ value: el, label: el })) || [];
    const invpartnerOptions = values?.PR_Inv_Partner_Data?.split(";")?.filter(item => !!item)?.map(el => ({ value: el, label: el })) || [];
    const orderingAddressOptions = values?.PR_Ordering_Add_Data?.split(";")?.filter(item => !!item)?.map(el => ({ value: el, label: el })) || [];
    const fieldLevelConditions = getCondtions(values);
    const { hasFeature } = React.useContext(FeaturesContext);

    function applyChange(value: any) {
        dispatchMethod({ type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS, payload: value })
    }

    const setPoSendingOptionsData = finalResendPodata.postData?.status ? { ...finalResendPodata.postData, Title_resend: projectOrderTitle } : {
        Requester_Resend: requestorChecked,
        Spender_Resend: spendorChecked,
        Supplier_Resend: suppliersSapData,
        Other_Resend: othersSapData,
        Hide_Price: hidePriceSapData,
    }

    useEffect(() => {
        if (projectOrderTitle !== finalTitle) {
            setFinalResendPoData({ "changedStatus": true, "postData": setPoSendingOptionsData })
        }
    }, [projectOrderTitle])

    const [incotermsDescriptionEnableEdit, setIncotermsDescriptionEnableEdit] = useState(true);
    const [oldIncotermsText, setOldText] = useState(values?.Incoterms_Text);

    return <>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: "30px" }}>

            {
                hasFeature(Features.RC_165281_NEW_FIELD_AND_SUPLIER_MODIFIED) &&
                <div className={generalDetailsItemsStyles}>
                    <p className={paraWithMargin}>Project/Order Title</p>
                    <CustomFastInput
                        disabled={disbaleGeneralDetails}
                        type={"text"}
                        initialValue={projectOrderTitle}
                        onChange={value => applyChange({ Title_resend: value, status: 'T' })}
                        style={null}
                    // onChange={e=>applyChange({ Title_resend: e.target.value, Status: 'T' })}
                    />
                    {/* <span><Input type="text" value={projectOrderTitle} onChange={(e)=>applyChange({ Title_resend: e.target.value })}/></span> */}
                </div>
            }

            {
                hasFeature(Features.RC_165281_NEW_FIELD_AND_SUPLIER_MODIFIED) ?
                    <div className={generalDetailsItemsStyles}>
                        <p className={paraWithMargin}>Suppliers</p>
                        <p>{suppliersCode + " - " + supplierText}</p>
                    </div>
                    :
                    <div className={generalDetailsItemsStyles}>
                        <p className={paraWithMargin}>Suppliers</p>
                        <p>{suppliers}</p>
                    </div>
            }

            {/* <div className={generalDetailsItemsStyles}>
                <p className={paraWithMargin}>Suppliers</p>
                <p>{suppliers}</p>
            </div> */}

            <div className={generalDetailsItemsStyles}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className={paraWithMargin} style={{ marginBottom: 12 }}>Requestor</p>
                    <AvatarWithPopover
                        item={{}}
                        firstName={requestorFirstName}
                        lastName={requestorLastName}
                        userId={requestorId}
                        customStyles={{ fontSize: 14, width: 32, height: 32 }}
                        email={PRCreatoremail}
                    />
                </div>
            </div>

            <div className={generalDetailsItemsStyles}>
                <p className={paraWithMargin}>Way of Buying</p>
                <p>{wayOfBuying}</p>
            </div>
            {hasFeature(Features.RC_166203_NEW_GENERAL_DETAILS_FIELDS) && fieldLevelConditions.isTherePO && <div className={generalDetailsDropDownStyles} style={{ width: "300px" }}>
                <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>Ordering Address</p>
                <CustomFastSelect
                    disabled={hasFeature(Features.RC_DISABLING_GENERAL_DETAIL_FIELDS) ? !fieldLevelConditions.isItEasyRequestPR : true}
                    menuPortalTarget={document.body}
                    isMulti={false}
                    clearable={false}
                    customDisplay={option => option.value}
                    initialValue={{ value: values?.PR_Ordering_Add }}
                    options={orderingAddressOptions}
                    onChange={value => {
                        let val = value ? value.value : ''
                        applyChange({ PR_Ordering_Add: val })
                    }} />
            </div>}
            {!hasFeature(Features.RC_166206_INCOTERMS) && <div className={generalDetailsItemsStyles}>
                <p className={paraWithMargin}>Purchasing Group</p>
                <p>{PurchGrp}</p>
            </div>}

            {hasFeature(Features.RC_166206_INCOTERMS) && <div className={generalDetailsDropDownStyles}>
                <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>Purchasing Group</p>
                <CustomFastAsyncSelect
                    disabled={hasFeature(Features.RC_DISABLING_GENERAL_DETAIL_FIELDS) ? !fieldLevelConditions.isItEasyRequestPR : true}
                    onLoadLimit={3}
                    clearable={false}
                    onLoad={x => MasterService.fetchPurchaseGroup(x.trim(), zone)}
                    customDisplay={option => option.value}
                    initialValue={{ value: PurchGrp }}
                    onChange={value => {
                        let val = value ? value.value : ''
                        applyChange({ PurchGrp: val })
                    }} />

            </div>}

            {fieldLevelConditions.isItEasyRequestPR && fieldLevelConditions.isItFO && <div
                className={generalDetailsItemsStyles}
                style={{ width: 200 }}>
                <p className={paraWithMargin} style={{ marginBottom: 7 }}>Validity Start</p>
                <ValidityFilterDate
                    format="dd/MM/yyyy"
                    label=''
                    source={Validity_Start}
                    maxDate={Validity_End}
                    onChange={selected => {
                        console.log(selected)
                        applyChange({ Validity_Start: selected })
                    }} />
            </div>}

            {fieldLevelConditions.isItEasyRequestPR && fieldLevelConditions.isItFO && <div
                className={generalDetailsItemsStyles}
                style={{ width: 200 }}>
                <p className={paraWithMargin} style={{ marginBottom: 7 }}>Validity End</p>
                <ValidityFilterDate
                    format="dd/MM/yyyy"
                    label=''
                    source={Validity_End}
                    minDate={Validity_Start}
                    onChange={selected => {
                        applyChange({ Validity_End: selected })
                    }} />

                {/* <CustomFastDate
                    initialValue={DateTime.fromFormat(
                        Validity_End,
                        "yyyyMMdd"
                    ).toISO()}
                    onChange={selected => applyChange({ Validity_End: selected })}
                /> */}
            </div>}

            {fieldLevelConditions.isItEasyRequestPR && <div className={generalDetailsDropDownStyles}>
                <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>Payment Terms</p>
                <CustomFastSelect
                    disabled={disbaleGeneralDetails}
                    menuPortalTarget={document.body}
                    isMulti={false}
                    clearable={false}
                    customDisplay={option => option.value}
                    initialValue={{ value: PR_PaymentTerms || PO_PaymentTerms }}
                    options={!!paymentTermsData ? paymentTermsData : []}
                    onChange={value => {
                        let val = value ? value.value : ''
                        applyChange({ PR_PaymentTerms: val })
                    }} />
            </div>}
            {hasFeature(Features.RC_166203_NEW_GENERAL_DETAILS_FIELDS) && fieldLevelConditions.isTherePO && <div className={generalDetailsDropDownStyles} style={{ width: "300px" }}>
                <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>Invoice Partner</p>
                <CustomFastSelect
                    disabled={hasFeature(Features.RC_DISABLING_GENERAL_DETAIL_FIELDS) ? !fieldLevelConditions.isItEasyRequestPR : true}
                    menuPortalTarget={document.body}
                    isMulti={false}
                    clearable={false}
                    customDisplay={option => option.value}
                    initialValue={{ value: values?.PR_Inv_Partner }}
                    options={invpartnerOptions}
                    onChange={value => {
                        let val = value ? value.value : ''
                        applyChange({ PR_Inv_Partner: val })
                    }} />
            </div>}

            {
                hasFeature(Features.RC_166206_INCOTERMS) &&
                    hasFeature(Features.RC_169620_SHOW_INCOTERMS_EDIT_DESCRIPTION) ?
                    <>
                        <div className={generalDetailsDropDownStyles} style={{ width: "305px" }}>
                            <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>IncoTerms</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: "29%" }}>
                                    <CustomFastSelect
                                        disabled={!fieldLevelConditions.isItEasyRequestPR}
                                        menuPortalTarget={document.body}
                                        isMulti={false}
                                        clearable={false}
                                        customDisplay={option => option.value.substr(0, 3)}
                                        initialValue={{ value: values?.Incoterms_Text }}
                                        options={incoTermOptions}
                                        onChange={value => {
                                            if (value && value.value) {
                                                if (value.value.substr(0, 3) === oldIncotermsText.substr(0, 3))
                                                    applyChange({ Incoterms_Text: oldIncotermsText, status: 'T' })
                                                else
                                                    applyChange({ Incoterms_Text: value.value, status: 'T' })
                                            }
                                            // let val = value ? value.value : ''
                                            // applyChange({ Incoterms_Text: val, status: 'T' })
                                        }}
                                    />
                                </div>
                                <div style={{ width: "71%" }}>
                                    <NewStyledPaper elevation={0}>
                                        <StyledInput
                                            style={{ fontSize: "14px" }}
                                            disabled={incotermsDescriptionEnableEdit}
                                            inputProps={{ 'aria-label': 'Edit...' }}
                                            value={values?.Incoterms_Text.length > 28 ? values?.Incoterms_Text.substring(4).substr(0, 28) : values?.Incoterms_Text.substring(4)}
                                            onChange={e => e.target.value.length <= 28 && applyChange({ Incoterms_Text: values?.Incoterms_Text.substr(0, 3) + " " + e.target.value, status: 'T' })}
                                        />
                                        <Tooltip title={
                                            "Enable Edit"
                                        }>
                                            <StyledIconButton aria-label="Search" disabled={!fieldLevelConditions.isItEasyRequestPR}>
                                                <EditIcon onClick={() => setIncotermsDescriptionEnableEdit(false)} />
                                            </StyledIconButton>
                                        </Tooltip>
                                    </NewStyledPaper>
                                </div>
                            </div>
                            {values?.Incoterms_Text.substring(4).length >= 28 && <div style={{ display: 'flex', justifyContent: 'end', fontStyle: 'italic', color: '#837b6d', fontWeight: 'bold', fontSize: '12px' }}>Max limit 28 reached</div>}
                        </div>
                    </>
                    :
                    <div className={generalDetailsDropDownStyles} style={{ width: "300px" }}>
                        <p className={paraWithMargin} style={{ marginBottom: 12, color: 'rgb(124, 119, 119)' }}>IncoTerms</p>
                        <CustomFastSelect
                            disabled={!fieldLevelConditions.isItEasyRequestPR}
                            menuPortalTarget={document.body}
                            isMulti={false}
                            clearable={false}
                            customDisplay={option => option.value}
                            initialValue={{ value: values?.Incoterms_Text }}
                            options={incoTermOptions}
                            onChange={value => {
                                let val = value ? value.value : ''
                                applyChange({ Incoterms_Text: val, status: 'T' })
                            }}
                        />
                    </div>
            }
        </div >
    </>
}
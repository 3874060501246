import React from "react";
import { useReducer, SetStateAction, useEffect } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { withContext } from "../../../shared/services/context.service";
import { FeedbackDetails, FeedbackDialogContent, StyledFeedbackForm, StyledEditPrDialog } from "../../../shared/containers/dashboard/components/feedback/feedback.styles";
import Button from "@material-ui/core/Button";
import { RootStateStore } from "../../../application.reducers";
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from "../../../shared/components/layout/styles";
import { PopupLoading } from "../../../shared/components/loading";
import { DocFlowItem } from "../../domains/prpo/poItem";
import { MasterService } from "../../../shared/domains/master/master.service";
import { isEmpty } from "ramda";
import { StyledTabs, StyledTab } from "../../../shared/components/layout/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FeaturesContext } from "../../../shared/domains/core/pataflag.context";
import { Features } from "../../../shared/domains/core/pataflag";
import { Invoice } from "../../../shared/domains/master/invoice";
import { useDispatch } from "react-redux";
import { PrItem } from './PrItem';
import { NewPrItem } from './NewPrItem';
import { PoSendingOptions } from './PoSendingOptions';
import { Grid, MuiThemeProvider, Tooltip } from "@material-ui/core";
import { useStylesForToolTip } from "src/app/shared/components/tooltip/toolTip.styles";
import { NewPrItem2 } from "./NewPrItem2";
import { SettingsTheme } from "src/app/shared/containers/dashboard/components/addCompanyCode/addCompanyCode.styles";
import { dateTimeFromFormat } from "src/app/shared/utils/global";
import { DocumentFlowIdGenerator } from "../../domains/documentFlow/documentFlow";
import AttachmentsAndCommentsSection from "./AttachmentsAndCommentsSection";
import { getType } from "../dataTable/docFlow/docFlowTableRelated/CbLink";
import { PriceService2 } from "src/app/shared/services/price.service";
import { getLabel } from "../complianceIndicator";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export enum ExceptionEnum {
  EXCEPTION = 'Exception'
}

export enum ExceptionalTypeEnum {
  CONTRACT_IN_PLACE = "Contract in place",
  SINGLE_SOURCE = "Single Source",
  EMERGENCY_CASE = "Emergency case"
}

export const topicEnum = {

}


export const initialTopicValues = [
  { value: "Offer no. 1 (selected supplier)", label: "Offer no. 1 (selected supplier)" },
  { value: "Offer no. 2", label: "Offer no. 2" },
  { value: "Offer no. 3", label: "Offer no. 3" },
  { value: "Event summary", label: "Event summary" },
]

export const diff = function (obj1, obj2) {

  if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
    return obj1;
  }
  // console.log("old :" + JSON.stringify(obj1));
  // console.log("New :" + JSON.stringify(obj2));
  var diffs = {};
  var key;

  const arraysMatch = function (arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  const compare = function (item1, item2, key) {
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    if (type2 === "[object Undefined]") {
      diffs[key] = null;
      return;
    }

    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    if (type1 === "[object Object]") {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    if (type1 === "[object Array]") {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    if (type1 === "[object Function]") {
      if (item1?.toString() !== item2?.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }
  };

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  return diffs;
};

function isItFoFun(values) {
  return values.PoDocType === "FO";
}

function getTotalValState(allItems) {
  const isItFO = isItFoFun(allItems[0])
  // check US - 157625 for the conditions 
  // PoDocType in all items will be same
  let sum = 0;
  let temp = isItFO ? 'PrPriceRaw' : 'PrNetPriceRaw'
  allItems.forEach(el => {
    sum = sum + (Number(el[temp].replace(',', '.').replace(/ /g, '')) * Number(el.PrQuantityRaw.replace(/ /g, '').replace(',', '.'))) / (Number(el.PrNetPriceUnit))
  })

  return sum;

}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CBBasedOnSupplierStatusFun(values) {

  if (values.ExtReqNo !== "EASY_REQUEST_PR" || values.ExtReqNo.slice(0,3) !== "EPR")
    return false;

  if (values.Supplier_Status?.trim() === 'Mandatory' || values.Supplier_Status?.trim() === 'Sole Source') {
    return false;
  }

  return true;
}


const EditItemContent = ({ onClose, onSubmit, dfItem, df }) => {
  const dispatch = useDispatch();
  const [enableSave, setEnableSave] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [values, setValues]: [any, any] = useReducer(R.mergeDeepRight, dfItem);
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const { hasFeature } = React.useContext(FeaturesContext);
  const [files, setFiles] = React.useState([]);
  const [showDelete, setShowDelete] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const sending: boolean = useSelector(
    (state: RootStateStore) => state.docFlows.documentFlows.data.onUpdateLoading
  );
  const classes = useStylesForToolTip({});


  //////////// below states are for Compititive Bidding(Attachments & comments Section)
  const [exceptionSelected, setExceptionSelected] = React.useState(false);
  const [topicValues, setTopicValues] = React.useState(initialTopicValues);
  const [exceptionalType, setExceptionalType] = React.useState(null);
  const allItems = useSelector(
    (state: RootStateStore) => state.docFlows.documentFlows.data.documentFlowsItems[DocumentFlowIdGenerator(df)].items);

  const [filesWithTopics, setFilesWithTopics] = React.useState([]);
  const [totalVal] = React.useState(() => getTotalValState(allItems));
  const [totalValDynamic, setTotalValDynamic] = React.useState(totalVal);
  const [showCompetitiveBidding, setShowCompetitiveBidding] = React.useState(false);
  const [showCBbasedOnSupplierStatus] = React.useState(() => CBBasedOnSupplierStatusFun(values));
  const [complianceIndicatorState] = React.useState(() => getLabel(getType(df.CollectiveNumber, df.CompetitiveBiddingIdInAzure, df.IsCbActive)))
  const [currencyConversionValue, setCurrencyConversionValue] = React.useState(1);
  const [currencyWarning, setCurrencyWarning] = React.useState<string>('')

  /////////////////////////////////////////////////////////////////////////////////////

  function totalChangeFn(lineItem, itemTotalvalue) {

    const otherItems = allItems.filter(el => {
      return el.PrItem.replace(/^0+/, "") !== lineItem
    })

    let sumOfOtherItemsTotalVals = otherItems.length ? getTotalValState(otherItems) : 0;
    setTotalValDynamic(itemTotalvalue + sumOfOtherItemsTotalVals)

    if (!showCBbasedOnSupplierStatus) return;

    if ((totalVal * currencyConversionValue) > Number(dfItem.Threshold_value?.trim())) return

    if (totalVal === itemTotalvalue + sumOfOtherItemsTotalVals) {
      setShowCompetitiveBidding(false);
      return;
    }

    setShowCompetitiveBidding(!!(
      ((itemTotalvalue + sumOfOtherItemsTotalVals) * currencyConversionValue) > Number(dfItem.Threshold_value?.trim())))
  }

  async function fetchCurrencyConversion(thresholdCurr, prCurrency) {
    // convert amount from prCurrency to threshold currency
    let amount = 1000;
    if (!showCBbasedOnSupplierStatus || (values.Threshold_currency === values.PrCurrency)) return;

    const result = await PriceService2.convertPrice(amount, prCurrency, thresholdCurr)

    if (!result.ToCurrency && !result.ToAmount) {
      // show some warning msg... like selected currency is unable to convert to 
      // threshold currency (EUR), Kindly change the currency or else system will consider 
      // default currency as threshold currency.
      console.log('Currency error')
      setCurrencyConversionValue(1);
      setCurrencyWarning(`Currency conversion failed for ${prCurrency}: System will consider only value without currency conversion`)
      return
    };

    setCurrencyConversionValue(Number(result.ToAmount?.trim()) / amount)
    setCurrencyWarning('')
    console.log(result)
  }

  useEffect(() => {
    async function fetchInvoices() {
      const response: SetStateAction<Invoice[]> = await MasterService.fetchInvoices(
        values.PrNo,
        'BUS2105',
        values.SystemAlias
      );
      setInvoices(response);
    }

    fetchCurrencyConversion(values.Threshold_currency, values.PrCurrency)
    fetchInvoices();
  }, []);

  const convertDate = (date) => {
    return dateTimeFromFormat(date).toFormat('yyyy-MM-dd')
  }


  function justToicsArr(filesWithTopics) {
    let topicsArr = filesWithTopics.map(el => {

      if (el.topic?.value) return el.topic.value;

      return el.topic;
    })
    return topicsArr
  }

  function areAllTopicsAreValid() {
    // case 1 - atleast one file is selected, which has isCB = true;

    if (!filesWithTopics.length) {
      setErrorMsg(`You need to have either an attachment with "Event Summary" as a topic, or three attachments with Offer no. 1, 2, and 3 as topics`);
      setValue(2)
      return false;
    }

    // case 2 - checking for exception case

    // if exception is selected, automatically for all files topic will be assigned as exception,    

    if (exceptionSelected) {
      // checking for if exception Type is selected

      if (exceptionalType) {
        setErrorMsg('')
        return true;
      }
      else {
        setErrorMsg('Kindly select Exception Type');
        setValue(2);
        return false;
      }
    }


    // case 3 - If filesWithTopics has length > 0

    const topicsArr = justToicsArr(filesWithTopics);

    if (filesWithTopics.length) {
      // if any file contains topic as null
      if (topicsArr.includes(null)) {
        setErrorMsg('All attached files must have the topic value assigned');
        setValue(2)
        return false;
      }

      // check for if the selected topics contains Event Summary

      if (topicsArr.includes('Event summary')) {
        setErrorMsg('');
        return true;
      }

      // check for offer no 1, offer no 2, offer no3

      if (topicsArr.includes('Offer no. 1 (selected supplier)') &&
        topicsArr.includes("Offer no. 2") &&
        topicsArr.includes("Offer no. 3")
      ) {
        setErrorMsg('');
        return true;
      }

    }



    setErrorMsg(`You need to have either an attachment with "Event Summary" as a topic, or three attachments with Offer no. 1, 2, and 3 as topics`);
    setValue(2)
    return false;

  }

  const handleSubmit = (e, isItDelete: boolean = false) => {
    e.preventDefault();
    const formData = new FormData();
    if (files.length) {
      files.forEach((file, i) => formData.append("Files", file, file.name))
    }

    const TotalPOAmount =
      (showCompetitiveBidding && !currencyWarning)
        && (values.PrCurrency?.trim() !== values.Threshold_currency?.trim()) ?
        `${Number(totalValDynamic.toFixed(2))} ${values.PrCurrency} (${Number(totalValDynamic * currencyConversionValue).toFixed(2)} ${values.Threshold_currency})` :
        `${Number(totalValDynamic.toFixed(2))} ${values.PrCurrency}`

    const SupplierStatus = values.Supplier_Status?.trim();
    const IsCBRequired = showCompetitiveBidding;
    const CCIndicator = df.CollectiveNumber ? `${df.CollectiveNumber} - ${complianceIndicatorState}` : `Unknown`;
    const IsEasyRequest = values.ExtReqNo === "EASY_REQUEST_PR" || values.ExtReqNo.slice(0,3) === "EPR"

    let newValues = {
      ...values,
      TotalPOAmount: TotalPOAmount,
      SupplierStatus: SupplierStatus,
      IsCBRequired: IsCBRequired,
      CCIndicator: CCIndicator,
      IsEasyRequest: IsEasyRequest
    }

    if (showCompetitiveBidding) {
      const isAlltopicsAreValid = areAllTopicsAreValid()
      if (!isAlltopicsAreValid) {
        return;
      }
      else {
        filesWithTopics.forEach(el => {
          formData.append('Files', el.file, el.fileName)
        });
        let CBFileData = {};
        CBFileData['exceptionalType'] = exceptionalType;
        CBFileData['files'] = filesWithTopics.map(el => {
          return {
            // isCB: el.isCB,
            fileName: el.fileName,
            uniqId: el.uniqId,
            topic: el.topic.value
          }
        });
        newValues = { ...newValues, CBFileData: CBFileData }
      }
    }
    if (!!values.ExtReqNo) {
      newValues = { ...newValues, POCreatoremail: '' }
    }
    onSubmit(isItDelete ? { ...newValues, isToBeDeleted: "X" } : newValues, formData, totalVal, totalValDynamic, currencyConversionValue);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {sending ? (
        <PopupLoading text={"Updating..."} />
      ) : (
        <FeedbackDialogContent>
          <FeedbackDetails>
            {!isEmpty(values.PoNo) && <StyledTabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="Horizontal tabs"
            >
              <StyledTab label="Item" {...a11yProps(0)} />
              <StyledTab label="PO sending options" {...a11yProps(1)} />
              <StyledTab label="ATTACHMENTS & COMMENTS" {...a11yProps(2)} />
            </StyledTabs>

            }
            <TabPanel value={value} index={0}>
              <NewPrItem2
                values={values} df={df} setValues={setValues}
                setEnableSave={setEnableSave}
                dfItem={dfItem}
                totalChangeFn={totalChangeFn}
                setErrorMsg={setErrorMsg}
                currencyConversionValue={currencyConversionValue}
                fetchCurrencyConversion={fetchCurrencyConversion}
              />
            </TabPanel>
            {!isEmpty(values.PoNo) &&
              <TabPanel value={value} index={1}>
                <PoSendingOptions invoices={invoices} open={open} setOpen={setOpen} values={values} setValues={setValues} setEnableSave={setEnableSave} files={files} setFiles={setFiles} />
              </TabPanel>}

            {/* Attachments and comments section */}

            {
              <TabPanel value={value} index={2}>
                <AttachmentsAndCommentsSection
                  values={values}
                  setValues={setValues}
                  setEnableSave={setEnableSave}
                  files={files}
                  setFiles={setFiles}
                  showCompetitiveBidding={showCompetitiveBidding}
                  exceptionSelected={exceptionSelected}
                  setExceptionSelected={setExceptionSelected}
                  exceptionalType={exceptionalType}
                  setExceptionalType={setExceptionalType}
                  topicValues={topicValues}
                  setTopicValues={setTopicValues}
                  filesWithTopics={filesWithTopics}
                  setFilesWithTopics={setFilesWithTopics}
                  totalValDynamic={totalValDynamic}
                  invoices={invoices}
                  setErrorMsg={setErrorMsg}
                  currencyConversionValue={currencyConversionValue}
                  currencyWarning={currencyWarning}
                />
              </TabPanel>
            }

          </FeedbackDetails>
        </FeedbackDialogContent>
      )}

      {errorMsg ? <span style={{ color: 'red', display: 'flex', paddingTop: 20, justifyContent: 'center' }}>{errorMsg}</span> : null}
      {(currencyWarning && showCompetitiveBidding) ? <span style={{ color: '#F67280', display: 'flex', paddingTop: 20, justifyContent: 'center' }}>{currencyWarning}</span> : null}

      {!sending && (
        <StyledDialogActions>
          <Tooltip
            classes={{ tooltip: classes.customTooltip }}
            title={enableSave ? "" : "Kindly make changes to enable Save button"}
            interactive
            placement='bottom-end'
          >
            <div>
              <Button
                color="primary"
                type="submit"
                disabled={!enableSave}
                onClick={(e) => handleSubmit(e, false)}
              >
                Save
              </Button></div>
          </Tooltip>
          <Button color="primary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => setShowDelete(true)}
          >
            Delete
          </Button>
        </StyledDialogActions>
      )}
      {
        <StyledDialog open={showDelete} maxWidth={"xs"} scroll={'paper'} fullWidth={true}>
          <MuiThemeProvider theme={SettingsTheme}>
            <StyledDialogTitle>Delete Item</StyledDialogTitle>
            <StyledDialogContent dividers={true}>
              <Grid container>
                <span style={{ fontSize: '1rem' }}>Caution! The selected item will be deleted.</span>
              </Grid>
            </StyledDialogContent>
            <StyledDialogActions>
              <Button color="primary" type="submit" onClick={(e) => handleSubmit(e, true)}> Delete</Button>
              <Button color="primary" onClick={() => setShowDelete(false)}> Cancel</Button>
            </StyledDialogActions>
          </MuiThemeProvider>
        </StyledDialog>
      }
    </>
  );
};


export const EditPrPopup = ({
  onClose,
  dfItem,
  df,
  requestChangeDocFlowItem,
  onDone
}) => {
  const docFlowItem: DocFlowItem = {
    ...dfItem
  };
  const { hasFeature } = React.useContext(FeaturesContext);


  const checkIfEmailIsNeeded = (filesUploaded: any, data: any, totalVal: number, totalValDynamic: number, currencyConversionValue: number) => {
    // this function is to ensure email is sent only on certain conditions
    // if it returns false then no email will be sent
    if (!data.IsEasyRequest) { return true }; // this func is valid only for easy request PRs

    if (filesUploaded.length > 0) { return true }; // if files are uploaded send emails by default

    // if its easy pr deletion then no email to be sent
    if (data?.isToBeDeleted === "X") {
      return  false
    };

    if (data.SendPDF === "X") return true;  // send email if po sending options have been changed

    const propertiesToIgnore = ["SupplierStatus", "TotalPOAmount", "IsCBRequired", "CCIndicator", "IsEasyRequest", "CBFileData"];
    const tempValues = { ...data };
    propertiesToIgnore.forEach(val => delete tempValues[val]);
    const tempData = Object.keys(tempValues);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // this section is to check if only total amount related fields - Quantity, Price, Per Unit were changed
    // if so, and if total value has decreased then dontsend the email
    const fieldsAffectingTotalAmount = ["PrQuantityRaw", "PrPriceRaw", "PrNetPriceRaw", "PrNetPriceUnit"];
    let tempValuesToPopOut = [...tempData];
    fieldsAffectingTotalAmount.forEach(item => {
      tempValuesToPopOut = tempValuesToPopOut.filter(el => el !== item);
    })
    if ((tempValuesToPopOut.length === 0) &&
      ((totalValDynamic * currencyConversionValue) < (totalVal * currencyConversionValue))
    ) { return false };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // email is skipped if no change is made to the below fields in the requests
    const fieldsToSendEmail = ["PrQuantityRaw", "PrPriceRaw", "PrNetPriceRaw", "PrCurrency", "PrNetPriceUnit", "Pr_wbsid", "Pr_CostCenter", "Pr_GlAccount", "PR_PROFIT", "Textsupplier"];
    let shouleEmailBeSent: boolean = false; // it will be enabled if any change is made to any field within fieldsToSendEmail array

    tempData.forEach(item => {
      if (fieldsToSendEmail.filter(el => el === item).length > 0) { shouleEmailBeSent = true };
    })
    if (!shouleEmailBeSent) { return false };

    return true;
  };

  const removeNullValues = (data) => {
    let tempData = { ...data };
    Object.keys(tempData).forEach(key => {
      if (tempData[key] == null) { // this will remove all values which are null and undefined 
        delete tempData[key];
      }
    })
    return tempData;
  }

  const handleSubmit = (docflowItem: DocFlowItem, formData: FormData,
    totalVal: number, totalValDynamic: number, currencyConversionValue: number) => {
    const tempData = removeNullValues(diff(dfItem, docflowItem));
    const filesUploaded = formData?.getAll("Files");
    return new Promise((resolve, reject) =>
      requestChangeDocFlowItem(
        {
          resolve,
          reject
        },
        {
          ...tempData,
          PrNo: dfItem.PrNo,
          PrItem: dfItem.PrItem,
          SystemAlias: dfItem.SystemAlias
        },
        onDone,
        dfItem,
        !isEmpty(dfItem.PoNo) && checkIfEmailIsNeeded(filesUploaded, tempData, totalVal, totalValDynamic, currencyConversionValue),
        docflowItem,
        formData
      )
    )
  };

  const handleClose = () => {
    onClose();
  };

  const EditItemForm = withContext(EditItemContent);

  return (
    <StyledEditPrDialog
      open={true}
      maxWidth={"xs"}
      scroll={"paper"}
      fullWidth={true}
    >
      <StyledFeedbackForm>
        <StyledDialogTitle>
          Edit SAP Request #{dfItem.PrNo} - Line: {dfItem.PrItem.replace(/^0+/, "")}
        </StyledDialogTitle>
        <EditItemForm
          onClose={handleClose}
          onSubmit={handleSubmit}
          dfItem={docFlowItem}
          df={df}
        />
      </StyledFeedbackForm>
    </StyledEditPrDialog>
  );
};

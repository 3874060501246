
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialFromPslTable } from './materialFromPslTable';
import { ApplyButton } from 'src/app/feedbackCockpit/components/styles';
import DocumentFlow from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { getAddMatFromPslDefaultFilters, getCondtions, removeDecimal } from '../../helper';
import { DISPATCH_TYPES } from '../../EditRequestAllLinesWrapper';
import { useFetchPslMaterial } from 'src/app/docFlows/hooks/docflow.hooks';
import { StyledConfirmDialog } from 'src/app/shared/containers/dashboard/components/feedback/feedback.styles';
import { StyledDialogTitle, StyledDrawer, Toolbar } from 'src/app/shared/components/layout/styles';
import { DialogActions, Portal } from '@material-ui/core';
import Notification, { NotificationType } from "src/app/shared/components/notification";
import { StyledIconButton, StyledInput, StyledPaper } from 'src/app/shared/components/search/search.styles';
import SearchIcon from '@material-ui/icons/Search';
import { FilterButton } from 'src/app/shared/components/button';
import AddMaterialFromPslFiltersPage, { AddMaterialFromPslActiveFilters } from './addMaterialFromPslFilters';
import { NbsEasyRequestActiveFilters } from 'src/app/nbsCockpit/containers/nbsCockpitEasyRequestFilters.page';

type addMaterialFromPslProps = {
    currencyDecimals: any;
    lineItems: DocFlowItem[];
    documentFlow: DocumentFlow;
    openChatSearch: boolean;
    setOpenChatSearch: Function;
    dispatchMethod: any;
};

export const AddMaterialFromPsl = (props: addMaterialFromPslProps) => {
    const { lineItems, documentFlow, setOpenChatSearch, dispatchMethod } = props;

    const firstLineItem = lineItems[0];
    const lastLineItem = lineItems[props.lineItems?.length - 1];
    const fieldLevelConditions = getCondtions(firstLineItem);

    const [materialsAdded, setMaterialsAdded] = React.useState<any>([]);
    const [rows, setRows] = React.useState<number>(5);
    const [page, setPage] = React.useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [showFilters, toggleFilters] = React.useState<boolean>(false);
    const compCode = [{ title: "", id: firstLineItem?.CompCode || props.documentFlow.POCompanyCode }];

    const vendorList: { title: string, id: string }[] = [
        { title: documentFlow.VendorName, id: documentFlow.VendorNo },
        { title: "Supplier Not Defined", id: "999999999" }
    ];

    const plantList: { title: string, id: string }[] = firstLineItem?.Plant ? [{ title: firstLineItem?.PlantTxt, id: firstLineItem?.Plant },
    ] : [];

    const filtersData = getAddMatFromPslDefaultFilters(vendorList, plantList);

    const [addMatPslQueryObject, setAddMatPslQueryObject] = React.useState({
        filters: filtersData,
        page: page,
        top: rows,
        searchTerm: searchValue,
        compCode: compCode[0]?.id
    });

    function handleClose() {
        setOpenChatSearch(false)
    };

    const { data: addMatFromPslData, isLoading, error, isError } = useFetchPslMaterial(addMatPslQueryObject);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setAddMatPslQueryObject(prevState => ({
            ...prevState,
            page: newPage,
        }));

    };
    const onRowsChange = (currentPage: number, newRows: number) => {
        setRows(newRows);
        setAddMatPslQueryObject(prevState => ({
            ...prevState,
            top: newRows,
        }));
    };

    const handleCopy = (item: any) => {
        let tempQty = fieldLevelConditions.isItFO ? 1 : 0;
        let finalPrice = item.PrPriceRaw || 0;
        let finalTempNetPriceUnit = item.PrNetPriceUnit || 0;
        const newItem = { ...item }

        if (item?.PrCurrency) {
            const tempDecimal: { currency: string, decimal: string } = props.currencyDecimals?.filter(curr => curr.currency === item?.PrCurrency)[0];
            const decimal = tempDecimal?.decimal ? parseInt(tempDecimal?.decimal) : null;
            finalPrice = removeDecimal(item.PrPriceRaw, decimal);
            finalTempNetPriceUnit = removeDecimal(item.PrNetPriceUnit, decimal);
        };

        const newTotal: number = isNaN((finalPrice * tempQty) / finalTempNetPriceUnit) ? 0 : (finalPrice * tempQty) / finalTempNetPriceUnit;
        delete item["status"]; // this is to avoid sending supplier status to sap
        const payload: any = {
            ...lastLineItem,
            ...item,
            CopiedItemNo: lastLineItem.PrItem,
            PrCurrency: lastLineItem.PrCurrency,
            PrPrice: newTotal,
            PrRequisitioner: documentFlow.Requisitioner,
            PrReqFname: documentFlow.Reqfnam,
            PrReqLname: documentFlow.Reqlnam,
            PrDeliveryDate: "",
            PrQuantityRaw: fieldLevelConditions.isItFO ? 1 : "",
            Taxcode: "",
            isItCopiedLineItem: true,
            isItAddedFromPsl: true,
            isSupplierStatusMandatoryOrSoleSource: false,
            Supplier: item.vendorNo === "999999999" ? documentFlow.VendorNo : item.vendorNo?.padStart(10, '0'),
            GrQty: "0.000",
            IrAmount: "0.000",
        };
        if (newItem.status === 'Mandatory' || newItem.status === 'SoleSource') {
            payload["isSupplierStatusMandatoryOrSoleSource"] = true;
        }
        if (fieldLevelConditions.isItFO) {
            payload["PrPriceRaw"] = finalPrice;
            delete payload["PrNetPriceRaw"]
        } else {
            payload["PrNetPriceRaw"] = finalPrice;
            delete payload["PrPriceRaw"]
        };
        dispatchMethod({
            type: DISPATCH_TYPES.ADD_NEW_ITEM_LINE,
            payload: payload
        })
    };

    const handleAddMaterialFromPsl = () => {
        handleClose();
        const tempData = materialsAdded.map(item => {
            delete item["tableData"];
            return item
        });
        tempData.forEach(item => {
            handleCopy(item)
        });
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter' || e.target.value === "") {
            setSearchValue(e.target.value);
            setAddMatPslQueryObject(prevState => ({
                ...prevState,
                searchTerm: e.target.value,
            }));
        }
    };

    const handleSearch = () => {
        setAddMatPslQueryObject(prevState => ({
            ...prevState,
            searchTerm: searchValue,
        }));
    };

    function toggleFiltersDrawer(open: boolean, toggle: (boolean) => void): (event: React.KeyboardEvent | React.MouseEvent) => any {
        return (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
                return;
            }
            toggle(open);
        }
    }

    async function updateAddMatFromPslFilters(filters: any) {
        setAddMatPslQueryObject(prevState => ({
            ...prevState,
            filters: filters,
            orderBy: [],
            page: 0,
            top: 5,
        }));
        toggleFilters(false);
    }

    return <StyledConfirmDialog open={true} maxWidth={"lg"} scroll={'paper'} fullWidth={true}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: "orange" }}>
                <StyledDialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <span style={{ color: 'white' }}>Add New Line From PSL</span>
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <StyledPaper elevation={0} style={{ borderRadius: "0px 4px 4px 0px", height: "32px", width: "200px" }} >
                                <StyledInput
                                    inputProps={{ 'aria-label': 'Search...' }}
                                    value={searchValue}
                                    onKeyUp={handleKeyUp}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <StyledIconButton aria-label="Search" onClick={handleSearch}>
                                    <SearchIcon />
                                </StyledIconButton>
                            </StyledPaper>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                                <CloseIcon onClick={handleClose} style={{ color: 'white' }}>close</CloseIcon>
                            </div>
                        </div>
                    </div>
                </StyledDialogTitle>
            </div>
            <div style={{ backgroundColor: "white", minHeight: 80, justifyContent: "space-between", display: "flex", flexDirection: "row", margin: "20px 20px 0 20px", alignItems: "center" }}>
                <AddMaterialFromPslActiveFilters
                    filters={addMatPslQueryObject?.filters}
                    setQueryObject={updateAddMatFromPslFilters}
                />
                <FilterButton onClick={toggleFiltersDrawer(true, toggleFilters)} />
            </div>
            <div>
                <MaterialFromPslTable
                    totalCount={addMatFromPslData?.total || 0}
                    currentPage={page}
                    onNewPage={onPageChange}
                    rowsPerPage={rows}
                    setRowsPerPage={onRowsChange}
                    data={addMatFromPslData?.data || []}
                    materialsAdded={materialsAdded}
                    setMaterialsAdded={setMaterialsAdded}
                    loading={isLoading}
                />
            </div>
            <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "10px", margin: "0 20px 20px 10px" }}>
                <ApplyButton
                    onClick={handleAddMaterialFromPsl}
                    disabled={materialsAdded.lengh === 0}
                    variant="contained" color="primary">
                    Add
                </ApplyButton>
                <ApplyButton
                    onClick={handleClose}
                    variant="contained" color="primary">
                    Cancel
                </ApplyButton>
            </DialogActions>
            <StyledDrawer anchor="right" open={showFilters} onClose={toggleFiltersDrawer(false, toggleFilters)}>
                <AddMaterialFromPslFiltersPage
                    onClose={toggleFiltersDrawer(false, toggleFilters)}
                    updateFilters={updateAddMatFromPslFilters}
                    queryData={addMatPslQueryObject}
                    vendorList={vendorList}
                    plantList={plantList}
                    zone={props.documentFlow.SystemAlias}
                />
            </StyledDrawer>
        </div>
        {isError && <Portal ><Notification type={NotificationType.error} message={error.toString()} /></Portal>}
    </StyledConfirmDialog>
};
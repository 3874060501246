import * as R from "ramda";
import { DocFlowActions, DocumentFlowTypes } from "../actions/documentFlow.actions";
import DocumentFlow, {
  CompetitiveBiddingType,
  DocFlowFilterTabs,
  DocumentFlowIdGenerator
} from "../domains/documentFlow/documentFlow";
import { Invoice } from "../invoices/domains/invoices/Invoice";
import { DocumentFlowStage } from "../domains/documentFlow/documentFlowStage";
import { QueryableReducer } from "../../shared/queryable/query.reducer";
import { newDocumentFlowQuery } from "../domains/documentFlow/documentFlowQuery";
import { DocFlowChatTypes } from "../actions/docflowChat.actions";
import { IChatCounter } from "../../shared/domains/chat/roomChat.service";
import { DocFlowItem } from "../domains/prpo/poItem";
import { InvoiceItem } from "../domains/prpo/InvoiceItem";
import { SAPBoolean } from "../../shared/domains/core/sapBoolean";

export type StageDocumentFlow = DocumentFlow & { stage: DocFlowStageState };
export type DocumentFlows = { [key: string]: StageDocumentFlow };

export interface DocFlowStageState {
  stage: DocumentFlowStage;
  loading: boolean;
  error: string;
}

export interface DocumentFlowState {
  loading: boolean;
  error: string;
  totalCount: number;
  docStatus: {};
  documentFlows: DocumentFlows;
  invoices: Invoice[];
  invoicesItems: {
    [key: string]: {
      items: InvoiceItem[];
      itemsLoading: boolean;
    };
  };
  documentFlowsItems: {
    [key: string]: {
      items: DocFlowItem[];
      itemsLoading: boolean;
    };
  };
  selectedDocFlow: DocumentFlow;
  selectedDocFlowItem: DocFlowItem;
  docFlowToLink: DocumentFlow;
  docFlowsToLink: {
    loading: boolean;
    error: string;
    data: DocumentFlow[];
  };
  onUpdateLoading: boolean;
  onClosePOLoading: boolean;
  onFiltersLoading: boolean;
  selectedTab: DocFlowFilterTabs;
}

export const emptyStage = () => ({
  stage: null,
  loading: false,
  error: null
});

export const initialDocFlowsState: DocumentFlowState = {
  loading: true,
  error: null,
  totalCount: 0,
  docStatus: {},
  documentFlows: {},
  invoices: [],
  invoicesItems: {},
  documentFlowsItems: {},
  selectedDocFlow: null,
  selectedDocFlowItem: null,
  docFlowToLink: null,
  docFlowsToLink: {
    loading: false,
    error: null,
    data: []
  },
  onUpdateLoading: false,
  onClosePOLoading: false,
  onFiltersLoading: false,
  selectedTab: DocFlowFilterTabs.DOCUMENTS
};

const loading = { loading: true, error: null };
const loaded = { loading: false, error: null };
const failure = (error: string) => ({ loading: false, error });
const stageFailure = (error: string) => ({ ...failure(error), stage: null });

function updateCounter(
  state: DocumentFlowState,
  chatCounter: IChatCounter,
  currentUserId: number
): DocumentFlowState {
  const ids = chatCounter.id.split(":");
  var item = Object.values(state.documentFlows).find(doc => {
    return (
      (!ids[0] || (ids[0] && ids[0] === doc.PurchaseReqNo)) &&
      (!ids[1] || (ids[1] && ids[1] === doc.PurchaseOrdNo))
    );
  });
  if (item) {
    const newState = {
      ...state,
      documentFlows: {
        ...state.documentFlows,
        [item.id]: {
          ...state.documentFlows[item.id],
          CountOfUnreadComments:
            chatCounter.userId === currentUserId
              ? state.documentFlows[item.id].CountOfUnreadComments
              : state.documentFlows[item.id].CountOfUnreadComments + 1,
          TotalCountOfComments:
            state.documentFlows[item.id].TotalCountOfComments + 1
        }
      }
    };
    return newState as DocumentFlowState;
  }
  return state;
}

export function documentFlowReducer(
  state: DocumentFlowState = initialDocFlowsState,
  action: DocFlowActions
): DocumentFlowState {
  switch (action.type) {
    case DocumentFlowTypes.DEFAULT_DOC_DATA:
      return initialDocFlowsState
    case DocumentFlowTypes.UPDATE_SELECTED_TAB:
      return R.mergeRight(state, { selectedTab: action.selectedTab });
    case DocumentFlowTypes.FETCH_DOCFLOW_REQUEST:
      return R.mergeRight(state, { ...loading, onFiltersLoading: true });
    case DocumentFlowTypes.FETCH_DOCFLOW:
      return R.mergeRight(state, { ...loading, onFiltersLoading: true });
    case DocumentFlowTypes.RESUBMIT_DOCFLOW_SUCCESS:
      return R.mergeRight(state, loaded);
    case DocumentFlowTypes.FETCH_DOCFLOW_SUCCESS:
      const documentsWithStage = action.documents.map(
        R.assoc("stage", emptyStage())
      );
      const indexedDocuments = R.indexBy(
        DocumentFlowIdGenerator,
        documentsWithStage
      );
      const withDocuments = R.mergeRight(
        { totalCount: action.total, documentFlows: indexedDocuments },
        loaded
      );
      return R.mergeRight(state, withDocuments);
    case DocumentFlowTypes.FETCH_INVOICES_SUCCESS:
      const withInvoices = R.mergeRight(
        { totalCount: action.total, invoices: action.documents },
        loaded
      );
      return R.mergeRight(state, withInvoices);
    case DocumentFlowTypes.FETCH_DOCFLOW_STATUS_SUCCESS:
      const withStatus = R.mergeRight(
        { docStatus: action.status, onFiltersLoading: false },
        { loading: state.loading, error: null }
      );
      const withOutStatus = R.mergeRight(
        { onFiltersLoading: false },
        { loading: state.loading, error: null }
      );
      return Object.values(action.status).every(el => el != undefined) ? R.mergeRight(state, withStatus) : R.mergeRight(state, withOutStatus); // To avoid updating after pagination
    case DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_REQUEST:
      return R.mergeRight(state, {
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: true,
            items: []
          }
        }
      });
    case DocumentFlowTypes.FETCH_INVOICE_ITEMS_REQUEST:
      return R.mergeRight(state, {
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: true,
            items: []
          }
        }
      });
    case DocumentFlowTypes.FETCH_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: false,
            items: action.documents
          }
        }
      };
    case DocumentFlowTypes.FETCH_INVOICE_ITEMS_FAILURE:
      return {
        ...state,
        invoicesItems: {
          ...state.invoicesItems,
          [action.i.InvDocNo]: {
            itemsLoading: false,
            items:
              state.invoicesItems[action.i.InvDocNo].items
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_SUCCESS:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: false,
            items: action.documents
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAILURE:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: false,
            items:
              state.documentFlowsItems[DocumentFlowIdGenerator(action.df)].items
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAV_REQUEST:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: true,
            items:
              state.documentFlowsItems[DocumentFlowIdGenerator(action.df)].items
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAV_SUCCESS:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: false,
            items: state.documentFlowsItems[
              DocumentFlowIdGenerator(action.df)
            ].items.map(item =>
              item.PrItem === action.PrItem || item.PoItem === action.PoItem
                ? {
                  ...item,
                  IsFavorite:
                    item.IsFavorite === SAPBoolean.TRUE
                      ? SAPBoolean.FALSE
                      : SAPBoolean.TRUE
                }
                : item
            )
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_REQUEST:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: true,
            items: state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
              ? state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
                .items
              : []
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_SUCCESS:
      return {
        ...state,
        documentFlows: {
          ...state.documentFlows,
          [DocumentFlowIdGenerator(action.df)]: {
            ...state.documentFlows[DocumentFlowIdGenerator(action.df)],
            IsFavorite: action.fav ? SAPBoolean.TRUE : SAPBoolean.FALSE
          }
        },
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: false,
            items: (state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
              ? state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
                .items
              : []
            ).map(item => ({
              ...item,
              IsFavorite: action.fav ? SAPBoolean.TRUE : SAPBoolean.FALSE
            }))
          }
        }
      };
    case DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_FAILURE:
      return {
        ...state,
        documentFlowsItems: {
          ...state.documentFlowsItems,
          [DocumentFlowIdGenerator(action.df)]: {
            itemsLoading: false,
            items: state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
              ? state.documentFlowsItems[DocumentFlowIdGenerator(action.df)]
                .items
              : []
          }
        }
      };
    case DocumentFlowTypes.SET_SELECTED_DOCFLOW:
      return R.mergeRight(state, { selectedDocFlow: action.docFLow });
    case DocumentFlowTypes.SET_SELECTED_DOCFLOW_ITEM:
      return R.mergeRight(state, { selectedDocFlowItem: action.docFLowItem });
    case DocumentFlowTypes.FETCH_DOCFLOW_FAILURE:
    case DocumentFlowTypes.FETCH_DOCFLOW_STATUS_FAILURE:
    case DocumentFlowTypes.FETCH_INVOICES_FAILURE:
    case DocumentFlowTypes.REMOVE_LINK_CB_FAILURE:
      return R.mergeRight(state, failure(action.error));
    case DocumentFlowTypes.FETCH_DOCFLOWSTAGE_SUCCESS:
      const stage = action.stage;
      return R.assocPath(
        ["documentFlows", stage.dfId, "stage", "stage"],
        stage,
        state
      );
    case DocumentFlowTypes.REMOVE_LINK_CB_SUCCESS:
      const dfListBySapPRPO = R.filter(
        R.allPass([
          R.propEq("PurchaseReqNo")(action.sapPR),
          R.propEq("PurchaseOrdNo")(action.sapPO)
        ])
      )(R.values(state.documentFlows));
      const docflowsUpdatedState = R.reduce(
        (acc, df) =>
          R.assocPath(
            [R.prop("id", <any>df), "CB"],
            CompetitiveBiddingType.MissingCB,
            acc
          ),
        state.documentFlows,
        dfListBySapPRPO
      );
      return R.assoc("documentFlows", docflowsUpdatedState, state);
    case DocFlowChatTypes.GET_MESSAGES_SUCCESS:
      const dfListByPRPO = R.filter(
        R.allPass([
          R.propEq("PurchaseReqNo")(action.prNo),
          R.propEq("PurchaseOrdNo")(action.poNo)
        ])
      )(R.values(state.documentFlows));
      const docflowsUpdatedCount = R.reduce(
        (acc, df) =>
          R.assocPath([R.prop("id", <any>df), "CountOfUnreadComments"], 0, acc),
        state.documentFlows,
        dfListByPRPO
      );
      return R.assoc("documentFlows", docflowsUpdatedCount, state);
    case DocumentFlowTypes.FETCH_DOCFLOWSTAGE_FAILURE:
      return R.assocPath(
        ["documentFlows", action.dfId, "stage"],
        stageFailure(action.error),
        state
      );
    case DocFlowChatTypes.NEW_DOCFLOW_CHAT_COUNTER:
      return updateCounter(state, action.counter, action.currentUserId);
    case DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB:
      return R.mergeRight(state, {
        docFlowsToLink: { loading: true, error: null, data: [] }
      });
    case DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB_SUCCESS:
      return R.mergeRight(state, {
        docFlowsToLink: { loading: false, error: null, data: action.data }
      });
    case DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB_FAILURE:
      return R.mergeRight(state, {
        docFlowsToLink: { loading: false, error: action.error, data: [] }
      });
    case DocumentFlowTypes.SET_DOCFLOW_TO_LINK:
      return R.mergeRight(state, { docFlowToLink: action.docFlow });
    case DocumentFlowTypes.SAVE_DOCFLOW_ITEM:
    case DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM:
    case DocumentFlowTypes.NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS:
    case DocumentFlowTypes.NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_WITH_ACCOUNT_DETAILS:
    case DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS:
    case DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_ARR:
      return R.mergeRight(state, { onUpdateLoading: true });
    case DocumentFlowTypes.CLOSE_PO:
      return R.mergeRight(state, { onClosePOLoading: true });
    case DocumentFlowTypes.SAVE_DOCFLOW_ITEM_SUCCESS:
    case DocumentFlowTypes.SAVE_DOCFLOW_ITEM_FAILURE:
    case DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_SUCCESS:
    case DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_FAILURE:
    case DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_SUCCESS:
    case DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_FAILURE:
      return R.mergeRight(state, { onUpdateLoading: false });
    case DocumentFlowTypes.CLOSE_PO_SUCCESS:
    case DocumentFlowTypes.CLOSE_PO_FAILURE:
      return R.mergeRight(state, { onClosePOLoading: false });
    default:
      return state;
  }
}

export const documentFlowStateSelector = ["docFlows", "documentFlows"];
export const documentFlowDomainName = "DOCUMENT_FLOW";
export const {
  reducer: QueryableDocumentFlowReducer,
  sagas: queryDocFlowSagas,
  actions: QueryActions
} = QueryableReducer<DocumentFlowState>(
  documentFlowDomainName,
  documentFlowStateSelector,
  documentFlowReducer,
  newDocumentFlowQuery()
);

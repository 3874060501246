import React from 'react';
import { FeaturesContext } from 'src/app/shared/domains/core/pataflag.context';
import { newEditRequestPageStyles } from './AllSections/newEditRequest/newEditRequestPageStyles';
import { Features } from 'src/app/shared/domains/core/pataflag';

export function TabSelector(props) {
    const { hasFeature } = React.useContext(FeaturesContext);

    const { pr_editor_title_tab_selector_link, individualTabStyle } = newEditRequestPageStyles({});

    return (
        <div className={'pr-editor-title-header'} ref={props.headerRef}
            style={{
                marginLeft: 30,
                fontSize: 16
            }}
        >
            <div
                className={`${pr_editor_title_tab_selector_link} generalDetails-link ${individualTabStyle}`}
                onClick={() => {
                    props.scrollTo(props.generalDetailsRef.current);
                }}
            >
                GENERAL DETAILS
            </div>
            <div
                className={`${pr_editor_title_tab_selector_link} itemDetails-link ${individualTabStyle}`}
                onClick={() => {
                    props.scrollTo(props.itemDetailsRef.current);
                }}
            >
                ITEM DETAILS
            </div>
            {props.showPoSendingOptions && <div
                className={`${pr_editor_title_tab_selector_link} poSendingOptions-link ${individualTabStyle}`}
                onClick={() => {
                    props.scrollTo(props.poSendingOptionsRef.current);
                }}
            >
                PO SENDING OPTIONS
            </div>}
            <div
                className={`${pr_editor_title_tab_selector_link} attachmentsAndComments-link ${individualTabStyle}`}
                onClick={() => {
                    props.scrollTo(props.attachmentsAndCommentsRef.current);
                }}
            >
                ATTACHMENTS & COMMENTS
            </div>
        </div>
    );
}



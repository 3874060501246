import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Input } from '@material-ui/core';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { CustomFastInput, CustomFastSelect } from 'src/app/shared/components/form';
import clsx from 'clsx';
import _ from "lodash";
import { newEditRequestPageStyles } from './newEditRequestPageStyles';

export default function DeliveryAddressComponent({ countryRegions, lineItem, applyChanges, disbaleItemDetails=false }: { countryRegions: any, lineItem: DocFlowItem, applyChanges: any, disbaleItemDetails?: boolean }) {
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const styles = newEditRequestPageStyles({});
    const [deliveryAddress, setDeliveryAddress] = useState<{
        Name1: string,
        Name2: string,
        Houseno: string,
        Street: string,
        Postalcode: string,
        City: string,
        Country: string,
        Region: string,
    }>({
        Name1: lineItem.Name1,
        Name2: lineItem.Name2,
        Houseno: lineItem.Houseno,
        Street: lineItem.Street,
        Postalcode: lineItem.Postalcode,
        City: lineItem.City,
        Country: lineItem.Country,
        Region: lineItem.Region,
    });
    const [fieldsChanged, setFieldsChanged] = useState({});

    const handleCancel = () => {
        setOpenDialog(false);
    };

    const handleChange = (vals: { [key: string]: any }) => {
        const newAddress = {
            ...deliveryAddress,
            ...vals
        };
        setDeliveryAddress(newAddress);
        setFieldsChanged(prevState => ({ ...prevState, ...vals }));
    };

    const handleSave = () => {
        console.log(deliveryAddress);
        applyChanges({ ...fieldsChanged }, lineItem.PrItem);
        handleCancel();
    };

    return (
        <div>
            <Input
                type={"text"}
                value={deliveryAddress.Name1 + ", " + deliveryAddress.Name2 + ", " + deliveryAddress.Houseno + ", " + deliveryAddress.Street + ", " +
                    deliveryAddress.Postalcode + ", " + deliveryAddress.Country + ", " + deliveryAddress.Region}
                className={styles.input}
                disabled = {!disbaleItemDetails && lineItem.PR_Addno ? false : true}
                onClick={() => setOpenDialog(true)}
            />
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <DialogContent>
                    <Grid className={clsx(styles.label, styles.deliveryAddHeader)}>DELIVERY ADDRESS</Grid>
                    <Grid container spacing={2} wrap={"nowrap"} style={{gap: 32, marginBottom: 30}}>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>Name</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.Name1}
                                    onChange={value => handleChange({ Name1: value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>Name Two</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.Name2}
                                    onChange={value => handleChange({ Name2: value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>House #</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.Houseno}
                                    onChange={value => handleChange({ Houseno: value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>Street</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.Street}
                                    onChange={value => handleChange({ Street: value })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} wrap={"nowrap"} style={{gap: 32, marginBottom: 12}}>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>Postal Code</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.Postalcode}
                                    onChange={value => handleChange({ Postalcode: value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>City</Grid>
                            <Grid className={styles.select}>
                                <CustomFastInput
                                    type={"text"}
                                    initialValue={deliveryAddress.City}
                                    onChange={value => handleChange({ City: value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>Country</Grid>
                            <Grid className={styles.select}>
                                <CustomFastSelect
                                    menuPortalTarget={document.body}
                                    isMulti={false}
                                    clearable={false}
                                    customDisplay={option => option.value}
                                    initialValue={{ value: deliveryAddress?.Country }}
                                    options={_.uniq(countryRegions?.map(el => el.value)).map(el => ({ value: el }))}
                                    // options={[]}
                                    onChange={value => handleChange({ Country: value?.value })} />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Grid className={styles.label}>State / Province</Grid>
                            <Grid className={styles.select}>
                                <CustomFastSelect
                                    menuPortalTarget={document.body}
                                    isMulti={false}
                                    disabled={!deliveryAddress?.Country}
                                    clearable={false}
                                    customDisplay={option =>
                                        option.label
                                            ? `${option.value} - ${option.label}`
                                            : option.value
                                    }
                                    initialValue={{ value: deliveryAddress?.Region }}
                                    options={countryRegions?.filter(el => el.value === deliveryAddress?.Country).map(el => ({ value: el.Region, label: el.label }))}
                                    onChange={value => handleChange({ Region: value?.value })} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button color="secondary" onClick={handleCancel} >Cancel</Button>
                        <Button color="secondary" onClick={handleSave} > Save </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

import React from "react";
import { EditDetailsTable, PRStyledCell } from "src/app/purchaseRequisition/PR.styles";
import MaterialTable, { Column } from "material-table";
import { TableLoadingComponent } from "src/app/shared/components/loading";
import { useTableStyles } from "../../../../dataTable/docFlow/docFlowTable.styles";
import { ItemTableCellComponent } from "../../../itemTableCell.component";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { isNil } from "ramda";
import { Formatter } from "src/app/shared/components/amount-formatter";
import { AvatarWithPopover } from "../../../AvatarWithPopover";
import { PaginationComponent } from "src/app/shared/components/pagination/paginationComponent";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50"
        },
        secondary: {
            main: "#ff9100"
        }
    }
});

type newItemDetailsTableProps = {
    totalCount: number;
    currentPage: number;
    onNewPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: Function;
    data: any[];
    loading: boolean;
    materialsAdded: any[];
    setMaterialsAdded: Function
};

export const MaterialFromPslTable = (props: newItemDetailsTableProps) => {
    const { docFlowTableClass, removeBorderForCheckBox } = useTableStyles({});

    const materialFromPslColumns = (): Column<any>[] => {
        const columns = [
            {
                title: "PSL ID", sorting: false, field: "pslId"
            },
            {
                title: "Supplier", field: "vendorNo", sorting: false, render: (item: any, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.vendorNo, item.vendorDescription]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Material", field: "PrMaterial", sorting: false, render: (item: any, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.PrMaterial]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Material Description", field: "PrItemTxt", sorting: false, render: (item: any, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.PrItemTxt]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Plant", field: "plants", sorting: false, render: (item: any, i) => (
                    <span>{item?.plants?.map((el, i) => <div key={i}>{el.code} - {el.name}</div>)}</span>
                )
            },
            {
                title: "Status", field: "status", sorting: false, render: (item: any, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.status]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Price", field: "PrPriceRaw", sorting: false, render: (item: any, i) => (
                    item.PrPriceRaw ? <Formatter amount={item.PrPriceRaw} /> : null
                )
            },
            {
                title: "Lead Times", field: "leadTimes", sorting: false, render: (item: any, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.leadTimes]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Strategic Buyer", field: "buyer", sorting: false, render: (item: any, i) => (
                    <AvatarWithPopover
                        item={item?.buyer}
                        firstName={item?.buyer}
                        lastName={""}
                        userId={""}
                        email={item?.buyer}
                    />
                )
            }
        ]
        return columns
    };

    const theColumns = materialFromPslColumns();
    const columnsNotNull = theColumns.filter(el => !isNil(el));

    return <EditDetailsTable style={{ padding: "15px", minWidth: "1000px" }} className={`${docFlowTableClass} ${removeBorderForCheckBox}`}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{
                    boxShadow: "none",
                    zIndex: 999,
                    // maxHeight: "55vh"
                }}
                isLoading={props.loading}
                columns={columnsNotNull}
                data={props.data}
                components={{
                    OverlayLoading: () => <TableLoadingComponent />,
                    Cell: props => <PRStyledCell {...props} />
                }}
                onSelectionChange={dataClicked => {
                    props.setMaterialsAdded(dataClicked)
                }
                }
                options={{
                    selection: true,
                    paging: false,
                    toolbar: false,
                    rowStyle: {
                        borderBottom: "hidden", // this is to ensure no border is seen under checkbox
                        padding: "10px 5px",
                        fontSize: ".8rem", color: "#7c7777",
                        whiteSpace: "nowrap",
                        height: "60px"
                    },
                    headerStyle: {
                        fontSize: ".8rem", fontWeight: "bold",
                        height: "60px",
                        padding: "10px 5px",
                        whiteSpace: "nowrap",
                        color: "#5D5858",
                        backgroundColor: "#F5F5F5",
                        borderBottom: 'none'
                    },
                }}
            />
            <PaginationComponent
                count={props.totalCount}
                currentPage={props.currentPage}
                onChangePage={props.onNewPage}
                rowsPerPage={props.rowsPerPage}
                setRowsPerPage={props.setRowsPerPage}
                defaultRowsPerPage={[5, 10, 20, 50]}
            />
        </MuiThemeProvider>
    </EditDetailsTable>
}
import * as React from "react";
import { CustomFastAsyncSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { SelectContainer } from "src/app/shared/components/filters/filters.styles";

export const PlantComponent = ({plant,compCode,zone, applyChange}) =>{

    const plantData = [{
        id: plant,
        title: plant
    }];

    const compCodeData = [
        {
            id: compCode
        }
    ]

    return <SelectContainer>
        <CustomFastAsyncSelect
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            isMulti={false}
            onLoadLimit={3}
            onLoad={(x) => MasterService.fetchPlantsWithCompanyCodes(x.trim(), compCodeData, zone).then(res => res.map(v => ({ value: v.PlantCode, label: v.PlantName })))}
            customDisplay={option => `${option.value}`}
            initialValue={plantData.map(el => ({ value: el.id, label: el.title }))}
            onChange={(v) => {
                if(v && v.value !== "")
                    applyChange({Plant: v.value})
            }}
        />
    </SelectContainer>
}

import * as React from "react";
import { useEffect, useReducer, useState, useRef } from "react";
import * as R from "ramda";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    FilterChipsWithHover,
    FilterHeader,
} from "src/app/shared/components/filters/filters";
import { InputLabel } from "@material-ui/core";
import _ from "lodash";
import { FilterCategoryView } from "src/app/shared/domains/query/filter";
import {
    ApplyButton,
    FilterRow,
    FiltersContainer,
    FiltersContainerWrapper,
    ResetButton,
    SelectContainer,
    StyledButtons
} from "src/app/shared/components/filters/filters.styles";
import { CustomFastAsyncSelect, CustomFastSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { Toolbar } from "src/app/shared/components/layout/styles";
import { defaultNbsEasyRequestFiltersData } from "src/app/nbsCockpit/helpers/constants";
import { AddMatFromPslFilters, AddMatFromPslFilterKeys, getAddMatFromPslDefaultFilters } from "../../helper";
import { SupplierStatus } from "src/app/preferredSuppliers/domains/psl";

export interface AddMaterialFromPslFiltersPageProps {
    onClose: Function;
    updateFilters: Function;
    queryData: any;
    vendorList: { title: string, id: string }[];
    plantList: { title: string, id: string }[];
    zone: string;
}

const statusValues = [
    {
        title: "Blank",
        id: SupplierStatus.BLANK
    },
    {
        title: "Sole Source",
        id: SupplierStatus.SOLE_SOURCE
    },
    {
        title: "Mandatory",
        id: SupplierStatus.MANDATORY,
    },
    {
        title: "Validated",
        id: SupplierStatus.VALIDATED,
    },
];

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start'
        }
    })
);


export const AddMaterialFromPslActiveFilters = ({ filters, setQueryObject }: { filters: any, setQueryObject: any }) => {

    const removeTheEntry = (filterSelected, filterToDelete) => {
        return filterSelected.filter(el => el["id"] !== filterToDelete.id);
    };

    const newDeleteFilter = (filter) => {
        const tempFilter = { ...filters };
        tempFilter[filter.kind].selected = removeTheEntry(tempFilter[filter.kind].selected, filter);
        setQueryObject(tempFilter)
    };

    return (
        <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    AddMatFromPslFilters.map((f, idx) => {
                        if (!filters[f.key]) {
                            return <div key={idx}></div>
                        }
                        return <FilterChipsWithHover key={idx} kind={f.key} name={f.label}
                            source={filters[f.key].selected}
                            onDelete={newDeleteFilter} />
                    })
                }
            </div>
        </>
    )
};

export default function AddMaterialFromPslFiltersPage(props: AddMaterialFromPslFiltersPageProps) {

    const classes = useStyles(props);

    const [initalFiltersData, setFilters]: [any, any] = useReducer(R.mergeDeepRight,
        !_.isEmpty(props.queryData.filters) ? props.queryData.filters : defaultNbsEasyRequestFiltersData);
    const [currentFiltersData, setCurrentFiltersData] = useState(initalFiltersData);

    const handleChange = (value, d: FilterCategoryView) => {
        const newValues = value ? value.map(el => ({ title: el.title, id: (el.azureId && el.azureId !== 0) ? el.azureId : el.id })) : [];
        setCurrentFiltersData(R.set(R.lensPath([d.key, 'selected']), newValues, currentFiltersData));
    };

    const easyRequstFilterComponents = R.mapObjIndexed((d: FilterCategoryView, key: string) => {
        switch (d.key) {
            case AddMatFromPslFilterKeys.Supplier:
                return <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                        <CustomFastSelect
                            isMulti
                            options={props.vendorList?.map(el => ({ value: el.id, label: el.title }))}
                            initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
                            onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
                        />
                    </SelectContainer>
                </FilterRow>;
            case AddMatFromPslFilterKeys.STATUS:
                return <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                        <CustomFastSelect
                            isMulti
                            options={statusValues?.map(el => ({ value: el.id, label: el.title }))}
                            customDisplay={option => option?.label ? option.label : option.value}
                            initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
                            onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
                        />
                    </SelectContainer>
                </FilterRow>;
            case AddMatFromPslFilterKeys.PLANT:
                return <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                        <CustomFastAsyncSelect
                            isMulti
                            onLoadLimit={3}
                            onLoad={(x) => MasterService.fetchPlantsWithCompanyCodes(x.trim(), [{ id: props.queryData?.compCode }], props.zone).then(res => res.map(v => ({ value: v.PlantCode, label: v.PlantName })))}
                            customDisplay={option => `${option.value} - ${option.label}`}
                            initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id, label: el.title }))}
                            onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
                            customTooltipMessage="Type to find the plants" />
                    </SelectContainer>
                </FilterRow>;
            case AddMatFromPslFilterKeys.MATERIAL:
                return <FilterRow key={key}>
                    <InputLabel>{d.label}</InputLabel>
                    <SelectContainer>
                        <CustomFastAsyncSelect
                            isMulti
                            onLoadLimit={2}
                            onLoad={(x) => MasterService.fetchMaterial(x.trim(), currentFiltersData[AddMatFromPslFilterKeys.PLANT]?.selected, props.zone)}
                            customDisplay={option => `${option.value?.replace(/^0+/, '')} - ${option.label}`}
                            initialValue={currentFiltersData[d.key]?.selected?.map(el => ({ value: el.id?.replace(/^0+/, ''), label: el.title }))}
                            onChange={(v) => handleChange(v?.map(v => ({ id: v.value, title: v.label })), d)}
                            customTooltipMessage="Type to find the materials" />
                    </SelectContainer>
                </FilterRow>;
            default:
                return null;
        }
    }, initalFiltersData);

    useEffect(() => {
        console.log(currentFiltersData)
    }, [currentFiltersData])

    const scrollToRef = useRef(null);

    useEffect(() => {
        scrollToRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Toolbar>
                <FilterHeader
                    onCloseClick={() => props.onClose(false)}
                    filters={currentFiltersData}
                    setFilters={setCurrentFiltersData}
                    zone={props.zone}
                    updateFilters={props.updateFilters}
                    closeFilters={props.onClose}
                    tab={""}
                />
            </Toolbar>
            <FiltersContainerWrapper ref={scrollToRef}>
                <FiltersContainer className={classes.root}>
                    {
                        R.keys(easyRequstFilterComponents).map(o => easyRequstFilterComponents[o])
                    }
                    <FilterRow style={{ padding: "0 0 20px 0" }}>
                        <br />
                        <StyledButtons>
                            <ApplyButton
                                onClick={() => {
                                    props.updateFilters(currentFiltersData)
                                }}
                                variant="contained"
                                color="primary"
                                autoFocus>
                                Apply
                            </ApplyButton>
                            <ResetButton
                                onClick={() => props.updateFilters(getAddMatFromPslDefaultFilters(props.vendorList, props.plantList))}
                                variant="contained"
                                color="primary">
                                Clear
                            </ResetButton>
                        </StyledButtons>
                    </FilterRow>
                </FiltersContainer>
            </FiltersContainerWrapper>
        </>
    )
}


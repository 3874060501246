import * as React from "react";
import { ContactChatIcon, ContactDepartmentIcon, ContactDesignationIcon, ContactEmailIcon, ContactIdIcon, ContactMobileIcon, ContactOrganisationIcon, ContactPhoneIcon } from "src/app/landing/containers/icons";
import { useFetchUserContactDetails } from "../../domains/master/master.hooks";
import { LoadingComponent } from "../loading";

export const ContactCardComponent = ({ email }: { email: string }) => {

    const { data: userContactDetails, isLoading } = useFetchUserContactDetails(email);

    return (
        isLoading ? <LoadingComponent /> : <>
            <div style={{
                display: "grid", gridTemplateColumns: "auto auto", gap: "15px", margin: "15px",
                color: '#818181',
                font: "normal normal normal 12px/14px Roboto, sans-serif",

            }}>
                {userContactDetails?.mailNickname && <div style={{ display: "flex", gap: "10px", }}>
                    <ContactIdIcon />
                    <div>
                        {userContactDetails?.mailNickname}
                    </div>
                </div>}
                {userContactDetails?.displayName && <div style={{ display: "flex", gap: "10px" }}>
                    <ContactChatIcon />
                    <div>{userContactDetails?.displayName}</div>
                </div>}
                {email && <div style={{ display: "flex", gap: "10px", color: "#4289EB" }}>
                    <ContactEmailIcon />
                    <a href={`mailto: ${email}`}>{email?.toLowerCase()}</a>
                </div>}
                {userContactDetails?.businessPhones.length > 0 && <div style={{ display: "flex", gap: "10px", color: "#4289EB" }}>
                    <ContactPhoneIcon />
                    <div>
                        {userContactDetails?.businessPhones?.join(",")}
                    </div>
                </div>}
                {userContactDetails?.mobilePhone && <div style={{ display: "flex", gap: "10px", color: "#4289EB" }}>
                    <ContactMobileIcon />
                    <div>
                        {userContactDetails?.mobilePhone}
                    </div>
                </div>}
                {userContactDetails?.jobTitle && <div style={{ display: "flex", gap: "10px", }}>
                    <ContactDesignationIcon />
                    <div>
                        {userContactDetails?.jobTitle}
                    </div>
                </div>}
                {userContactDetails?.department && <div style={{ display: "flex", gap: "10px", }}>
                    <ContactDepartmentIcon />
                    <div>
                        {userContactDetails?.department}
                    </div>
                </div>}
                {userContactDetails?.companyName && <div style={{ display: "flex", gap: "10px", }}>
                    <ContactOrganisationIcon />
                    <div>
                        {userContactDetails?.companyName}
                    </div>
                </div>}
            </div>
        </>
    )

}
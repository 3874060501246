import {
  Action,
  AzureErrorAction, DownloadCompletedAction,
  ErrorAction,
  IPromiseExecutor,
  PendingAction,
  SuccessAction
} from "../../shared/domains/core/actions";
import DocumentFlow, { DocFlowFilterTabs, DocumentFlowId } from "../domains/documentFlow/documentFlow";
import { DocumentFlowStage } from "../domains/documentFlow/documentFlowStage";
import { GetChatMessagesSuccess, NewDocFlowChatCounter } from "./docflowChat.actions";
import { SuccessNotificationAction } from "../../shared/domains/core/notifications";
import { DocFlowItem } from "../domains/prpo/poItem";
import { InvoiceItem } from "../domains/prpo/InvoiceItem";
import { Invoice } from "../invoices/domains/invoices/Invoice";


export enum DocumentFlowTypes {
  FETCH_DOCFLOW = "FETCH_DOCFLOW",
  FETCH_DOCFLOW_REQUEST = "FETCH_DOCFLOW_REQUEST",
  FETCH_DOCFLOW_SUCCESS = "FETCH_DOCFLOW_SUCCESS",
  FETCH_DOCFLOW_FAILURE = "FETCH_DOCFLOW_FAILURE",

  RESUBMIT_DOCFLOW_SUCCESS = "RESUBMIT_DOCFLOW_SUCCESS",

  FETCH_INVOICES = 'FETCH_INVOICES',
  FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST",
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE',

  FETCH_DOCFLOW_STATUS_SUCCESS = "FETCH_DOCFLOW_STATUS_SUCCESS",
  FETCH_DOCFLOW_STATUS_FAILURE = "FETCH_DOCFLOW_STATUS_FAILURE",

  FETCH_DOCFLOW_ITEMS_REQUEST = "FETCH_DOCFLOW_ITEMS_REQUEST",
  FETCH_DOCFLOW_ITEMS_SUCCESS = "FETCH_DOCFLOW_ITEMS_SUCCESS",
  FETCH_DOCFLOW_ITEMS_FAILURE = "FETCH_DOCFLOW_ITEMS_FAILURE",

  FETCH_DOCFLOW_ITEMS_FAV_REQUEST = "FETCH_DOCFLOW_ITEMS_FAV_REQUEST",
  FETCH_DOCFLOW_ITEMS_FAV_SUCCESS = "FETCH_DOCFLOW_ITEMS_FAV_SUCCESS",

  FETCH_DOCFLOW_HEADER_LEVEL_FAV_REQUEST = "FETCH_DOCFLOW_HEADER_LEVEL_FAV_REQUEST",
  FETCH_DOCFLOW_HEADER_LEVEL_FAV_SUCCESS = "FETCH_DOCFLOW_HEADER_LEVEL_FAV_SUCCESS",
  FETCH_DOCFLOW_HEADER_LEVEL_FAV_FAILURE = "FETCH_DOCFLOW_HEADER_LEVEL_FAV_FAILURE",

  UPDATE_DOCFLOW_ITEM = "UPDATE_DOCFLOW_ITEM",
  UPDATE_DOCFLOW_ITEM_SUCCESS = "UPDATE_DOCFLOW_ITEM_SUCCESS",
  UPDATE_DOCFLOW_ITEM_FAILURE = "UPDATE_DOCFLOW_ITEM_FAILURE",

  REQUEST_CHANGE_DOCFLOW_ITEM = "REQUEST_CHANGE_DOCFLOW_ITEM",
  REQUEST_CHANGE_DOCFLOW_ITEM_ARR = 'REQUEST_CHANGE_DOCFLOW_ITEM_ARR',
  REQUEST_CHANGE_DOCFLOW_ITEM_SUCCESS = "REQUEST_CHANGE_DOCFLOW_ITEM_SUCCESS",
  REQUEST_CHANGE_DOCFLOW_ITEM_FAILURE = "REQUEST_CHANGE_DOCFLOW_ITEM_FAILURE",

  REQUEST_CHANGE_ALL_DOCFLOW_ITEMS = "REQUEST_CHANGE_ALL_DOCFLOW_ITEMS",
  NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS = "NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS",
  NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_WITH_ACCOUNT_DETAILS = "NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_WITH_ACCOUNT_DETAILS",
  REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_SUCCESS = "REQUEST_CHANGE_DOCFLOW_ITEM_SUCCESS",
  REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_FAILURE = "REQUEST_CHANGE_DOCFLOW_ITEM_FAILURE",

  SEND_EMAIL_PO = "SEND_EMAIL_PO",
  SEND_EMAIL_PO_SUCCESS = "SEND_EMAIL_PO_SUCCESS",
  SEND_EMAIL_PO_FAILURE = "SEND_EMAIL_PO_FAILURE",

  SEND_EMAIL_PO_APPROVAL = "SEND_EMAIL_PO_APPROVAL",
  SEND_EMAIL_PO_APPROVAL_SUCCESS = "SEND_EMAIL_PO_APPROVAL_SUCCESS",
  SEND_EMAIL_PO_APPROVAL_FAILURE = "SEND_EMAIL_PO_APPROVAL_FAILURE",

  SAVE_DOCFLOW_ITEM = "SAVE_DOCFLOW_ITEM",
  SAVE_DOCFLOW_ITEM_SUCCESS = "SAVE_DOCFLOW_ITEM_SUCCESS",
  SAVE_DOCFLOW_ITEM_FAILURE = "SAVE_DOCFLOW_ITEM_FAILURE",

  FETCH_INVOICE_ITEMS_REQUEST = "FETCH_INVOICE_ITEMS_REQUEST",
  FETCH_INVOICE_ITEMS_SUCCESS = "FETCH_INVOICE_ITEMS_SUCCESS",
  FETCH_INVOICE_ITEMS_FAILURE = "FETCH_INVOICE_ITEMS_FAILURE",

  CLOSE_PO = "CLOSE_PO",
  CLOSE_PO_SUCCESS = "CLOSE_PO_SUCCESS",
  CLOSE_PO_FAILURE = "CLOSE_PO_FAILURE",

  FETCH_DOCFLOWSTAGE = "FETCH_DOCFLOWSTAGE",
  FETCH_DOCFLOWSTAGE_SUCCESS = "FETCH_DOCFLOWSTAGE_SUCCESS",
  FETCH_DOCFLOWSTAGE_FAILURE = "FETCH_DOCFLOWSTAGE_FAILURE",

  FETCH_DOCFLOWS_LINK_CB = "FETCH_DOCFLOWS_LINK_CB",
  FETCH_DOCFLOWS_LINK_CB_SUCCESS = "FETCH_DOCFLOWS_LINK_CB_SUCCESS",
  FETCH_DOCFLOWS_LINK_CB_FAILURE = "FETCH_DOCFLOWS_LINK_CB_FAILURE",

  SET_SELECTED_DOCFLOW = "SET_SELECTED_DOCFLOW",
  SET_SELECTED_DOCFLOW_ITEM = "SET_SELECTED_DOCFLOW_ITEM",

  REMOVE_LINK_CB = "REMOVE_LINK_CB",
  REMOVE_LINK_CB_SUCCESS = "REMOVE_LINK_CB_SUCCESS",
  REMOVE_LINK_CB_FAILURE = "REMOVE_LINK_CB_FAILURE",

  GET_BLOCKED_INVOICES = "GET_BLOCKED_INVOICES",
  GET_BLOCKED_INVOICES_SUCCESS = "GET_BLOCKED_INVOICES_SUCCESS",
  GET_BLOCKED_INVOICES_FAILURE = "GET_BLOCKED_INVOICES_FAILURE",

  SET_DOCFLOW_TO_LINK = "SET_DOCFLOW_TO_LINK",
  UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB",

  FETCH_DOCFLOW_DOCUMENT = "FETCH_DOCFLOW_DOCUMENT",
  FETCH_DOCFLOW_DOCUMENT_SUCCESS = "FETCH_DOCFLOW_DOCUMENT_SUCCESS",
  DEFAULT_DOC_DATA = "DEFAULT_DOC_DATA",

  EXPORT_RC_DOC_LIST_DATA_SUCCESS_BY_EMAIL = "EXPORT_PSL_LIST_DATA_SUCCESS_BY_EMAIL",
  EXPORT_RC_DOC_LIST_DATA_FAILURE_BY_EMAIL = "EXPORT_PSL_LIST_DATA_FAILURE_BY_EMAIL",
}

export class UpdateTab extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.UPDATE_SELECTED_TAB;

  constructor(public selectedTab: DocFlowFilterTabs) {
    super();
  }
}

export class DocumentFlowFetchAction extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW;
  constructor(public tab: DocFlowFilterTabs) {
    super();
  }
}

export class DocumentFlowFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_SUCCESS;

  constructor(public documents: DocumentFlow[], public total: number) {
    super();
  }
}
export class DocumentFlowResubmitSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.RESUBMIT_DOCFLOW_SUCCESS;
}

export class DefaultDocData {
  public readonly type = DocumentFlowTypes.DEFAULT_DOC_DATA;
}
export class ExportDocListDataEmailSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.EXPORT_RC_DOC_LIST_DATA_SUCCESS_BY_EMAIL;
  constructor(public message) {
    super();
  }
}

export class ExportDocListDataEmailError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.EXPORT_RC_DOC_LIST_DATA_FAILURE_BY_EMAIL;
}

export class InvoicesFetchAction extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICES;
  constructor(public zone: string) {
    super();
  }
}

export class InvoicesFetchLoad extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICES_REQUEST;
}

export class InvoicesFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICES_SUCCESS;

  constructor(public documents: Invoice[], public total: number) {
    super();
  }
}

export class DocumentFlowStatusFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_STATUS_SUCCESS;

  constructor(public documents: DocumentFlow[], public total: number, public status: object) {
    super();
  }
}



export class DocumentFlowFetchLoad extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_REQUEST;
}

export class DocumentFlowFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_FAILURE;
}

export class DocumentFlowStatusFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_STATUS_FAILURE;
}

export class InvoicesFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICES_FAILURE;
}

export class DocumentFlowItemsFetchLoad extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_REQUEST;

  constructor(public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowItemsFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_SUCCESS;

  constructor(public documents: DocFlowItem[], public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowItemsFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  constructor(public errormsg: string, public df: DocumentFlow) {
    super(errormsg);
  }

  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAILURE;
}

export class InvoiceItemsFetchLoad extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICE_ITEMS_REQUEST;

  constructor(public i: Invoice) {
    super();
  }
}

export class InvoiceItemsFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_INVOICE_ITEMS_SUCCESS;

  constructor(public documents: InvoiceItem[], public i: Invoice) {
    super();
  }
}

export class InvoiceItemsFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  constructor(public errormsg: string, public i: Invoice) {
    super(errormsg);
  }

  public readonly type = DocumentFlowTypes.FETCH_INVOICE_ITEMS_FAILURE;
}

export class DocumentFlowItemsFavAction extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAV_REQUEST;

  constructor(public PrItem: string, public PoItem: string, public fav: boolean, public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowItemsFavSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_ITEMS_FAV_SUCCESS;

  constructor(public PrItem: string, public PoItem: string, public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowHeaderLevelFavAction extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_REQUEST;

  constructor(public fav: boolean, public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowHeaderLevelFavSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_SUCCESS;

  constructor(public fav: boolean, public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowHeaderLevelFavFailureAction extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_HEADER_LEVEL_FAV_FAILURE;

  constructor(public error: string, public df: DocumentFlow) {
    super();
  }
}

export class DocumentFlowStageFetchAction extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWSTAGE;

  constructor(public dfId: DocumentFlowId, public documentFlow: DocumentFlow) {
    super();
  }
}

export class DocumentFlowStageFetchSuccessAction extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWSTAGE_SUCCESS;

  constructor(public stage: DocumentFlowStage) {
    super();
  }
}

export class DocumentFlowStageFetchFailureAction extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWSTAGE_FAILURE;

  constructor(public dfId: DocumentFlowId, public error: string) {
    super();
  }
}

export class SetSelectedDocumentFlowAction extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SET_SELECTED_DOCFLOW;

  constructor(public docFLow: DocumentFlow) {
    super();
  }
}

export class SetSelectedDocFlowItemAction extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SET_SELECTED_DOCFLOW_ITEM;

  constructor(public docFLowItem: DocFlowItem) {
    super();
  }
}

export class RemoveLinkCb extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REMOVE_LINK_CB;

  constructor(
    public cbId: string,
    public df: Partial<DocumentFlow>,
    public done?: any
  ) {
    super();
  }
}

export class RemoveLinkCbSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REMOVE_LINK_CB_SUCCESS;

  constructor(public sapPR: string, public sapPO: string) {
    super();
  }
}

export class RemoveLinkCbFailure extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REMOVE_LINK_CB_FAILURE;
}

export class FetchDocumentFlowsLinkCb extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB;

  constructor(public cbId: number) {
    super();
  }
}

export class FetchDocumentFlowsLinkCbSuccess extends SuccessAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB_SUCCESS;

  constructor(public data: DocumentFlow[]) {
    super();
  }
}

export class FetchDocumentFlowsLinkCbFailure extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOWS_LINK_CB_FAILURE;
}

export class GetBlockedInvoices extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.GET_BLOCKED_INVOICES;

  constructor(public compCode: string[], public done: Function) {
    super();
  }
}

export class GetBlockedInvoicesSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.GET_BLOCKED_INVOICES_SUCCESS;

  constructor(public message: string) {
    super();
  }
}

export class GetBlockedInvoicesFailure extends AzureErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.GET_BLOCKED_INVOICES_FAILURE;
}

export class SetDocFlowToLink extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SET_DOCFLOW_TO_LINK;

  constructor(public docFlow: any) {
    super();
  }
}

export class UpdateDocFlowItem extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.UPDATE_DOCFLOW_ITEM;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public itemId: string,
    public docflow: DocumentFlow) {
    super();
  }
}

export class UpdateDocFlowItemSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.UPDATE_DOCFLOW_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public itemId: string,
    public docflow: DocumentFlow) {
    super();

    this.executor.resolve();
  }
}

export class UpdateDocFlowItemError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.UPDATE_DOCFLOW_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class RequestChangeDocFlowItem extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public onDone,
    public docflow: DocumentFlow,
    public docflowItemOld: DocFlowItem,
    public sendEmailToCreator: boolean,
    public docflowItemNew: DocFlowItem,
    public formData: any) {
    super();
  }
}

export class NewRequestChangeAllDocFlowItemsWithAccountDetails extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_WITH_ACCOUNT_DETAILS;

  constructor(
    public docflowItems: DocFlowItem[],
    public onDone,
    public docflow: DocumentFlow,
    public docflowItemsOld: DocFlowItem[],
    public sendEmailToCreator: boolean,
    public docflowItemsNew: DocFlowItem[],
    public formData: any,
    public sendEmail: boolean,
    public docflowAccountsOld: any,
  ) {
    super();
  }
}

export class NewRequestChangeAllDocFlowItems extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.NEW_REQUEST_CHANGE_ALL_DOCFLOW_ITEMS;

  constructor(
    public docflowItems: DocFlowItem[],
    public onDone,
    public docflow: DocumentFlow,
    public docflowItemsOld: DocFlowItem[],
    public sendEmailToCreator: boolean,
    public docflowItemsNew: DocFlowItem[],
    public formData: any,
    public sendEmail: boolean,
  ) {
    super();
  }
}

export class RequestChangeAllDocFlowItems extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS;

  constructor(
    public docflowItems: DocFlowItem[],
    public onDone,
    public docflow: DocumentFlow,
    public docflowItemsOld: DocFlowItem[],
    public sendEmailToCreator: boolean,
    public docflowItemsNew: DocFlowItem[],
    public formData: any,
    public sendEmail: boolean,
  ) {
    super();
  }
}

export class RequestChangeDocFlowItemArr extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_ARR;

  constructor(public executor: IPromiseExecutor,
    public docflowItemDiff: DocFlowItem[], // this will go to SAP
    public onDone,
    public docflow: DocumentFlow,   // header df
    public docflowItemOld: DocFlowItem[],
    public sendEmailToCreator: boolean[],
    public docflowItemNew: DocFlowItem[],
    public formData: any) {
    super();
  }
}


export class RequestChangeDocFlowItemSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public onDone,
    public docflow: DocumentFlow,
    public docflowItemOld: DocFlowItem,
    public sendEmailToCreator: boolean,
    public docflowItemNew: DocFlowItem,
    public formData: any) {
    super();

    this.executor.resolve();
  }
}

export class RequestChangeAllDocFlowItemsSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_SUCCESS;
}

export class RequestChangeDocFlowItemError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_DOCFLOW_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}
export class RequestChangeAllDocFlowItemsError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.REQUEST_CHANGE_ALL_DOCFLOW_ITEMS_FAILURE;

  constructor(public error) {
    super(error);
  }
}
export class SendEmailPO extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO;

  constructor(
    //public executor: IPromiseExecutor,
    public ItemNo: string,
    public approved: boolean,
    public POmodifier: string,
    public poNumber: string,
    public comment: string,
    public onDone) {
    super();
  }
}

export class SendEmailPOSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO_SUCCESS;

  constructor(
    //public executor: IPromiseExecutor,
    public message: string) {
    super();

    //this.executor.resolve();
  }
}

export class SendEmailPOError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO_FAILURE;

  constructor(
    //public executor: IPromiseExecutor, 
    public error) {
    super(error);

    //this.executor.reject();
  }
}

export class SendEmailPOApproval extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO_APPROVAL;

  constructor(
    //public executor: IPromiseExecutor,
    public poItemNumber: string,
    public prItemNumber: string,
    public resendPDF: boolean,
    public SystemAlias: string,
    public approved: boolean,
    public POmodifier: string,
    public poNumber: string,
    public comment: string,
    public onDone,
    public compIndicatorSelected?: string) {
    super();
  }
}

export class SendEmailPOApprovalSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO_APPROVAL_SUCCESS;

  constructor(
    //public executor: IPromiseExecutor,
    public message: string) {
    super();

    //this.executor.resolve();
  }
}

export class SendEmailPOApprovalError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SEND_EMAIL_PO_APPROVAL_FAILURE;

  constructor(
    //public executor: IPromiseExecutor, 
    public error) {
    super(error);

    //this.executor.reject();
  }
}

export class SaveDocFlowItem extends Action<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SAVE_DOCFLOW_ITEM;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public onDone,
    public docflow: DocumentFlow) {
    super();
  }
}

export class SaveDocFlowItemSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SAVE_DOCFLOW_ITEM_SUCCESS;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public onDone,
    public docflow: DocumentFlow) {
    super();

    this.executor.resolve();
  }
}

export class SaveDocFlowItemError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.SAVE_DOCFLOW_ITEM_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class ClosePO extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.CLOSE_PO;

  constructor(public executor: IPromiseExecutor,
    public docflowItem: DocFlowItem,
    public onDone,
    public itemId: string,
    public docflow: DocumentFlow) {
    super();
  }
}

export class ClosePOSuccess extends SuccessNotificationAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.CLOSE_PO_SUCCESS;

  constructor(public executor: IPromiseExecutor,
    public message: string) {
    super();

    this.executor.resolve();
  }
}

export class ClosePOError extends ErrorAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.CLOSE_PO_FAILURE;

  constructor(public executor: IPromiseExecutor, public error) {
    super(error);

    this.executor.reject();
  }
}

export class DownloadDocument extends PendingAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_DOCUMENT;
  constructor(public instId: string, public typeId: string, public attaId: string, public fileName: string, public systemAlias: string) {
    super();
  }
}

export class DownloadDocumentSuccess extends DownloadCompletedAction<DocumentFlowTypes> {
  public readonly type = DocumentFlowTypes.FETCH_DOCFLOW_DOCUMENT_SUCCESS;
}

export type DocFlowActions =
  | InvoiceItemsFetchLoad
  | InvoiceItemsFetchSuccessAction
  | InvoiceItemsFetchFailureAction
  | DocumentFlowFetchAction
  | DocumentFlowFetchSuccessAction
  | DocumentFlowItemsFetchLoad
  | DocumentFlowItemsFetchSuccessAction
  | DocumentFlowItemsFetchFailureAction
  | DocumentFlowItemsFavAction
  | DocumentFlowItemsFavSuccessAction
  | DocumentFlowHeaderLevelFavAction
  | DocumentFlowHeaderLevelFavSuccessAction
  | DocumentFlowHeaderLevelFavFailureAction
  | DocumentFlowFetchLoad
  | DocumentFlowFetchFailureAction
  | DocumentFlowStageFetchAction
  | DocumentFlowStageFetchSuccessAction
  | DocumentFlowStageFetchFailureAction
  | GetChatMessagesSuccess
  | NewDocFlowChatCounter
  | SetSelectedDocumentFlowAction
  | SetSelectedDocFlowItemAction
  | RemoveLinkCb
  | RemoveLinkCbSuccess
  | RemoveLinkCbFailure
  | FetchDocumentFlowsLinkCb
  | FetchDocumentFlowsLinkCbSuccess
  | FetchDocumentFlowsLinkCbFailure
  | SetDocFlowToLink
  | UpdateTab
  | UpdateDocFlowItem
  | UpdateDocFlowItemSuccess
  | UpdateDocFlowItemError
  | SaveDocFlowItem
  | SaveDocFlowItemSuccess
  | SaveDocFlowItemError
  | ClosePO
  | ClosePOSuccess
  | ClosePOError
  | RequestChangeDocFlowItem
  | RequestChangeDocFlowItemArr
  | RequestChangeDocFlowItemSuccess
  | RequestChangeDocFlowItemError
  | DownloadDocument
  | DownloadDocumentSuccess
  | DocumentFlowStatusFetchSuccessAction
  | DocumentFlowStatusFetchFailureAction
  | SendEmailPO
  | SendEmailPOSuccess
  | SendEmailPOError
  | SendEmailPOApproval
  | SendEmailPOApprovalSuccess
  | SendEmailPOApprovalError
  | InvoicesFetchAction
  | InvoicesFetchSuccessAction
  | InvoicesFetchFailureAction
  | DefaultDocData
  | RequestChangeAllDocFlowItems
  | NewRequestChangeAllDocFlowItems
  | NewRequestChangeAllDocFlowItemsWithAccountDetails
  | RequestChangeAllDocFlowItemsSuccess
  | RequestChangeAllDocFlowItemsError
  | DocumentFlowResubmitSuccessAction
  ;

import React from "react";

import { NewEditReqStyledCell, PRpopupTable, PRStyledCell } from "src/app/purchaseRequisition/PR.styles";
import MaterialTable, { Column } from "material-table";
import { TableLoadingComponent } from "src/app/shared/components/loading";
import { useTableStyles } from "../../../../dataTable/docFlow/docFlowTable.styles";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { ItemTableCellComponent } from "../../../itemTableCell.component";
import { CustomFastAsyncSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { getCondtions } from "../../helper";
import { DISPATCH_TYPES } from "../../EditRequestAllLinesWrapper";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { Features } from "src/app/shared/domains/core/pataflag";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50"
        },
        secondary: {
            main: "#ff9100"
        }
    }
});

type accountDetailsTableProps = {
    lineItems: DocFlowItem[];
    documentFlow: DocumentFlow;
    dispatchMethod: any;
};

export const AccountDetailsTable = (props: accountDetailsTableProps) => {

    const { hasFeature } = React.useContext(FeaturesContext);
    
    const { docFlowTableClass } = useTableStyles({});

    const applyChanges = (vals: { [key: string]: any }, itemNo: string) => {
        props.dispatchMethod({
            type: DISPATCH_TYPES.EDIT_LINE_ITEMS,
            payload: {
                lineNo: itemNo,
                editedValues: vals
            }
        })
    };

    const stylesFun = React.useCallback((disabled) => ({
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
        valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
        control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderRadius: 0, borderBottom: disabled ? '1px solid hsl(0,0%,90%)' : '1px solid' })
    }), [])

    const PRDetailColumns = (): Column<any>[] => {
        return [
            {
                title: "#", sorting: false, field: "ItemNo", cellStyle: { width: "4%", marginLeft: "10px" }, render: (item: DocFlowItem, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.PrItem]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Cost Center", sorting: false, field: "ItemNo", cellStyle: { width: "23%" }, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    const newItem:any = {...item}
                    let disabled = (fieldLevelConditions.isItMulti || !item.Pr_CostCenter || fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR)
                    return <CustomFastAsyncSelect
                        disabled={disabled}
                        clearable={false}
                        styles={stylesFun(disabled)}
                        isMulti={false}
                        menuPortalTarget={document.body}
                        onLoadLimit={3}
                        onLoad={(x) => MasterService.fetchCostCenter(x?.trim(), [{ id: item?.CompCode || props.documentFlow.POCompanyCode }], item?.SystemAlias)}
                        customDisplay={option => `${option.value} - ${option.label}`}
                        initialValue={{ value: item.Pr_CostCenter, label: item.Pr_CostCenterTxt }}
                        onChange={value => applyChanges({ Pr_CostCenter: value.value, Pr_CostCenterTxt: value.label }, item.PrItem)} />
                }
            },
            {
                title: "Profit Center", sorting: false, field: "ItemNo", cellStyle: { width: "23%" }, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    const newItem:any = {...item}
                    let disabled = (fieldLevelConditions.isItMulti || !item.PR_PROFIT || fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR)
                    return <CustomFastAsyncSelect
                        disabled={disabled}
                        clearable={false}
                        styles={stylesFun(disabled)}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        onLoadLimit={3}
                        onLoad={(x) => MasterService.fetchProfitCenter(x?.trim(), item?.SystemAlias)}
                        customDisplay={option =>
                            option.label
                                ? `${option.value} - ${option.label}`
                                : option.value
                        }
                        initialValue={fieldLevelConditions.isThereAccCategory ? null : { value: item.PR_PROFIT }}
                        onChange={value => applyChanges({ PR_PROFIT: value.value }, item.PrItem)} />
                }
            },
            {
                title: "WBS/Project", field: "Pr_wbsid", sorting: false, cellStyle: { width: "23%" }, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    const newItem:any = {...item}
                    let disabled = (fieldLevelConditions.isItMulti || !item.Pr_wbsid || fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR)
                    return <CustomFastAsyncSelect
                        isMulti={false}
                        styles={stylesFun(disabled)}
                        menuPortalTarget={document.body}
                        onLoadLimit={2}
                        clearable={false}
                        onLoad={(x) => MasterService.fetchProjectId(x?.trim(), [{ id: item?.CompCode || props.documentFlow.POCompanyCode }], item?.SystemAlias)}

                        customDisplay={option =>
                            option.label ? `${option.value} - ${option.label}` : option.value
                        }
                        disabled={disabled}
                        initialValue={{ value: item.Pr_wbsid }}
                        onChange={value => applyChanges({ Pr_wbsid: value.WBSElement }, item.PrItem)} />
                }
            },
            {
                title: "G/L Number", field: "Pr_GlAccount", cellStyle: { width: "23%" }, sorting: false, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    const newItem:any = {...item}
                    let disabled = (fieldLevelConditions.isItMulti || !item.Pr_GlAccount || fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR)
                    return <CustomFastAsyncSelect
                        disabled={disabled}
                        clearable={false}
                        styles={stylesFun(disabled)}
                        isMulti={false}
                        menuPortalTarget={document.body}
                        onLoadLimit={3}
                        onLoad={(x) => MasterService.fetchGlAccount(x?.trim(), item?.SystemAlias)}
                        customDisplay={option => `${option.value} - ${option.label}`}
                        initialValue={{ value: item.Pr_GlAccount, label: item.Pr_GlAccountTxt }}
                        onChange={value => applyChanges({ Pr_GlAccount: value.value, Pr_GlAccountTxt: value.label }, item.PrItem)} />
                }
            }
        ];
    };


    // decrease the space btw check box and 1st column to 10px
    return <PRpopupTable className={docFlowTableClass}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{
                    boxShadow: "none",
                    zIndex: 999
                }}
                isLoading={!props.lineItems}
                columns={PRDetailColumns()}
                data={props.lineItems}
                components={{
                    OverlayLoading: () => <TableLoadingComponent />,
                    Cell: props => <NewEditReqStyledCell {...props} />
                }}
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    toolbar: false,
                    rowStyle: {
                        border: "none",
                        padding: "10px 5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        fontSize: ".8rem", color: "#7c7777",
                        whiteSpace: "nowrap",
                        height: "60px",
                    },
                    headerStyle: {
                        fontSize: ".8rem", fontWeight: "bold",
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        color: "#5D5858",
                        backgroundColor: "#F5F5F5",
                        height: "60px",
                        borderBottom: 'none'
                    }
                }}
            />
        </MuiThemeProvider>
    </PRpopupTable>
}